/*-------------------------------------------------------*/
/* Team
/*-------------------------------------------------------*/
.team {
  margin-bottom: 32px;

  &__img {
    width: 100%;
  }

  &__name {
    font-size: 1.75rem;
    margin-top: 30px;
  }

  &__position {
    font-size: 0.9375rem;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 14px;
  }

  @include bp-lg-down {
    margin-bottom: 56px;
  }
}