/*-------------------------------------------------------*/
/* Grid
/*-------------------------------------------------------*/
.main-wrap {
  overflow: hidden;
  position: relative;
}

.content-wrap {
  position: relative;
  z-index: 2;
}

section {
  background-color: #fff;
  padding: 148px 0;
  position: relative;
  // z-index: 1;
  background-size: cover;
  background-position: center;

  @include bp-lg-down {
    padding: 80px 0;
  }
}

.container-semi-fluid {
  padding: 0 50px;

  @include bp-md-down {
    padding: 0 20px;
  }
}

.main-container {
  margin: auto;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}


/* Columns Gutters
-------------------------------------------------------*/
.row-16 {
  margin: 0 -8px;
}

.row-16 > [class*="col-"] {
  padding: 0 8px;
}

.row-20 {
  margin: 0 -10px;
}

.row-20 > [class*="col-"] {
  padding: 0 10px;
}


.row-30 {
  margin: 0 -15px;
}

.row-30 > [class*="col-"] {
  padding: 0 15px;
}

.row-60 {
  margin: 0 -30px;
}

.row-60 > [class*="col-"] {
  padding: 0 30px;
}

.row-80 {
  margin: 0 -40px;
}

.row-80 > [class*="col-"] {
  padding: 0 40px;
}


/* Flexbox
-------------------------------------------------------*/
.flex-parent {
  @include display-flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.flex-child {
  @include flex (1 0 0);
}


/* Flexbox Align
-------------------------------------------------------*/
.justify-content-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.align-items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}