/*-------------------------------------------------------*/
/* Contact Form
/*-------------------------------------------------------*/
.contact-form {

	&-modal {
		padding: 0 !important;

		&__dialog {
			display: flex;
			margin: 0;
			max-width: 100%;
		}

		&__content {
			border-radius: 0;
			border: 0;
			justify-content: center;
			flex-direction: row;
			height: 100vh;	
		}

		&__body {
			flex: 1 0 0;
			max-width: 555px;
			margin: auto;
		}

		&__close {
			width: 40px;
			height: 40px;
			position: absolute;
			top: 40px;
			right: 40px;

			span {
				font-size: 0;

				&:before,
				&:after {
					content: '';
					display: block;
					position: absolute;
					top: 20px;
					height: 2px;
					width: 40px;
					background-color: $bg-dark;
				}

				&:before {
					@include transform( rotate(-45deg));
				}

				&:after {
					@include transform( rotate(45deg));
				}
			}

			&:hover,
			&:focus {
				background-color: transparent;
			}
		}
	}	
}

.contact-form__message {
  height: 50px;
  width: 100%;
  font-size: 13px;
  line-height: 50px;
  text-align: center;
  float: none;
  margin-top: 20px;
  display: none;
	color: #fff;
	
	&.error {
		background-color: #f8d7da;
		color: #721c24;
	}

	&.success {
		background-color: #d4edda;
		color: #155724;
	}
}
