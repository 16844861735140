/*-------------------------------------------------------*/
/* Newsletter
/*-------------------------------------------------------*/
.newsletter {
  
  .title-row {
    margin-bottom: 20px;
  }

  .section-title {
    font-size: 36px;
    margin-bottom: 30px;
  }

  .subtitle {
    font-size: 21px;
    font-weight: 400;
  }

  &__form {
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
  }

  .mc4wp-form-fields {
    @include display-flex;

    & > .form-group:first-child {
      flex: 1;
      margin-right: 8px;

      @include bp-sm-down {
        margin-right: 0;
      }
    }

    @include bp-sm-down {
      display: block;
    }
  }

  input {
    height: 50px;
  }

  input[type=email],
  input[type=text] {
    color: #fff;
    background-color: transparent;
    border-color: rgba(213, 217, 237, 0.36);

    &:focus {
      border-color: #fff;
    }
  }

  /* Change Color of Placeholders */
  input[type=email]::-webkit-input-placeholder,
  input[type=text]::-webkit-input-placeholder {
    color: #fff;
  }

  input[type=email]:-moz-placeholder,
  input[type=text]:-moz-placeholder {
    color: #fff;
    opacity: 1;
  }

  input[type=email]::-moz-placeholder,
  input[type=text]::-moz-placeholder {
    color: #fff;
    opacity: 1;
  }

  input[type=email]:-ms-input-placeholder,
  input[type=text]:-ms-input-placeholder {
    color: #fff;
  }
}