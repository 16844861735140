/*-------------------------------------------------------*/
/* Preloader
/*-------------------------------------------------------*/
.loader-mask {
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  height: 100vh;
  background-color:#000000; 
  z-index:99999;
}

.loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  font-size: 0;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: $main-color;
  animation: spin 1.7s linear infinite;
  z-index: 11;
  
  &:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: $main-color;
    animation: spin-reverse .6s linear infinite;
  }
  
  &:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: $main-color;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% { 
    @include transform(rotate(0deg));
  }
  100% {
    @include transform(rotate(360deg));
  }
}

@-webkit-keyframes spin {
  0% { 
    @include transform(rotate(0deg));
  }
  100% {
    @include transform(rotate(360deg));
  }
}

@keyframes spin-reverse {
  0% { 
    @include transform(rotate(0deg));
  }
  100% {
    @include transform(rotate(-360deg));
  }
}

@-webkit-keyframes spin-reverse {
  0% { 
    @include transform(rotate(0deg));
  }
  100% {
    @include transform(rotate(-360deg));
  }
}