/*-------------------------------------------------------*/
/* Page Title
/*-------------------------------------------------------*/
.page-title {
  position: relative;
  width: 100%;
  display: block;
  padding: 100px 0;
  text-align: center;
  background-size: cover;
  @include background-img-cover;
  background-color: $bg-light;

  &__holder {
    max-width: 580px;
    position: relative;
  }

  &__title {    
    font-size: 3rem;
    line-height: 1.3;
    margin-bottom: 24px;

    @include bp-lg-up {
      font-size: 4.25rem;
    }
  }

  &__subtitle {
    font-size: 1.5rem;
    line-height: 1.4;
    color: #fff;
  }


  @include bp-md-down {
    padding: 40px 0;
  }
}