@charset "UTF-8";
/*-------------------------------------------------------*/
/* Table of Content

1. General
2. Typography
3. Icon Fonts
4. Grid
5. Navigation
6. Footer
7. Components
8. Pages
9. Helper Classes

/*-------------------------------------------------------*/
/*!
* Bootstrap v4.3.1 (https://getbootstrap.com/)
* Copyright 2011-2019 The Bootstrap Authors
* Copyright 2011-2019 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
*/
*, :after, :before {
	box-sizing: border-box; }

html {
	font-family: sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
	display: block; }

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	text-align: left;
	background-color: #fff; }

[tabindex="-1"]:focus {
	outline: 0 !important; }

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible; }

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 0.5rem; }

p {
	margin-top: 0;
	margin-bottom: 1rem; }

abbr[data-original-title], abbr[title] {
	text-decoration: underline;
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	cursor: help;
	border-bottom: 0;
	-webkit-text-decoration-skip-ink: none;
	        text-decoration-skip-ink: none; }

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit; }

dl, ol, ul {
	margin-top: 0;
	margin-bottom: 1rem; }

ol ol, ol ul, ul ol, ul ul {
	margin-bottom: 0; }

dt {
	font-weight: 700; }

dd {
	margin-bottom: .5rem;
	margin-left: 0; }

blockquote {
	margin: 0 0 1rem; }

b, strong {
	font-weight: bolder; }

small {
	font-size: 80%; }

sub, sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline; }

sub {
	bottom: -.25em; }

sup {
	top: -.5em; }

a {
	color: #007bff;
	text-decoration: none;
	background-color: transparent; }

a:hover {
	color: #0056b3;
	text-decoration: underline; }

a:not([href]):not([tabindex]) {
	color: inherit;
	text-decoration: none; }

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
	color: inherit;
	text-decoration: none; }

a:not([href]):not([tabindex]):focus {
	outline: 0; }

code, kbd, pre, samp {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	font-size: 1em; }

pre {
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto; }

figure {
	margin: 0 0 1rem; }

img {
	vertical-align: middle;
	border-style: none; }

svg {
	overflow: hidden;
	vertical-align: middle; }

table {
	border-collapse: collapse; }

caption {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	color: #6c757d;
	text-align: left;
	caption-side: bottom; }

th {
	text-align: inherit; }

label {
	display: inline-block;
	margin-bottom: 0.5rem; }

button {
	border-radius: 0; }

button:focus {
	outline: 1px dotted;
	outline: 5px auto -webkit-focus-ring-color; }

button, input, optgroup, select, textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit; }

button, input {
	overflow: visible; }

button, select {
	text-transform: none; }

select {
	word-wrap: normal; }

[type=button], [type=reset], [type=submit], button {
	-webkit-appearance: button; }

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
	cursor: pointer; }

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
	padding: 0;
	border-style: none; }

input[type=checkbox], input[type=radio] {
	box-sizing: border-box;
	padding: 0; }

input[type=date], input[type=datetime-local], input[type=month], input[type=time] {
	-webkit-appearance: listbox; }

textarea {
	overflow: auto;
	resize: vertical; }

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0; }

legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: .5rem;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal; }

progress {
	vertical-align: baseline; }

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
	height: auto; }

[type=search] {
	outline-offset: -2px;
	-webkit-appearance: none; }

[type=search]::-webkit-search-decoration {
	-webkit-appearance: none; }

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button; }

output {
	display: inline-block; }

summary {
	display: list-item;
	cursor: pointer; }

template {
	display: none; }

[hidden] {
	display: none !important; }

.container {
	width: 100%;
	padding-right: 20px;
	padding-left: 20px;
	margin-right: auto;
	margin-left: auto; }

@media (min-width: 576px) {
	.container {
		max-width: 540px; } }

@media (min-width: 768px) {
	.container {
		max-width: 720px; } }

@media (min-width: 992px) {
	.container {
		max-width: 960px; } }

@media (min-width: 1200px) {
	.container {
		max-width: 1140px; } }

.container-fluid {
	width: 100%;
	padding-right: 20px;
	padding-left: 20px;
	margin-right: auto;
	margin-left: auto; }

.row {
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	margin-right: -20px;
	margin-left: -20px; }

.no-gutters {
	margin-right: 0;
	margin-left: 0; }

.no-gutters > .col, .no-gutters > [class*=col-] {
	padding-right: 0;
	padding-left: 0; }

.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto {
	position: relative;
	width: 100%;
	padding-right: 20px;
	padding-left: 20px; }

.col {
	flex-basis: 0;
	-webkit-box-flex: 1;
	flex-grow: 1;
	max-width: 100%; }

.col-auto {
	-webkit-box-flex: 0;
	flex: 0 0 auto;
	width: auto;
	max-width: 100%; }

.col-1 {
	-webkit-box-flex: 0;
	flex: 0 0 8.33333%;
	max-width: 8.33333%; }

.col-2 {
	-webkit-box-flex: 0;
	flex: 0 0 16.66667%;
	max-width: 16.66667%; }

.col-3 {
	-webkit-box-flex: 0;
	flex: 0 0 25%;
	max-width: 25%; }

.col-4 {
	-webkit-box-flex: 0;
	flex: 0 0 33.33333%;
	max-width: 33.33333%; }

.col-5 {
	-webkit-box-flex: 0;
	flex: 0 0 41.66667%;
	max-width: 41.66667%; }

.col-6 {
	-webkit-box-flex: 0;
	flex: 0 0 50%;
	max-width: 50%; }

.col-7 {
	-webkit-box-flex: 0;
	flex: 0 0 58.33333%;
	max-width: 58.33333%; }

.col-8 {
	-webkit-box-flex: 0;
	flex: 0 0 66.66667%;
	max-width: 66.66667%; }

.col-9 {
	-webkit-box-flex: 0;
	flex: 0 0 75%;
	max-width: 75%; }

.col-10 {
	-webkit-box-flex: 0;
	flex: 0 0 83.33333%;
	max-width: 83.33333%; }

.col-11 {
	-webkit-box-flex: 0;
	flex: 0 0 91.66667%;
	max-width: 91.66667%; }

.col-12 {
	-webkit-box-flex: 0;
	flex: 0 0 100%;
	max-width: 100%; }

.order-first {
	-webkit-box-ordinal-group: 0;
	order: -1; }

.order-last {
	-webkit-box-ordinal-group: 14;
	order: 13; }

.order-0 {
	-webkit-box-ordinal-group: 1;
	order: 0; }

.order-1 {
	-webkit-box-ordinal-group: 2;
	order: 1; }

.order-2 {
	-webkit-box-ordinal-group: 3;
	order: 2; }

.order-3 {
	-webkit-box-ordinal-group: 4;
	order: 3; }

.order-4 {
	-webkit-box-ordinal-group: 5;
	order: 4; }

.order-5 {
	-webkit-box-ordinal-group: 6;
	order: 5; }

.order-6 {
	-webkit-box-ordinal-group: 7;
	order: 6; }

.order-7 {
	-webkit-box-ordinal-group: 8;
	order: 7; }

.order-8 {
	-webkit-box-ordinal-group: 9;
	order: 8; }

.order-9 {
	-webkit-box-ordinal-group: 10;
	order: 9; }

.order-10 {
	-webkit-box-ordinal-group: 11;
	order: 10; }

.order-11 {
	-webkit-box-ordinal-group: 12;
	order: 11; }

.order-12 {
	-webkit-box-ordinal-group: 13;
	order: 12; }

.offset-1 {
	margin-left: 8.33333%; }

.offset-2 {
	margin-left: 16.66667%; }

.offset-3 {
	margin-left: 25%; }

.offset-4 {
	margin-left: 33.33333%; }

.offset-5 {
	margin-left: 41.66667%; }

.offset-6 {
	margin-left: 50%; }

.offset-7 {
	margin-left: 58.33333%; }

.offset-8 {
	margin-left: 66.66667%; }

.offset-9 {
	margin-left: 75%; }

.offset-10 {
	margin-left: 83.33333%; }

.offset-11 {
	margin-left: 91.66667%; }

@media (min-width: 576px) {
	.col-sm {
		flex-basis: 0;
		-webkit-box-flex: 1;
		flex-grow: 1;
		max-width: 100%; }
	.col-sm-auto {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%; }
	.col-sm-1 {
		-webkit-box-flex: 0;
		flex: 0 0 8.33333%;
		max-width: 8.33333%; }
	.col-sm-2 {
		-webkit-box-flex: 0;
		flex: 0 0 16.66667%;
		max-width: 16.66667%; }
	.col-sm-3 {
		-webkit-box-flex: 0;
		flex: 0 0 25%;
		max-width: 25%; }
	.col-sm-4 {
		-webkit-box-flex: 0;
		flex: 0 0 33.33333%;
		max-width: 33.33333%; }
	.col-sm-5 {
		-webkit-box-flex: 0;
		flex: 0 0 41.66667%;
		max-width: 41.66667%; }
	.col-sm-6 {
		-webkit-box-flex: 0;
		flex: 0 0 50%;
		max-width: 50%; }
	.col-sm-7 {
		-webkit-box-flex: 0;
		flex: 0 0 58.33333%;
		max-width: 58.33333%; }
	.col-sm-8 {
		-webkit-box-flex: 0;
		flex: 0 0 66.66667%;
		max-width: 66.66667%; }
	.col-sm-9 {
		-webkit-box-flex: 0;
		flex: 0 0 75%;
		max-width: 75%; }
	.col-sm-10 {
		-webkit-box-flex: 0;
		flex: 0 0 83.33333%;
		max-width: 83.33333%; }
	.col-sm-11 {
		-webkit-box-flex: 0;
		flex: 0 0 91.66667%;
		max-width: 91.66667%; }
	.col-sm-12 {
		-webkit-box-flex: 0;
		flex: 0 0 100%;
		max-width: 100%; }
	.order-sm-first {
		-webkit-box-ordinal-group: 0;
		order: -1; }
	.order-sm-last {
		-webkit-box-ordinal-group: 14;
		order: 13; }
	.order-sm-0 {
		-webkit-box-ordinal-group: 1;
		order: 0; }
	.order-sm-1 {
		-webkit-box-ordinal-group: 2;
		order: 1; }
	.order-sm-2 {
		-webkit-box-ordinal-group: 3;
		order: 2; }
	.order-sm-3 {
		-webkit-box-ordinal-group: 4;
		order: 3; }
	.order-sm-4 {
		-webkit-box-ordinal-group: 5;
		order: 4; }
	.order-sm-5 {
		-webkit-box-ordinal-group: 6;
		order: 5; }
	.order-sm-6 {
		-webkit-box-ordinal-group: 7;
		order: 6; }
	.order-sm-7 {
		-webkit-box-ordinal-group: 8;
		order: 7; }
	.order-sm-8 {
		-webkit-box-ordinal-group: 9;
		order: 8; }
	.order-sm-9 {
		-webkit-box-ordinal-group: 10;
		order: 9; }
	.order-sm-10 {
		-webkit-box-ordinal-group: 11;
		order: 10; }
	.order-sm-11 {
		-webkit-box-ordinal-group: 12;
		order: 11; }
	.order-sm-12 {
		-webkit-box-ordinal-group: 13;
		order: 12; }
	.offset-sm-0 {
		margin-left: 0; }
	.offset-sm-1 {
		margin-left: 8.33333%; }
	.offset-sm-2 {
		margin-left: 16.66667%; }
	.offset-sm-3 {
		margin-left: 25%; }
	.offset-sm-4 {
		margin-left: 33.33333%; }
	.offset-sm-5 {
		margin-left: 41.66667%; }
	.offset-sm-6 {
		margin-left: 50%; }
	.offset-sm-7 {
		margin-left: 58.33333%; }
	.offset-sm-8 {
		margin-left: 66.66667%; }
	.offset-sm-9 {
		margin-left: 75%; }
	.offset-sm-10 {
		margin-left: 83.33333%; }
	.offset-sm-11 {
		margin-left: 91.66667%; } }

@media (min-width: 768px) {
	.col-md {
		flex-basis: 0;
		-webkit-box-flex: 1;
		flex-grow: 1;
		max-width: 100%; }
	.col-md-auto {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%; }
	.col-md-1 {
		-webkit-box-flex: 0;
		flex: 0 0 8.33333%;
		max-width: 8.33333%; }
	.col-md-2 {
		-webkit-box-flex: 0;
		flex: 0 0 16.66667%;
		max-width: 16.66667%; }
	.col-md-3 {
		-webkit-box-flex: 0;
		flex: 0 0 25%;
		max-width: 25%; }
	.col-md-4 {
		-webkit-box-flex: 0;
		flex: 0 0 33.33333%;
		max-width: 33.33333%; }
	.col-md-5 {
		-webkit-box-flex: 0;
		flex: 0 0 41.66667%;
		max-width: 41.66667%; }
	.col-md-6 {
		-webkit-box-flex: 0;
		flex: 0 0 50%;
		max-width: 50%; }
	.col-md-7 {
		-webkit-box-flex: 0;
		flex: 0 0 58.33333%;
		max-width: 58.33333%; }
	.col-md-8 {
		-webkit-box-flex: 0;
		flex: 0 0 66.66667%;
		max-width: 66.66667%; }
	.col-md-9 {
		-webkit-box-flex: 0;
		flex: 0 0 75%;
		max-width: 75%; }
	.col-md-10 {
		-webkit-box-flex: 0;
		flex: 0 0 83.33333%;
		max-width: 83.33333%; }
	.col-md-11 {
		-webkit-box-flex: 0;
		flex: 0 0 91.66667%;
		max-width: 91.66667%; }
	.col-md-12 {
		-webkit-box-flex: 0;
		flex: 0 0 100%;
		max-width: 100%; }
	.order-md-first {
		-webkit-box-ordinal-group: 0;
		order: -1; }
	.order-md-last {
		-webkit-box-ordinal-group: 14;
		order: 13; }
	.order-md-0 {
		-webkit-box-ordinal-group: 1;
		order: 0; }
	.order-md-1 {
		-webkit-box-ordinal-group: 2;
		order: 1; }
	.order-md-2 {
		-webkit-box-ordinal-group: 3;
		order: 2; }
	.order-md-3 {
		-webkit-box-ordinal-group: 4;
		order: 3; }
	.order-md-4 {
		-webkit-box-ordinal-group: 5;
		order: 4; }
	.order-md-5 {
		-webkit-box-ordinal-group: 6;
		order: 5; }
	.order-md-6 {
		-webkit-box-ordinal-group: 7;
		order: 6; }
	.order-md-7 {
		-webkit-box-ordinal-group: 8;
		order: 7; }
	.order-md-8 {
		-webkit-box-ordinal-group: 9;
		order: 8; }
	.order-md-9 {
		-webkit-box-ordinal-group: 10;
		order: 9; }
	.order-md-10 {
		-webkit-box-ordinal-group: 11;
		order: 10; }
	.order-md-11 {
		-webkit-box-ordinal-group: 12;
		order: 11; }
	.order-md-12 {
		-webkit-box-ordinal-group: 13;
		order: 12; }
	.offset-md-0 {
		margin-left: 0; }
	.offset-md-1 {
		margin-left: 8.33333%; }
	.offset-md-2 {
		margin-left: 16.66667%; }
	.offset-md-3 {
		margin-left: 25%; }
	.offset-md-4 {
		margin-left: 33.33333%; }
	.offset-md-5 {
		margin-left: 41.66667%; }
	.offset-md-6 {
		margin-left: 50%; }
	.offset-md-7 {
		margin-left: 58.33333%; }
	.offset-md-8 {
		margin-left: 66.66667%; }
	.offset-md-9 {
		margin-left: 75%; }
	.offset-md-10 {
		margin-left: 83.33333%; }
	.offset-md-11 {
		margin-left: 91.66667%; } }

@media (min-width: 992px) {
	.col-lg {
		flex-basis: 0;
		-webkit-box-flex: 1;
		flex-grow: 1;
		max-width: 100%; }
	.col-lg-auto {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%; }
	.col-lg-1 {
		-webkit-box-flex: 0;
		flex: 0 0 8.33333%;
		max-width: 8.33333%; }
	.col-lg-2 {
		-webkit-box-flex: 0;
		flex: 0 0 16.66667%;
		max-width: 16.66667%; }
	.col-lg-3 {
		-webkit-box-flex: 0;
		flex: 0 0 25%;
		max-width: 25%; }
	.col-lg-4 {
		-webkit-box-flex: 0;
		flex: 0 0 33.33333%;
		max-width: 33.33333%; }
	.col-lg-5 {
		-webkit-box-flex: 0;
		flex: 0 0 41.66667%;
		max-width: 41.66667%; }
	.col-lg-6 {
		-webkit-box-flex: 0;
		flex: 0 0 50%;
		max-width: 50%; }
	.col-lg-7 {
		-webkit-box-flex: 0;
		flex: 0 0 58.33333%;
		max-width: 58.33333%; }
	.col-lg-8 {
		-webkit-box-flex: 0;
		flex: 0 0 66.66667%;
		max-width: 66.66667%; }
	.col-lg-9 {
		-webkit-box-flex: 0;
		flex: 0 0 75%;
		max-width: 75%; }
	.col-lg-10 {
		-webkit-box-flex: 0;
		flex: 0 0 83.33333%;
		max-width: 83.33333%; }
	.col-lg-11 {
		-webkit-box-flex: 0;
		flex: 0 0 91.66667%;
		max-width: 91.66667%; }
	.col-lg-12 {
		-webkit-box-flex: 0;
		flex: 0 0 100%;
		max-width: 100%; }
	.order-lg-first {
		-webkit-box-ordinal-group: 0;
		order: -1; }
	.order-lg-last {
		-webkit-box-ordinal-group: 14;
		order: 13; }
	.order-lg-0 {
		-webkit-box-ordinal-group: 1;
		order: 0; }
	.order-lg-1 {
		-webkit-box-ordinal-group: 2;
		order: 1; }
	.order-lg-2 {
		-webkit-box-ordinal-group: 3;
		order: 2; }
	.order-lg-3 {
		-webkit-box-ordinal-group: 4;
		order: 3; }
	.order-lg-4 {
		-webkit-box-ordinal-group: 5;
		order: 4; }
	.order-lg-5 {
		-webkit-box-ordinal-group: 6;
		order: 5; }
	.order-lg-6 {
		-webkit-box-ordinal-group: 7;
		order: 6; }
	.order-lg-7 {
		-webkit-box-ordinal-group: 8;
		order: 7; }
	.order-lg-8 {
		-webkit-box-ordinal-group: 9;
		order: 8; }
	.order-lg-9 {
		-webkit-box-ordinal-group: 10;
		order: 9; }
	.order-lg-10 {
		-webkit-box-ordinal-group: 11;
		order: 10; }
	.order-lg-11 {
		-webkit-box-ordinal-group: 12;
		order: 11; }
	.order-lg-12 {
		-webkit-box-ordinal-group: 13;
		order: 12; }
	.offset-lg-0 {
		margin-left: 0; }
	.offset-lg-1 {
		margin-left: 8.33333%; }
	.offset-lg-2 {
		margin-left: 16.66667%; }
	.offset-lg-3 {
		margin-left: 25%; }
	.offset-lg-4 {
		margin-left: 33.33333%; }
	.offset-lg-5 {
		margin-left: 41.66667%; }
	.offset-lg-6 {
		margin-left: 50%; }
	.offset-lg-7 {
		margin-left: 58.33333%; }
	.offset-lg-8 {
		margin-left: 66.66667%; }
	.offset-lg-9 {
		margin-left: 75%; }
	.offset-lg-10 {
		margin-left: 83.33333%; }
	.offset-lg-11 {
		margin-left: 91.66667%; } }

@media (min-width: 1200px) {
	.col-xl {
		flex-basis: 0;
		-webkit-box-flex: 1;
		flex-grow: 1;
		max-width: 100%; }
	.col-xl-auto {
		-webkit-box-flex: 0;
		flex: 0 0 auto;
		width: auto;
		max-width: 100%; }
	.col-xl-1 {
		-webkit-box-flex: 0;
		flex: 0 0 8.33333%;
		max-width: 8.33333%; }
	.col-xl-2 {
		-webkit-box-flex: 0;
		flex: 0 0 16.66667%;
		max-width: 16.66667%; }
	.col-xl-3 {
		-webkit-box-flex: 0;
		flex: 0 0 25%;
		max-width: 25%; }
	.col-xl-4 {
		-webkit-box-flex: 0;
		flex: 0 0 33.33333%;
		max-width: 33.33333%; }
	.col-xl-5 {
		-webkit-box-flex: 0;
		flex: 0 0 41.66667%;
		max-width: 41.66667%; }
	.col-xl-6 {
		-webkit-box-flex: 0;
		flex: 0 0 50%;
		max-width: 50%; }
	.col-xl-7 {
		-webkit-box-flex: 0;
		flex: 0 0 58.33333%;
		max-width: 58.33333%; }
	.col-xl-8 {
		-webkit-box-flex: 0;
		flex: 0 0 66.66667%;
		max-width: 66.66667%; }
	.col-xl-9 {
		-webkit-box-flex: 0;
		flex: 0 0 75%;
		max-width: 75%; }
	.col-xl-10 {
		-webkit-box-flex: 0;
		flex: 0 0 83.33333%;
		max-width: 83.33333%; }
	.col-xl-11 {
		-webkit-box-flex: 0;
		flex: 0 0 91.66667%;
		max-width: 91.66667%; }
	.col-xl-12 {
		-webkit-box-flex: 0;
		flex: 0 0 100%;
		max-width: 100%; }
	.order-xl-first {
		-webkit-box-ordinal-group: 0;
		order: -1; }
	.order-xl-last {
		-webkit-box-ordinal-group: 14;
		order: 13; }
	.order-xl-0 {
		-webkit-box-ordinal-group: 1;
		order: 0; }
	.order-xl-1 {
		-webkit-box-ordinal-group: 2;
		order: 1; }
	.order-xl-2 {
		-webkit-box-ordinal-group: 3;
		order: 2; }
	.order-xl-3 {
		-webkit-box-ordinal-group: 4;
		order: 3; }
	.order-xl-4 {
		-webkit-box-ordinal-group: 5;
		order: 4; }
	.order-xl-5 {
		-webkit-box-ordinal-group: 6;
		order: 5; }
	.order-xl-6 {
		-webkit-box-ordinal-group: 7;
		order: 6; }
	.order-xl-7 {
		-webkit-box-ordinal-group: 8;
		order: 7; }
	.order-xl-8 {
		-webkit-box-ordinal-group: 9;
		order: 8; }
	.order-xl-9 {
		-webkit-box-ordinal-group: 10;
		order: 9; }
	.order-xl-10 {
		-webkit-box-ordinal-group: 11;
		order: 10; }
	.order-xl-11 {
		-webkit-box-ordinal-group: 12;
		order: 11; }
	.order-xl-12 {
		-webkit-box-ordinal-group: 13;
		order: 12; }
	.offset-xl-0 {
		margin-left: 0; }
	.offset-xl-1 {
		margin-left: 8.33333%; }
	.offset-xl-2 {
		margin-left: 16.66667%; }
	.offset-xl-3 {
		margin-left: 25%; }
	.offset-xl-4 {
		margin-left: 33.33333%; }
	.offset-xl-5 {
		margin-left: 41.66667%; }
	.offset-xl-6 {
		margin-left: 50%; }
	.offset-xl-7 {
		margin-left: 58.33333%; }
	.offset-xl-8 {
		margin-left: 66.66667%; }
	.offset-xl-9 {
		margin-left: 75%; }
	.offset-xl-10 {
		margin-left: 83.33333%; }
	.offset-xl-11 {
		margin-left: 91.66667%; } }

.fade {
	-webkit-transition: opacity 0.15s linear;
	transition: opacity 0.15s linear; }

@media (prefers-reduced-motion: reduce) {
	.fade {
		-webkit-transition: none;
		transition: none; } }

.fade:not(.show) {
	opacity: 0; }

.collapse:not(.show) {
	display: none; }

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	-webkit-transition: height 0.35s ease;
	transition: height 0.35s ease; }

@media (prefers-reduced-motion: reduce) {
	.collapsing {
		-webkit-transition: none;
		transition: none; } }

.close {
	float: right;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: .5; }

.close:hover {
	color: #000;
	text-decoration: none; }

.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
	opacity: .75; }

button.close {
	padding: 0;
	background-color: transparent;
	border: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none; }

a.close.disabled {
	pointer-events: none; }

.modal-open {
	overflow: hidden; }

.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto; }

.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1050;
	display: none;
	width: 100%;
	height: 100%;
	overflow: hidden;
	outline: 0; }

.modal-dialog {
	position: relative;
	width: auto;
	margin: 0.5rem;
	pointer-events: none; }

.modal.fade .modal-dialog {
	-webkit-transition: -webkit-transform 0.3s ease-out;
	transition: -webkit-transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
	-webkit-transform: translate(0, -50px);
	transform: translate(0, -50px); }

@media (prefers-reduced-motion: reduce) {
	.modal.fade .modal-dialog {
		-webkit-transition: none;
		transition: none; } }

.modal.show .modal-dialog {
	-webkit-transform: none;
	transform: none; }

.modal-dialog-scrollable {
	display: -webkit-box;
	display: flex;
	max-height: calc(100% - 1rem); }

.modal-dialog-scrollable .modal-content {
	max-height: calc(100vh - 1rem);
	overflow: hidden; }

.modal-dialog-scrollable .modal-footer, .modal-dialog-scrollable .modal-header {
	flex-shrink: 0; }

.modal-dialog-scrollable .modal-body {
	overflow-y: auto; }

.modal-dialog-centered {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	min-height: calc(100% - 1rem); }

.modal-dialog-centered:before {
	display: block;
	height: calc(100vh - 1rem);
	content: ""; }

.modal-dialog-centered.modal-dialog-scrollable {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-webkit-box-pack: center;
	justify-content: center;
	height: 100%; }

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
	max-height: none; }

.modal-dialog-centered.modal-dialog-scrollable:before {
	content: none; }

.modal-content {
	position: relative;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.3rem;
	outline: 0; }

.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: #000; }

.modal-backdrop.fade {
	opacity: 0; }

.modal-backdrop.show {
	opacity: 0.5; }

.modal-header {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: start;
	align-items: flex-start;
	-webkit-box-pack: justify;
	justify-content: space-between;
	padding: 1rem 1rem;
	border-bottom: 1px solid #dee2e6;
	border-top-left-radius: 0.3rem;
	border-top-right-radius: 0.3rem; }

.modal-header .close {
	padding: 1rem 1rem;
	margin: -1rem -1rem -1rem auto; }

.modal-title {
	margin-bottom: 0;
	line-height: 1.5; }

.modal-body {
	position: relative;
	-webkit-box-flex: 1;
	flex: 1 1 auto;
	padding: 1rem; }

.modal-footer {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: end;
	justify-content: flex-end;
	padding: 1rem;
	border-top: 1px solid #dee2e6;
	border-bottom-right-radius: 0.3rem;
	border-bottom-left-radius: 0.3rem; }

.modal-footer > :not(:first-child) {
	margin-left: .25rem; }

.modal-footer > :not(:last-child) {
	margin-right: .25rem; }

.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll; }

@media (min-width: 576px) {
	.modal-dialog {
		max-width: 500px;
		margin: 1.75rem auto; }
	.modal-dialog-scrollable {
		max-height: calc(100% - 3.5rem); }
	.modal-dialog-scrollable .modal-content {
		max-height: calc(100vh - 3.5rem); }
	.modal-dialog-centered {
		min-height: calc(100% - 3.5rem); }
	.modal-dialog-centered:before {
		height: calc(100vh - 3.5rem); }
	.modal-sm {
		max-width: 300px; } }

@media (min-width: 992px) {
	.modal-lg, .modal-xl {
		max-width: 800px; } }

@media (min-width: 1200px) {
	.modal-xl {
		max-width: 1140px; } }

.d-none {
	display: none !important; }

.d-inline {
	display: inline !important; }

.d-inline-block {
	display: inline-block !important; }

.d-block {
	display: block !important; }

.d-table {
	display: table !important; }

.d-table-row {
	display: table-row !important; }

.d-table-cell {
	display: table-cell !important; }

.d-flex {
	display: -webkit-box !important;
	display: flex !important; }

.d-inline-flex {
	display: -webkit-inline-box !important;
	display: inline-flex !important; }

@media (min-width: 576px) {
	.d-sm-none {
		display: none !important; }
	.d-sm-inline {
		display: inline !important; }
	.d-sm-inline-block {
		display: inline-block !important; }
	.d-sm-block {
		display: block !important; }
	.d-sm-table {
		display: table !important; }
	.d-sm-table-row {
		display: table-row !important; }
	.d-sm-table-cell {
		display: table-cell !important; }
	.d-sm-flex {
		display: -webkit-box !important;
		display: flex !important; }
	.d-sm-inline-flex {
		display: -webkit-inline-box !important;
		display: inline-flex !important; } }

@media (min-width: 768px) {
	.d-md-none {
		display: none !important; }
	.d-md-inline {
		display: inline !important; }
	.d-md-inline-block {
		display: inline-block !important; }
	.d-md-block {
		display: block !important; }
	.d-md-table {
		display: table !important; }
	.d-md-table-row {
		display: table-row !important; }
	.d-md-table-cell {
		display: table-cell !important; }
	.d-md-flex {
		display: -webkit-box !important;
		display: flex !important; }
	.d-md-inline-flex {
		display: -webkit-inline-box !important;
		display: inline-flex !important; } }

@media (min-width: 992px) {
	.d-lg-none {
		display: none !important; }
	.d-lg-inline {
		display: inline !important; }
	.d-lg-inline-block {
		display: inline-block !important; }
	.d-lg-block {
		display: block !important; }
	.d-lg-table {
		display: table !important; }
	.d-lg-table-row {
		display: table-row !important; }
	.d-lg-table-cell {
		display: table-cell !important; }
	.d-lg-flex {
		display: -webkit-box !important;
		display: flex !important; }
	.d-lg-inline-flex {
		display: -webkit-inline-box !important;
		display: inline-flex !important; } }

@media (min-width: 1200px) {
	.d-xl-none {
		display: none !important; }
	.d-xl-inline {
		display: inline !important; }
	.d-xl-inline-block {
		display: inline-block !important; }
	.d-xl-block {
		display: block !important; }
	.d-xl-table {
		display: table !important; }
	.d-xl-table-row {
		display: table-row !important; }
	.d-xl-table-cell {
		display: table-cell !important; }
	.d-xl-flex {
		display: -webkit-box !important;
		display: flex !important; }
	.d-xl-inline-flex {
		display: -webkit-inline-box !important;
		display: inline-flex !important; } }

@media print {
	.d-print-none {
		display: none !important; }
	.d-print-inline {
		display: inline !important; }
	.d-print-inline-block {
		display: inline-block !important; }
	.d-print-block {
		display: block !important; }
	.d-print-table {
		display: table !important; }
	.d-print-table-row {
		display: table-row !important; }
	.d-print-table-cell {
		display: table-cell !important; }
	.d-print-flex {
		display: -webkit-box !important;
		display: flex !important; }
	.d-print-inline-flex {
		display: -webkit-inline-box !important;
		display: inline-flex !important; } }

.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden; }

.embed-responsive:before {
	display: block;
	content: ""; }

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0; }

.embed-responsive-21by9:before {
	padding-top: 42.85714%; }

.embed-responsive-16by9:before {
	padding-top: 56.25%; }

.embed-responsive-4by3:before {
	padding-top: 75%; }

.embed-responsive-1by1:before {
	padding-top: 100%; }

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	overflow: visible;
	clip: auto;
	white-space: normal; }

/*-------------------------------------------------------*/
/* General
/*-------------------------------------------------------*/
html {
	font-size: 100%; }

.clearfix {
	*zoom: 1; }
	.clearfix:before, .clearfix:after {
		display: table;
		line-height: 0;
		content: ""; }
	.clearfix:after {
		clear: both; }

::-moz-selection {
	color: #fff;
	background-color: #212121; }

::-webkit-selection {
	color: #fff;
	background-color: #212121; }

::selection {
	color: #fff;
	background-color: #212121; }

:focus {
	outline: none; }

body {
	margin: 0;
	padding: 0;
	font-family: "DM Sans", sans-serif;
	font-size: 1rem;
	line-height: 1.5;
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	background: #fff;
	outline: 0;
	overflow-x: hidden;
	overflow-y: auto;
	color: #686868; }

img {
	border: none;
	height: auto;
	max-width: 100%;
	-webkit-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	-ms-user-select: none;
	    user-select: none; }

a {
	text-decoration: none;
	color: #FE5900;
	-webkit-transition: color 0.1s ease-in-out;
	transition: color 0.1s ease-in-out; }
	a:hover, a:focus {
		text-decoration: none;
		color: #131313; }

p {
	margin: 0 0 24px; }

video {
	height: 100%;
	width: 100%; }

hr {
	margin: 0;
	border-color: #E9E9E9; }

/*-------------------------------------------------------*/
/* Typography
/*-------------------------------------------------------*/
ul, ol {
	margin: 0;
	padding: 0; }

h1, h2, h3, h4, h5, h6 {
	font-family: "DM Sans", sans-serif;
	margin-top: 0;
	margin-bottom: 10px;
	color: #131313;
	font-weight: 700;
	line-height: 1.3;
	letter-spacing: -0.05em; }

h1 {
	font-size: 3.5rem; }

h2 {
	font-size: 3rem; }

h3 {
	font-size: 1.75rem; }

h4 {
	font-size: 1.5rem; }

h5 {
	font-size: 1.375rem; }

h6 {
	font-size: 1.25rem; }

@media only screen and (max-width: 991px) {
	h1 {
		font-size: 3rem; }
	h2 {
		font-size: 2.5rem; }
	h3 {
		font-size: 1.625rem; } }

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a {
	color: inherit; }
	h1 > a:hover, h1 > a:focus, h2 > a:hover, h2 > a:focus, h3 > a:hover, h3 > a:focus, h4 > a:hover, h4 > a:focus, h5 > a:hover, h5 > a:focus, h6 > a:hover, h6 > a:focus {
		color: inherit; }

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
	color: #131313;
	font-size: 85%; }

.text-center {
	text-align: center; }

.text-right {
	text-align: right; }

.text-left {
	text-align: left; }

@media only screen and (max-width: 1199px) {
	.text-lg-center {
		text-align: center; } }

@media only screen and (max-width: 991px) {
	.text-md-center {
		text-align: center; } }

@media only screen and (max-width: 767px) {
	.text-sm-center {
		text-align: center; } }

@media only screen and (max-width: 575px) {
	.text-xs-center {
		text-align: center; } }

@media only screen and (max-width: 1199px) {
	.text-lg-left {
		text-align: left; } }

@media only screen and (max-width: 991px) {
	.text-md-left {
		text-align: left; } }

@media only screen and (max-width: 767px) {
	.text-sm-left {
		text-align: left; } }

@media only screen and (max-width: 575px) {
	.text-xs-left {
		text-align: left; } }

@media only screen and (max-width: 1199px) {
	.text-lg-right {
		text-align: right; } }

@media only screen and (max-width: 991px) {
	.text-md-right {
		text-align: right; } }

@media only screen and (max-width: 767px) {
	.text-sm-right {
		text-align: right; } }

@media only screen and (max-width: 575px) {
	.text-xs-right {
		text-align: right; } }

.title-row {
	margin-bottom: 72px;
	position: relative; }
	@media only screen and (max-width: 991px) {
		.title-row {
			margin-bottom: 48px; } }
	.title-row--boxed {
		max-width: 560px;
		margin-left: auto;
		margin-right: auto; }

.section-title {
	margin-bottom: 34px; }

.section-subtitle {
	font-size: 0.8125rem;
	line-height: 1.56;
	letter-spacing: normal;
	font-weight: 500;
	color: #909090;
	position: relative;
	text-transform: uppercase; }
	.section-subtitle--line:before {
		display: inline-block;
		content: '';
		width: 48px;
		height: 2px;
		margin-right: 16px;
		position: relative;
		top: -4px;
		background-color: #FE5900; }

.section-description {
	font-size: 1.5rem;
	line-height: 1.5; }

.highlight {
	color: #FE5900; }

.lead {
	font-size: 1.25rem;
	line-height: 1.666666; }

address {
	font-style: normal;
	margin-bottom: 0; }

/* Orion Icons
-------------------------------------------------------*/
@font-face {
	font-family: "orion-font";
	src: url("../fonts/orion-font.eot");
	src: url("../fonts/orion-font.eot?#iefix") format("embedded-opentype"), url("../fonts/orion-font.woff2") format("woff2"), url("../fonts/orion-font.woff") format("woff"), url("../fonts/orion-font.ttf") format("truetype"), url("../fonts/orion-font.svg#orion-font") format("svg");
	font-weight: normal;
	font-style: normal; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	@font-face {
		font-family: "orion-font";
		src: url("../fonts/orion-font.svg#orion-font") format("svg"); } }

[data-icon]::before {
	content: attr(data-icon); }

[data-icon]::before,
.o-audio-call-1::before,
.o-bank-check-1::before,
.o-binocular-1::before,
.o-bookmark-1::before,
.o-briefcase-1::before,
.o-business-report-1::before,
.o-calculator-1::before,
.o-call-contact-1::before,
.o-champion-1::before,
.o-chat-app-1::before,
.o-chemistry-1::before,
.o-configuration-1::before,
.o-currency-1::before,
.o-customer-support-1::before,
.o-data-app-1::before,
.o-data-content-1::before,
.o-design-1::before,
.o-diploma-1::before,
.o-dollar-sign-1::before,
.o-download-1::before,
.o-download-cloud-1::before,
.o-duplicate-tool-1::before,
.o-earth-globe-1::before,
.o-edit-1::before,
.o-edit-window-1::before,
.o-error-bug-1::before,
.o-expand-1::before,
.o-filter-data-1::before,
.o-firewall-1::before,
.o-giftbox-1::before,
.o-glasses-1::before,
.o-grid-layout-1::before,
.o-handshake-1::before,
.o-heart-1::before,
.o-hide-layer-1::before,
.o-html5-1::before,
.o-idea-1::before,
.o-imac-screen-1::before,
.o-image-gallery-1::before,
.o-inkpot-1::before,
.o-key-1::before,
.o-label-1::before,
.o-laptop-screen-1::before,
.o-lifebelt-1::before,
.o-lightning-bolt-1::before,
.o-loop-1::before,
.o-luxury-1::before,
.o-mail-1::before,
.o-maximize-window-1::before,
.o-microphone-1::before,
.o-microscope-1::before,
.o-missile-1::before,
.o-money-box-1::before,
.o-mouse-1::before,
.o-network-1::before,
.o-page-1::before,
.o-paint-bucket-1::before,
.o-paper-plane-1::before,
.o-pay-1::before,
.o-pencil-1::before,
.o-permalink-1::before,
.o-pie-chart-1::before,
.o-pin-1::before,
.o-pin-2::before,
.o-play-video-1::before,
.o-point-hand-1::before,
.o-portfolio-grid-1::before,
.o-print-1::before,
.o-property-1::before,
.o-puzzle-1::before,
.o-recurring-appointment-1::before,
.o-recycle-1::before,
.o-refresh-database-1::before,
.o-refresh-window-1::before,
.o-retro-camera-1::before,
.o-royal-1::before,
.o-sales-up-1::before,
.o-security-1::before,
.o-security-shield-1::before,
.o-set-square-1::before,
.o-settings-1::before,
.o-shopping-bag-1::before,
.o-smartphone-1::before,
.o-smartphone-2::before,
.o-social-1::before,
.o-sorting-1::before,
.o-source-code-1::before,
.o-speed-1::before,
.o-stack-1::before,
.o-star-1::before,
.o-strategy-1::before,
.o-telephone-operator-1::before,
.o-text-search-1::before,
.o-upload-1::before,
.o-upload-to-cloud-1::before,
.o-user-group-1::before,
.o-vector-1::before,
.o-visible-1::before,
.o-wall-clock-1::before,
.o-wallet-1::before {
	display: inline-block;
	font-family: "orion-font";
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	line-height: 1;
	text-decoration: inherit;
	text-rendering: optimizeLegibility;
	text-transform: none;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased; }

.o-audio-call-1::before {
	content: "\f707"; }

.o-bank-check-1::before {
	content: "\f708"; }

.o-binocular-1::before {
	content: "\f709"; }

.o-bookmark-1::before {
	content: "\f70a"; }

.o-briefcase-1::before {
	content: "\f70b"; }

.o-business-report-1::before {
	content: "\f70c"; }

.o-calculator-1::before {
	content: "\f70d"; }

.o-call-contact-1::before {
	content: "\f70e"; }

.o-champion-1::before {
	content: "\f70f"; }

.o-chat-app-1::before {
	content: "\f710"; }

.o-chemistry-1::before {
	content: "\f711"; }

.o-configuration-1::before {
	content: "\f712"; }

.o-currency-1::before {
	content: "\f713"; }

.o-customer-support-1::before {
	content: "\f714"; }

.o-data-app-1::before {
	content: "\f715"; }

.o-data-content-1::before {
	content: "\f716"; }

.o-design-1::before {
	content: "\f717"; }

.o-diploma-1::before {
	content: "\f718"; }

.o-dollar-sign-1::before {
	content: "\f719"; }

.o-download-1::before {
	content: "\f71a"; }

.o-download-cloud-1::before {
	content: "\f71b"; }

.o-duplicate-tool-1::before {
	content: "\f71c"; }

.o-earth-globe-1::before {
	content: "\f71d"; }

.o-edit-1::before {
	content: "\f71e"; }

.o-edit-window-1::before {
	content: "\f71f"; }

.o-error-bug-1::before {
	content: "\f720"; }

.o-expand-1::before {
	content: "\f721"; }

.o-filter-data-1::before {
	content: "\f722"; }

.o-firewall-1::before {
	content: "\f723"; }

.o-giftbox-1::before {
	content: "\f724"; }

.o-glasses-1::before {
	content: "\f725"; }

.o-grid-layout-1::before {
	content: "\f726"; }

.o-handshake-1::before {
	content: "\f727"; }

.o-heart-1::before {
	content: "\f728"; }

.o-hide-layer-1::before {
	content: "\f729"; }

.o-html5-1::before {
	content: "\f72a"; }

.o-idea-1::before {
	content: "\f72b"; }

.o-imac-screen-1::before {
	content: "\f72c"; }

.o-image-gallery-1::before {
	content: "\f72d"; }

.o-inkpot-1::before {
	content: "\f72e"; }

.o-key-1::before {
	content: "\f72f"; }

.o-label-1::before {
	content: "\f730"; }

.o-laptop-screen-1::before {
	content: "\f731"; }

.o-lifebelt-1::before {
	content: "\f732"; }

.o-lightning-bolt-1::before {
	content: "\f733"; }

.o-loop-1::before {
	content: "\f734"; }

.o-luxury-1::before {
	content: "\f735"; }

.o-mail-1::before {
	content: "\f736"; }

.o-maximize-window-1::before {
	content: "\f737"; }

.o-microphone-1::before {
	content: "\f738"; }

.o-microscope-1::before {
	content: "\f739"; }

.o-missile-1::before {
	content: "\f73a"; }

.o-money-box-1::before {
	content: "\f73b"; }

.o-mouse-1::before {
	content: "\f73c"; }

.o-network-1::before {
	content: "\f73d"; }

.o-page-1::before {
	content: "\f73e"; }

.o-paint-bucket-1::before {
	content: "\f73f"; }

.o-paper-plane-1::before {
	content: "\f740"; }

.o-pay-1::before {
	content: "\f741"; }

.o-pencil-1::before {
	content: "\f742"; }

.o-permalink-1::before {
	content: "\f743"; }

.o-pie-chart-1::before {
	content: "\f744"; }

.o-pin-1::before {
	content: "\f745"; }

.o-pin-2::before {
	content: "\f746"; }

.o-play-video-1::before {
	content: "\f747"; }

.o-point-hand-1::before {
	content: "\f748"; }

.o-portfolio-grid-1::before {
	content: "\f749"; }

.o-print-1::before {
	content: "\f74a"; }

.o-property-1::before {
	content: "\f74b"; }

.o-puzzle-1::before {
	content: "\f74c"; }

.o-recurring-appointment-1::before {
	content: "\f74d"; }

.o-recycle-1::before {
	content: "\f74e"; }

.o-refresh-database-1::before {
	content: "\f74f"; }

.o-refresh-window-1::before {
	content: "\f750"; }

.o-retro-camera-1::before {
	content: "\f751"; }

.o-royal-1::before {
	content: "\f752"; }

.o-sales-up-1::before {
	content: "\f753"; }

.o-security-1::before {
	content: "\f754"; }

.o-security-shield-1::before {
	content: "\f755"; }

.o-set-square-1::before {
	content: "\f756"; }

.o-settings-1::before {
	content: "\f757"; }

.o-shopping-bag-1::before {
	content: "\f758"; }

.o-smartphone-1::before {
	content: "\f759"; }

.o-smartphone-2::before {
	content: "\f75a"; }

.o-social-1::before {
	content: "\f75b"; }

.o-sorting-1::before {
	content: "\f75c"; }

.o-source-code-1::before {
	content: "\f75d"; }

.o-speed-1::before {
	content: "\f75e"; }

.o-stack-1::before {
	content: "\f75f"; }

.o-star-1::before {
	content: "\f760"; }

.o-strategy-1::before {
	content: "\f761"; }

.o-telephone-operator-1::before {
	content: "\f762"; }

.o-text-search-1::before {
	content: "\f763"; }

.o-upload-1::before {
	content: "\f764"; }

.o-upload-to-cloud-1::before {
	content: "\f765"; }

.o-user-group-1::before {
	content: "\f766"; }

.o-vector-1::before {
	content: "\f767"; }

.o-visible-1::before {
	content: "\f768"; }

.o-wall-clock-1::before {
	content: "\f769"; }

.o-wallet-1::before {
	content: "\f76a"; }

/* UI Icons
-------------------------------------------------------*/
@font-face {
	font-family: 'ui-icons';
	src: url("../fonts/ui-icons.eot?ucfpsh");
	src: url("../fonts/ui-icons.eot?ucfpsh#iefix") format("embedded-opentype"), url("../fonts/ui-icons.ttf?ucfpsh") format("truetype"), url("../fonts/ui-icons.woff?ucfpsh") format("woff"), url("../fonts/ui-icons.svg?ucfpsh#ui-icons") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block; }

[class^="ui-"], [class*=" ui-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'ui-icons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale; }

.ui-quote:before {
	content: "\e900"; }

.ui-arrow-scroll-down:before {
	content: "\f76b"; }

.ui-check:before {
	content: "\e80a"; }

.ui-arrow-down:before {
	content: "\f004"; }

.ui-arrow-up:before {
	content: "\f005"; }

.ui-arrow-right:before {
	content: "\f006"; }

.ui-arrow-left:before {
	content: "\f007"; }

.ui-twitter:before {
	content: "\f099"; }

.ui-facebook:before {
	content: "\f09a"; }

.ui-github:before {
	content: "\f09b"; }

.ui-pinterest:before {
	content: "\f0d2"; }

.ui-linkedin:before {
	content: "\f0e1"; }

.ui-youtube:before {
	content: "\f167"; }

.ui-xing:before {
	content: "\f168"; }

.ui-dropbox:before {
	content: "\f16b"; }

.ui-stackoverflow:before {
	content: "\f16c"; }

.ui-instagram:before {
	content: "\f16d"; }

.ui-flickr:before {
	content: "\f16e"; }

.ui-bitbucket:before {
	content: "\f171"; }

.ui-tumblr:before {
	content: "\f173"; }

.ui-apple:before {
	content: "\f179"; }

.ui-windows:before {
	content: "\f17a"; }

.ui-android:before {
	content: "\f17b"; }

.ui-dribbble:before {
	content: "\f17d"; }

.ui-skype:before {
	content: "\f17e"; }

.ui-foursquare:before {
	content: "\f180"; }

.ui-trello:before {
	content: "\f181"; }

.ui-vkontakte:before {
	content: "\f189"; }

.ui-vimeo:before {
	content: "\f194"; }

.ui-slack:before {
	content: "\f198"; }

.ui-reddit:before {
	content: "\f1a1"; }

.ui-stumbleupon:before {
	content: "\f1a4"; }

.ui-delicious:before {
	content: "\f1a5"; }

.ui-digg:before {
	content: "\f1a6"; }

.ui-behance:before {
	content: "\f1b4"; }

.ui-spotify:before {
	content: "\f1bc"; }

.ui-soundcloud:before {
	content: "\f1be"; }

.ui-codeopen:before {
	content: "\f1cb"; }

.ui-git:before {
	content: "\f1d3"; }

.ui-slideshare:before {
	content: "\f1e7"; }

.ui-twitch:before {
	content: "\f1e8"; }

.ui-lastfm:before {
	content: "\f202"; }

.ui-forumbee:before {
	content: "\f211"; }

.ui-sellsy:before {
	content: "\f213"; }

.ui-skyatlas:before {
	content: "\f216"; }

.ui-whatsapp:before {
	content: "\f232"; }

.ui-get-pocket:before {
	content: "\f265"; }

.ui-snapchat:before {
	content: "\f2ac"; }

.ui-telegram:before {
	content: "\f2c6"; }

.ui-play:before {
	content: "\f706"; }

/*-------------------------------------------------------*/
/* Grid
/*-------------------------------------------------------*/
.main-wrap {
	overflow: hidden;
	position: relative; }

.content-wrap {
	position: relative;
	z-index: 2; }

section {
	background-color: #fff;
	padding: 148px 0;
	position: relative;
	background-size: cover;
	background-position: center; }
	@media only screen and (max-width: 991px) {
		section {
			padding: 80px 0; } }

.container-semi-fluid {
	padding: 0 50px; }
	@media only screen and (max-width: 767px) {
		.container-semi-fluid {
			padding: 0 20px; } }

.main-container {
	margin: auto; }

@media (min-width: 1400px) {
	.container {
		max-width: 1320px; } }

/* Columns Gutters
-------------------------------------------------------*/
.row-16 {
	margin: 0 -8px; }

.row-16 > [class*="col-"] {
	padding: 0 8px; }

.row-20 {
	margin: 0 -10px; }

.row-20 > [class*="col-"] {
	padding: 0 10px; }

.row-30 {
	margin: 0 -15px; }

.row-30 > [class*="col-"] {
	padding: 0 15px; }

.row-60 {
	margin: 0 -30px; }

.row-60 > [class*="col-"] {
	padding: 0 30px; }

.row-80 {
	margin: 0 -40px; }

.row-80 > [class*="col-"] {
	padding: 0 40px; }

/* Flexbox
-------------------------------------------------------*/
.flex-parent {
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	        flex-flow: row nowrap;
	-webkit-box-pack: justify;
	        justify-content: space-between; }

.flex-child {
	-webkit-box-flex: 1 0 0;
	flex: 1 0 0; }

/* Flexbox Align
-------------------------------------------------------*/
.justify-content-center {
	-webkit-box-pack: center;
	justify-content: center; }

.align-items-center {
	-webkit-box-align: center;
	align-items: center; }

/*-------------------------------------------------------*/
/* Navigation
/*-------------------------------------------------------*/
.nav {
	min-height: 60px;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	z-index: 120;
	position: relative;
	-webkit-transition: height 0.3s ease-in-out;
	transition: height 0.3s ease-in-out;
	/* Dropdowns (large screen) */ }
	.nav__header {
		position: relative; }
	@media only screen and (min-width: 992px) {
		.nav {
			height: 112px; }
			.nav .nav.sticky {
				height: 60px; } }
	.nav__menu, .nav__dropdown-menu {
		list-style: none; }
	.nav__menu {
		position: relative;
		display: -webkit-inline-box;
		display: inline-flex;
		-webkit-box-align: center;
		        align-items: center; }
		.nav__menu > li {
			position: relative; }
		.nav__menu > li > a {
			font-family: "DM Sans", sans-serif;
			font-weight: 500;
			color: #ffffff;
			font-size: 16px;
			padding: 0 17px;
			letter-spacing: -0.05em;
			line-height: 112px;
			display: block;
			position: relative;
			-webkit-transition: line-height 0.6s cubic-bezier(0.86, 0, 0.07, 1), color 0.1s ease-in-out;
			transition: line-height 0.6s cubic-bezier(0.86, 0, 0.07, 1), color 0.1s ease-in-out; }
			.nav__menu > li > a:hover {
				color: #FE5900; }
		.nav__menu > li > .active {
			color: #FE5900; }
		.nav__menu > li:last-child > a {
			padding-right: 0; }
	@media only screen and (min-width: 992px) {
		.nav__wrap {
			display: block !important;
			height: auto !important; }
		.nav__wrap--text-center {
			text-align: center; }
		.nav__menu > li {
			display: inline-block;
			text-align: center; }
		.nav__dropdown-menu {
			position: absolute;
			top: 100%;
			z-index: 1000;
			min-width: 240px;
			width: 100%;
			text-align: left;
			padding: 16px 0;
			list-style: none;
			white-space: nowrap;
			background-color: #131313;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
			-webkit-background-clip: padding-box;
			background-clip: padding-box;
			display: block;
			visibility: hidden;
			opacity: 0;
			-webkit-transition: all 0.1s ease-in-out;
			transition: all 0.1s ease-in-out; }
			.nav__dropdown-menu > li > a {
				color: #fff;
				padding: 6px 30px;
				font-size: 0.9375rem;
				letter-spacing: -0.05em;
				display: block;
				position: relative;
				font-weight: 500;
				-webkit-transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
				transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1); }
				.nav__dropdown-menu > li > a:hover, .nav__dropdown-menu > li > a:focus {
					color: #fff; }
			.nav__dropdown-menu-label {
				position: relative; }
				.nav__dropdown-menu-label:after {
					content: "";
					display: block;
					position: absolute;
					border-bottom: 1px solid #fff;
					width: 0;
					-webkit-transition: width 0.3s linear;
					transition: width 0.3s linear; }
					.mobile .nav__dropdown-menu-label:after {
						display: none; }
			.nav__dropdown-menu > li > a:hover .nav__dropdown-menu-label:after {
				width: 100%; }
		.nav__dropdown-menu.hide-dropdown {
			visibility: hidden !important;
			opacity: 0 !important; }
		.nav__dropdown-menu--right {
			right: 0; }
		.nav__dropdown:hover > .nav__dropdown-menu {
			opacity: 1;
			visibility: visible; }
		.nav__dropdown-trigger {
			display: none; } }

.mobile body {
	cursor: pointer; }

/* Logo
-------------------------------------------------------*/
.logo {
	max-height: 21px;
	position: relative;
	top: -2px; }

.logo__url {
	height: 112px;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	-webkit-transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
	transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1); }

.logo--left {
	-webkit-box-pack: start;
	        justify-content: flex-start; }

/* Nav Flexbox
-------------------------------------------------------*/
header .flex-parent {
	-webkit-box-align: center;
	        align-items: center; }
	@media only screen and (max-width: 991px) {
		header .flex-parent {
			display: block; } }

header .flex-child {
	-webkit-box-flex: 1;
	        flex: 1 0 0; }
	@media only screen and (max-width: 991px) {
		header .flex-child {
			width: 100%; } }

header .flex-child.flex-right {
	text-align: right; }

.nav--align-right {
	margin-left: auto; }

.nav--align-left {
	margin-right: auto; }
	@media only screen and (min-width: 992px) {
		.nav--align-left {
			margin-left: 60px; } }

@media only screen and (min-width: 992px) {
	.nav--align-center .nav__header {
		-webkit-box-flex: 1;
		        flex: 1 0 0; }
	.nav--align-center .nav__wrap {
		-webkit-box-flex: 4;
		        flex: 4 0 0;
		text-align: center; } }

/* Nav Socials
-------------------------------------------------------*/
.nav__socials a {
	color: #fff;
	float: left;
	margin-bottom: 0; }
	.nav__socials a:hover, .nav__socials a:focus {
		color: #FE5900; }

.nav__mobile-socials {
	padding: 15px 0 32px; }
	.nav__mobile-socials a {
		color: #fff; }

/* Nav Icon Toggle (mobile nav)
-------------------------------------------------------*/
.nav__icon-toggle {
	position: absolute;
	top: 50%;
	right: 0;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	padding: 9px 0 9px 10px;
	background-color: transparent;
	border: none;
	z-index: 50; }
	@media only screen and (min-width: 992px) {
		.nav__icon-toggle {
			display: none; } }
	.nav__icon-toggle-bar {
		background-color: #fff;
		width: 18px;
		display: block;
		height: 2px;
		border-radius: 1px;
		margin-bottom: 4px; }
		.nav__icon-toggle-bar:last-child {
			margin-bottom: 0; }
	.nav__icon-toggle:focus, .nav__icon-toggle:hover {
		background-color: transparent;
		outline: 0; }
		.nav__icon-toggle:focus .nav__icon-toggle-bar, .nav__icon-toggle:hover .nav__icon-toggle-bar {
			background-color: #FE5900; }

/* Sticky Nav
-------------------------------------------------------*/
@media only screen and (min-width: 992px) {
	.nav--sticky {
		position: fixed;
		height: 112px;
		left: 0;
		right: 0;
		top: 0;
		-webkit-transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
		transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1); }
		.nav--sticky.sticky {
			box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
			background-color: #111111;
			z-index: 120;
			height: 60px; }
			.nav--sticky.sticky .nav__menu > li > a {
				line-height: 60px; }
			.nav--sticky.sticky .logo-wrap a {
				height: 60px; }
	.nav.sticky {
		height: 60px; } }

.nav--always-fixed {
	position: fixed;
	top: 0;
	left: 0;
	right: 0; }

/* Nav White
-------------------------------------------------------*/
.nav--white .nav--sticky.sticky {
	background-color: #fff; }

.nav--white .nav-link:not(.active),
.nav--white .nav__socials a,
.nav--white .nav__mobile-socials a {
	color: #131313; }

.nav--white .nav__menu > li > a:hover,
.nav--white .nav__menu > li > a:focus,
.nav--white .nav__socials a:hover,
.nav--white .nav__socials a:focus {
	color: #FE5900; }

/* Nav Pattern
-------------------------------------------------------*/
.nav--pattern,
.nav--pattern .nav__holder {
	background: url("../img/dark_wall.png"); }

/* Go to Top
-------------------------------------------------------*/
#back-to-top {
	display: block;
	z-index: 100;
	width: 34px;
	height: 34px;
	text-align: center;
	font-size: 12px;
	position: fixed;
	bottom: -34px;
	right: 20px;
	line-height: 35px;
	background-color: rgba(19, 19, 19, 0.3);
	border-radius: 50%;
	-webkit-transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
	transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
	text-decoration: none; }
	#back-to-top i {
		-webkit-transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
		transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1); }
	#back-to-top a {
		display: block;
		color: #fff; }
	#back-to-top.show {
		bottom: 20px; }
	#back-to-top:hover {
		background-color: #FE5900;
		bottom: 24px; }
	#back-to-top:hover i {
		color: #fff; }

/*-------------------------------------------------------*/
/* Nav Mobile Styles
/*-------------------------------------------------------*/
@media only screen and (max-width: 991px) {
	.nav {
		min-height: 60px;
		background-color: #131313; }
		.nav--white {
			background-color: #fff; }
			.nav--white .nav__icon-toggle-bar {
				background-color: #131313; }
			.nav--white .nav__dropdown-menu li a {
				color: #686868; }
			.nav--white .nav__menu li a {
				border-bottom: 1px solid #E9E9E9; }
		.nav__menu {
			display: block; }
		.nav__menu li a {
			padding: 0;
			line-height: 50px;
			height: 50px;
			display: block;
			color: #fff;
			border-bottom: 1px solid #323232; }
		.nav__dropdown-menu a {
			color: #686868; }
			.nav__dropdown-menu a:hover {
				color: #FE5900; }
		.nav__dropdown-menu > li > a {
			padding-left: 10px; }
		.nav__dropdown-trigger {
			display: block;
			width: 20px;
			height: 50px;
			line-height: 50px;
			font-size: 0.75rem;
			text-align: center;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 50;
			cursor: pointer;
			-webkit-transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
			transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1); }
			.nav__dropdown-trigger--is-open {
				-webkit-transform: rotate(180deg);
				transform: rotate(180deg); }
		.nav__dropdown-menu {
			display: none;
			width: 100% !important; }
		.nav__dropdown-trigger.active + .nav__dropdown-menu {
			display: block; }
	.logo-wrap {
		float: left; }
		.logo-wrap a {
			display: table-cell;
			vertical-align: middle; }
	.logo-wrap a,
	.nav__socials .social-icons {
		height: 60px; } }

/*-------------------------------------------------------*/
/* Footer
/*-------------------------------------------------------*/
@media only screen and (min-width: 992px) {
	.footer {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		z-index: 1; } }

/* Footer Widgets
-------------------------------------------------------*/
.footer__widgets {
	padding: 92px 0 48px; }
	.footer__widgets .social,
	.footer__widgets a:hover,
	.footer__widgets a:focus {
		color: #fff; }
	@media only screen and (min-width: 992px) {
		.footer__widgets .row > div:last-child {
			text-align: right; } }

/* Bottom Footer
-------------------------------------------------------*/
.copyright {
	display: inline-block;
	color: #686868;
	font-size: 0.875rem; }
	.copyright-wrap {
		padding: 40px 0;
		border-top: 1px solid #323232; }
	.copyright a {
		color: inherit; }
		.copyright a:hover, .copyright a:focus {
			color: #FE5900; }

/*-------------------------------------------------------*/
/* Preloader
/*-------------------------------------------------------*/
.loader-mask {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100vh;
	background-color: #000000;
	z-index: 99999; }

.loader {
	display: block;
	position: relative;
	left: 50%;
	top: 50%;
	width: 150px;
	height: 150px;
	font-size: 0;
	margin: -75px 0 0 -75px;
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: #FE5900;
	-webkit-animation: spin 1.7s linear infinite;
	        animation: spin 1.7s linear infinite;
	z-index: 11; }
	.loader:before {
		content: "";
		position: absolute;
		top: 5px;
		left: 5px;
		right: 5px;
		bottom: 5px;
		border-radius: 50%;
		border: 3px solid transparent;
		border-top-color: #FE5900;
		-webkit-animation: spin-reverse .6s linear infinite;
		        animation: spin-reverse .6s linear infinite; }
	.loader:after {
		content: "";
		position: absolute;
		top: 15px;
		left: 15px;
		right: 15px;
		bottom: 15px;
		border-radius: 50%;
		border: 3px solid transparent;
		border-top-color: #FE5900;
		-webkit-animation: spin 1s linear infinite;
		        animation: spin 1s linear infinite; }

@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg); }
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg); } }

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg); }
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg); } }

@keyframes spin-reverse {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg); }
	100% {
		-webkit-transform: rotate(-360deg);
		transform: rotate(-360deg); } }

@-webkit-keyframes spin-reverse {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg); }
	100% {
		-webkit-transform: rotate(-360deg);
		transform: rotate(-360deg); } }

/*-------------------------------------------------------*/
/* Buttons
/*-------------------------------------------------------*/
.btn {
	font-weight: 700;
	font-family: "DM Sans", sans-serif;
	overflow: hidden;
	display: inline-block;
	text-decoration: none;
	text-align: center;
	height: auto;
	width: auto;
	border: 0;
	border-radius: 0;
	letter-spacing: -0.05em;
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
	color: #fff;
	background-color: #FE5900;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	position: relative;
	z-index: 1;
	white-space: nowrap;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none; }
	.btn:hover {
		color: #fff;
		box-shadow: 0 12px 20px rgba(254, 89, 0, 0.25);
		border-color: transparent; }
	.btn:focus {
		outline: none;
		color: #fff; }
	.btn--lg {
		font-size: 1.125rem;
		padding: 15px 40px; }
	.btn--md {
		font-size: 0.9375rem;
		padding: 12px 32px; }
	.btn--color {
		background-color: #FE5900; }
		.btn--color:hover {
			opacity: .92; }
	.btn--stroke {
		border: 2px solid #FE5900;
		background-color: transparent;
		color: #FE5900; }
	.btn--dark {
		background-color: #131313; }
	.btn--white {
		background-color: #fff;
		color: #131313; }
	.btn--light {
		background-color: #F6F6F6;
		color: #131313; }
	.btn--wide {
		width: 100%; }
	.btn--stroke:hover, .btn--stroke:focus, .btn--dark:hover, .btn--dark:focus, .btn--white:hover, .btn--white:focus, .btn--light:hover, .btn--light:focus {
		background-color: #FE5900;
		border-color: transparent; }
	.btn i {
		font-size: .75rem;
		position: relative;
		margin-left: 14px;
		line-height: 1; }

.rounded,
.rounded:before {
	border-radius: 70px; }

.section-buttons {
	margin-bottom: 50px; }
	.section-buttons a {
		margin-bottom: 10px; }

/* Input Buttons
-------------------------------------------------------*/
.btn--button {
	border: none;
	margin-bottom: 0;
	width: auto; }
	.btn--button.btn--color, .btn--button.btn--dark {
		color: #fff; }
		.btn--button.btn--color:focus, .btn--button.btn--dark:focus {
			background-color: #FE5900; }
	.btn--button.btn--wide {
		width: 100%; }

/*-------------------------------------------------------*/
/* Form Elements
/*-------------------------------------------------------*/
input,
select,
textarea {
	height: 48px;
	border-radius: 0;
	border: 1px solid transparent;
	background-color: #F6F6F6;
	width: 100%;
	margin-bottom: 16px;
	padding: 0 12px;
	-webkit-transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
	transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out; }

textarea {
	height: auto;
	display: block;
	padding: 8px 16px; }

input:focus,
textarea:focus {
	border-color: #FE5900;
	background-color: #fff;
	outline: none;
	box-shadow: none; }

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer; }
	button:focus,
	input[type="button"]:focus,
	input[type="reset"]:focus,
	input[type="submit"]:focus {
		background-color: #FE5900; }

/* Change Color of Placeholders */
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	color: #686868; }

input:-moz-placeholder,
textarea:-moz-placeholder {
	color: #686868;
	opacity: 1; }

input::-moz-placeholder,
textarea::-moz-placeholder {
	color: #686868;
	opacity: 1; }

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	color: #686868; }

label {
	color: #131313;
	font-family: "DM Sans", sans-serif;
	font-size: 1rem;
	display: inline-block;
	vertical-align: middle;
	margin-bottom: 10px;
	line-height: 1.5; }

.form-group {
	position: relative; }

/* Checkboxes & Radio Buttons
-------------------------------------------------------*/
input[type="checkbox"],
input[type="radio"] {
	min-width: 20px;
	width: 20px;
	height: 20px;
	margin-bottom: 0;
	vertical-align: middle;
	margin-right: 8px;
	text-align: center;
	line-height: 18px;
	border: 1px solid #d0d0d0;
	background-color: #fff;
	-moz-appearance: none;
	-webkit-appearance: none; }
	input[type="checkbox"]:hover,
	input[type="radio"]:hover {
		border-color: #b6b6b6;
		background-color: #fff; }

input[type="checkbox"]:checked:after {
	content: '\e80a';
	font-family: 'ui-icons';
	font-size: 0.75rem; }

input[type="checkbox"] + label,
input[type="radio"] + label {
	cursor: pointer;
	margin-bottom: 0;
	position: relative;
	line-height: 22px; }

input[type="radio"] {
	border-radius: 50%; }
	input[type="radio"]:checked:after {
		content: '';
		width: 8px;
		height: 8px;
		position: relative;
		top: -2px;
		border-radius: 50%;
		display: inline-block;
		background-color: #131313; }

.radio,
.checkbox {
	list-style: none; }

.consent-checkbox {
	margin-top: 12px;
	margin-bottom: 32px;
	overflow: hidden; }
	.consent-checkbox__label {
		line-height: 20px;
		font-size: 1rem;
		color: #686868;
		font-family: "DM Sans", sans-serif; }
	.consent-checkbox input[type=checkbox] {
		float: left; }

/* GDPR Checkbox
-------------------------------------------------------*/
.gdpr-checkbox {
	margin-bottom: 24px; }
	.gdpr-checkbox__label {
		display: -webkit-box;
		display: flex;
		color: #686868;
		font-family: "DM Sans", sans-serif;
		line-height: 20px;
		font-size: 1rem;
		font-weight: 400;
		text-align: left;
		margin-bottom: 0; }

/*-------------------------------------------------------*/
/* Sliders
/*-------------------------------------------------------*/
/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
	position: relative; }

.flickity-enabled:focus {
	outline: 0; }

.flickity-viewport {
	overflow: hidden;
	position: relative;
	height: 100%; }

.flickity-slider {
	position: absolute;
	width: 100%;
	height: 100%; }

.flickity-enabled.is-draggable {
	-webkit-tap-highlight-color: transparent;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
	cursor: -webkit-grabbing;
	cursor: grabbing; }

.flickity-button {
	position: absolute;
	background: rgba(255, 255, 255, 0.75);
	border: none;
	color: #333; }

.flickity-button:hover {
	background: #fff;
	cursor: pointer; }

.flickity-button:focus {
	outline: 0;
	box-shadow: 0 0 0 5px #19f; }

.flickity-button:active {
	opacity: .6; }

.flickity-button:disabled {
	opacity: .3;
	cursor: auto;
	pointer-events: none; }

.flickity-button-icon {
	fill: currentColor; }

.flickity-prev-next-button {
	top: 50%;
	width: 44px;
	height: 44px;
	border-radius: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%); }

.flickity-prev-next-button.previous {
	left: 10px; }

.flickity-prev-next-button.next {
	right: 10px; }

.flickity-rtl .flickity-prev-next-button.previous {
	left: auto;
	right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
	right: auto;
	left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
	position: absolute;
	left: 20%;
	top: 20%;
	width: 60%;
	height: 60%; }

.flickity-page-dots {
	position: absolute;
	width: 100%;
	bottom: -25px;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
	line-height: 1; }

.flickity-rtl .flickity-page-dots {
	direction: rtl; }

.flickity-page-dots .dot {
	display: inline-block;
	width: 10px;
	height: 10px;
	margin: 0 8px;
	background: #333;
	border-radius: 50%;
	opacity: .25;
	cursor: pointer; }

.flickity-page-dots .dot.is-selected {
	opacity: 1; }

.flickity-slide {
	width: 100%; }
	@media only screen and (min-width: 992px) {
		.flickity-slide {
			width: 40%; } }

.flickity-page-dots .dot {
	background-color: #686868; }
	.flickity-page-dots .dot.is-selected {
		background-color: #FE5900; }

/*-------------------------------------------------------*/
/* Accordions and Toggles
/*-------------------------------------------------------*/
.accordion__panel {
	border-bottom: 2px solid #E9E9E9; }

.accordion:first-child .accordion__panel {
	border-top: 2px solid #E9E9E9; }

.accordion__heading {
	position: relative;
	padding: 0;
	-webkit-transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
	transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1); }

.accordion__toggle {
	display: block;
	width: 12px;
	height: 2px;
	background-color: #131313;
	right: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	position: absolute; }
	.accordion__toggle:after {
		content: "";
		display: block;
		width: 12px;
		height: 2px;
		position: absolute;
		top: 0;
		background-color: inherit;
		-webkit-transform: rotate(-90deg);
		transform: rotate(-90deg); }

.accordion__body {
	padding-bottom: 24px; }
	.accordion__body p:last-child {
		margin-bottom: 0; }

.accordion__title {
	font-size: 1.5rem;
	margin-bottom: 0; }
	@media only screen and (max-width: 767px) {
		.accordion__title {
			font-size: 1.25rem; } }

.accordion--is-open .accordion__toggle:after {
	display: none; }

.accordion__link {
	display: block;
	position: relative;
	padding: 18px 0; }
	.accordion__link:hover .accordion__title {
		color: #686868; }

/*-------------------------------------------------------*/
/* Social Icons
/*-------------------------------------------------------*/
.social {
	display: inline-block;
	border-radius: 50%;
	text-align: center;
	margin-right: 20px;
	margin-bottom: 6px;
	font-size: 1rem;
	color: #686868;
	-webkit-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out; }
	.social:hover, .social:focus {
		color: #FE5900; }
	.social:last-child {
		margin-right: 0; }

.socials__text {
	display: inline-block;
	margin-left: 5px; }

.socials--rounded .social {
	border-radius: 50%;
	background-color: #131313;
	color: #fff;
	width: 30px;
	height: 30px;
	margin-right: 8px; }
	.socials--rounded .social i {
		line-height: 30px; }
	.socials--rounded .social:hover, .socials--rounded .social:focus {
		background-color: #FE5900; }

.socials--large .social {
	width: 40px;
	height: 40px;
	font-size: 1rem; }
	.socials--large .social i {
		line-height: 40px; }

.socials--colored .social-facebook {
	background-color: #39599f; }

.socials--colored .social-twitter {
	background-color: #55ACEE; }

.socials--colored .social-linkedin {
	background-color: #0e76a8; }

.socials--colored .social:hover,
.socials--colored .social:focus {
	background-color: #131313; }

/*-------------------------------------------------------*/
/* Tables
/*-------------------------------------------------------*/
table th {
	font-weight: normal; }

table caption {
	margin-bottom: 10px; }

table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
	border: 1px solid #E9E9E9;
	padding: 10px;
	letter-spacing: -0.05em; }

table > thead > tr > th {
	border-bottom: none; }

table thead tr th {
	font-size: 12px;
	color: #131313;
	font-family: "DM Sans", sans-serif;
	text-transform: uppercase;
	font-weight: bold; }

.wp-block-table.is-style-stripes td {
	border-color: transparent; }

.wp-block-table.is-style-stripes tr:nth-child(odd) {
	background-color: #F6F6F6; }

/*-------------------------------------------------------*/
/* Modals
/*-------------------------------------------------------*/
/* Magnific Popup */
.mfp-bg, .mfp-wrap {
	position: fixed;
	left: 0;
	top: 0; }

.mfp-bg, .mfp-container, .mfp-wrap {
	height: 100%;
	width: 100%; }

.mfp-arrow:after, .mfp-arrow:before, .mfp-container:before, .mfp-figure:after {
	content: ''; }

.mfp-bg {
	z-index: 1042;
	overflow: hidden;
	background: #0b0b0b;
	opacity: .8; }

.mfp-wrap {
	z-index: 1043;
	outline: 0 !important;
	-webkit-backface-visibility: hidden; }

.mfp-container {
	text-align: center;
	position: absolute;
	left: 0;
	top: 0;
	padding: 0 8px;
	box-sizing: border-box; }

.mfp-container:before {
	display: inline-block;
	height: 100%;
	vertical-align: middle; }

.mfp-align-top .mfp-container:before {
	display: none; }

.mfp-content {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin: 0 auto;
	text-align: left;
	z-index: 1045; }

.mfp-ajax-holder .mfp-content, .mfp-inline-holder .mfp-content {
	width: 100%;
	cursor: auto; }

.mfp-ajax-cur {
	cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
	cursor: -webkit-zoom-out;
	cursor: zoom-out; }

.mfp-zoom {
	cursor: pointer;
	cursor: -webkit-zoom-in;
	cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
	cursor: auto; }

.mfp-arrow, .mfp-close, .mfp-counter, .mfp-preloader {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	    user-select: none; }

.mfp-loading.mfp-figure {
	display: none; }

.mfp-hide {
	display: none !important; }

.mfp-preloader {
	color: #CCC;
	position: absolute;
	top: 50%;
	width: auto;
	text-align: center;
	margin-top: -.8em;
	left: 8px;
	right: 8px;
	z-index: 1044; }

.mfp-preloader a {
	color: #CCC; }

.mfp-close, .mfp-preloader a:hover {
	color: #FFF; }

.mfp-s-error .mfp-content, .mfp-s-ready .mfp-preloader {
	display: none; }

button.mfp-arrow, button.mfp-close {
	overflow: visible;
	cursor: pointer;
	background: 0 0;
	border: 0;
	-webkit-appearance: none;
	display: block;
	outline: 0;
	padding: 0;
	z-index: 1046;
	box-shadow: none;
	touch-action: manipulation; }

.mfp-figure:after, .mfp-iframe-scaler iframe {
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	position: absolute;
	left: 0; }

button::-moz-focus-inner {
	padding: 0;
	border: 0; }

.mfp-close {
	width: 44px;
	height: 44px;
	line-height: 44px;
	position: absolute;
	right: 0;
	top: 0;
	text-decoration: none;
	text-align: center;
	opacity: .65;
	padding: 0 0 18px 10px;
	font-style: normal;
	font-size: 28px;
	font-family: Arial,Baskerville,monospace; }

.mfp-close:focus, .mfp-close:hover {
	opacity: 1; }

.mfp-close:active {
	top: 1px; }

.mfp-close-btn-in .mfp-close {
	color: #333; }

.mfp-iframe-holder .mfp-close, .mfp-image-holder .mfp-close {
	color: #FFF;
	right: -6px;
	text-align: right;
	padding-right: 6px;
	width: 100%; }

.mfp-counter {
	position: absolute;
	top: 0;
	right: 0;
	color: #CCC;
	font-size: 12px;
	line-height: 18px;
	white-space: nowrap; }

.mfp-figure, img.mfp-img {
	line-height: 0; }

.mfp-arrow {
	position: absolute;
	opacity: .65;
	margin: -55px 0 0;
	top: 50%;
	padding: 0;
	width: 90px;
	height: 110px;
	-webkit-tap-highlight-color: transparent; }

.mfp-arrow:active {
	margin-top: -54px; }

.mfp-arrow:focus, .mfp-arrow:hover {
	opacity: 1; }

.mfp-arrow:after, .mfp-arrow:before {
	display: block;
	width: 0;
	height: 0;
	position: absolute;
	left: 0;
	top: 0;
	margin-top: 35px;
	margin-left: 35px;
	border: inset transparent; }

.mfp-arrow:after {
	border-top-width: 13px;
	border-bottom-width: 13px;
	top: 8px; }

.mfp-arrow:before {
	border-top-width: 21px;
	border-bottom-width: 21px;
	opacity: .7; }

.mfp-arrow-left {
	left: 0; }

.mfp-arrow-left:after {
	border-right: 17px solid #FFF;
	margin-left: 31px; }

.mfp-arrow-left:before {
	margin-left: 25px;
	border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
	right: 0; }

.mfp-arrow-right:after {
	border-left: 17px solid #FFF;
	margin-left: 39px; }

.mfp-arrow-right:before {
	border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
	padding-top: 40px;
	padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
	line-height: 0;
	width: 100%;
	max-width: 900px; }

.mfp-image-holder .mfp-content, img.mfp-img {
	max-width: 100%; }

.mfp-iframe-holder .mfp-close {
	top: -40px; }

.mfp-iframe-scaler {
	width: 100%;
	height: 0;
	overflow: hidden;
	padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
	display: block;
	top: 0;
	width: 100%;
	height: 100%;
	background: #000; }

.mfp-figure:after, img.mfp-img {
	width: auto;
	height: auto;
	display: block; }

img.mfp-img {
	box-sizing: border-box;
	padding: 40px 0;
	margin: 0 auto; }

.mfp-figure:after {
	top: 40px;
	bottom: 40px;
	right: 0;
	z-index: -1;
	background: #444; }

.mfp-figure small {
	color: #BDBDBD;
	display: block;
	font-size: 12px;
	line-height: 14px; }

.mfp-figure figure {
	margin: 0; }

.mfp-bottom-bar {
	margin-top: -36px;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	cursor: auto; }

.mfp-title {
	text-align: left;
	line-height: 18px;
	color: #F3F3F3;
	word-wrap: break-word;
	padding-right: 36px; }

.mfp-gallery .mfp-image-holder .mfp-figure {
	cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
	.mfp-img-mobile .mfp-image-holder {
		padding-left: 0;
		padding-right: 0; }
	.mfp-img-mobile img.mfp-img {
		padding: 0; }
	.mfp-img-mobile .mfp-figure:after {
		top: 0;
		bottom: 0; }
	.mfp-img-mobile .mfp-figure small {
		display: inline;
		margin-left: 5px; }
	.mfp-img-mobile .mfp-bottom-bar {
		background: rgba(0, 0, 0, 0.6);
		bottom: 0;
		margin: 0;
		top: auto;
		padding: 3px 5px;
		position: fixed;
		box-sizing: border-box; }
	.mfp-img-mobile .mfp-bottom-bar:empty {
		padding: 0; }
	.mfp-img-mobile .mfp-counter {
		right: 5px;
		top: 3px; }
	.mfp-img-mobile .mfp-close {
		top: 0;
		right: 0;
		width: 35px;
		height: 35px;
		line-height: 35px;
		background: rgba(0, 0, 0, 0.6);
		position: fixed;
		text-align: center;
		padding: 0; } }

@media all and (max-width: 900px) {
	.mfp-arrow {
		-webkit-transform: scale(0.75);
		transform: scale(0.75); }
	.mfp-arrow-left {
		-webkit-transform-origin: 0;
		transform-origin: 0; }
	.mfp-arrow-right {
		-webkit-transform-origin: 100%;
		transform-origin: 100%; }
	.mfp-container {
		padding-left: 6px;
		padding-right: 6px; } }

.mfp-iframe-holder .mfp-content {
	max-width: 1400px; }

.modal-open {
	padding: 0 !important;
	overflow-y: scroll; }

.mfp-zoom-in {
	/* start state */
	/* animate in */
	/* animate out */ }
	.mfp-zoom-in .mfp-iframe-scaler {
		overflow: visible; }
	.mfp-zoom-in .mfp-with-anim {
		opacity: 0;
		-webkit-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
		-webkit-transform: scale(0.8);
		        transform: scale(0.8); }
	.mfp-zoom-in.mfp-bg {
		opacity: 0;
		-webkit-transition: all 0.3s ease-out;
		transition: all 0.3s ease-out; }
	.mfp-zoom-in.mfp-ready .mfp-with-anim {
		opacity: 1;
		-webkit-transform: scale(1);
		        transform: scale(1); }
	.mfp-zoom-in.mfp-ready.mfp-bg {
		opacity: 0.8; }
	.mfp-zoom-in.mfp-removing .mfp-with-anim {
		-webkit-transform: scale(0.8);
		        transform: scale(0.8);
		opacity: 0; }
	.mfp-zoom-in.mfp-removing.mfp-bg {
		opacity: 0; }

/*-------------------------------------------------------*/
/* Intro
/*-------------------------------------------------------*/
.intro {
	padding-bottom: 172px; }
	.intro__text {
		max-width: 920px;
		margin: 0 auto;
		color: #fff;
		font-size: 1.75rem; }
		@media only screen and (min-width: 992px) {
			.intro__text {
				font-size: 2.3125rem; } }

/*-------------------------------------------------------*/
/* Play Button
/*-------------------------------------------------------*/
.play-btn {
	display: inline-block;
	position: absolute;
	z-index: 3;
	width: 72px;
	height: 72px;
	border: 3px solid #fff;
	border-radius: 50%;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out; }
	.play-btn:before {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		margin: -10px 0 0 -6px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 10px 0 10px 16px;
		border-color: transparent transparent transparent #fff; }

/* Icon Wave */
.icon-wave {
	-webkit-transition: -webkit-transform ease-out 0.1s, background 0.2s;
	-webkit-transition: background 0.2s, -webkit-transform ease-out 0.1s;
	transition: background 0.2s, -webkit-transform ease-out 0.1s;
	transition: transform ease-out 0.1s, background 0.2s;
	transition: transform ease-out 0.1s, background 0.2s, -webkit-transform ease-out 0.1s; }

.icon-wave:after {
	pointer-events: none;
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	content: '';
	box-sizing: content-box;
	top: 0;
	left: 0;
	padding: 0;
	z-index: -1;
	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
	opacity: 0;
	-webkit-transform: scale(0.9);
	transform: scale(0.9); }

.icon-wave:hover {
	background-color: #fff; }
	.icon-wave:hover:before {
		border-color: transparent transparent transparent #131313; }

.icon-wave:hover:after {
	-webkit-animation: sonarEffect 1s ease-out 75ms;
	animation: sonarEffect 1s ease-out 75ms; }

@-webkit-keyframes sonarEffect {
	0% {
		opacity: 0.3; }
	40% {
		opacity: 0.5;
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255, 255, 255, 0.5); }
	100% {
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255, 255, 255, 0.5);
		-webkit-transform: scale(1.3);
		opacity: 0; } }

@keyframes sonarEffect {
	0% {
		opacity: 0.3; }
	40% {
		opacity: 0.5;
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255, 255, 255, 0.5); }
	100% {
		box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #fff, 0 0 0 10px rgba(255, 255, 255, 0.5);
		-webkit-transform: scale(1.3);
		        transform: scale(1.3);
		opacity: 0; } }

/*-------------------------------------------------------*/
/* Alerts
/*-------------------------------------------------------*/
.alert-dismissible .close {
	padding: 0.70rem 1.25rem;
	height: 100%; }

/*-----------------------------------------------------------------------------

-	Revolution Slider 5.0 Default Style Settings -

Screen Stylesheet

version:   	5.4.5
date:      	15/05/17
author:		themepunch
email:     	info@themepunch.com
website:   	http://www.themepunch.com
-----------------------------------------------------------------------------*/
#debungcontrolls, .debugtimeline {
	width: 100%;
	box-sizing: border-box; }

.rev_column, .rev_column .tp-parallax-wrap, .tp-svg-layer svg {
	vertical-align: top; }

#debungcontrolls {
	z-index: 100000;
	position: fixed;
	bottom: 0;
	height: auto;
	background: rgba(0, 0, 0, 0.6);
	padding: 10px; }

.debugtimeline {
	height: 10px;
	position: relative;
	margin-bottom: 3px;
	display: none;
	white-space: nowrap; }

.debugtimeline:hover {
	height: 15px; }

.the_timeline_tester {
	background: #e74c3c;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 0; }

.rs-go-fullscreen {
	position: fixed !important;
	width: 100% !important;
	height: 100% !important;
	top: 0 !important;
	left: 0 !important;
	z-index: 9999999 !important;
	background: #fff !important; }

.debugtimeline.tl_slide .the_timeline_tester {
	background: #f39c12; }

.debugtimeline.tl_frame .the_timeline_tester {
	background: #3498db; }

.debugtimline_txt {
	color: #fff;
	font-weight: 400;
	font-size: 7px;
	position: absolute;
	left: 10px;
	top: 0;
	white-space: nowrap;
	line-height: 10px; }

.rtl {
	direction: rtl; }

@font-face {
	font-family: revicons;
	src: url(../fonts/revicons/revicons.eot?5510888);
	src: url(../fonts/revicons/revicons.eot?5510888#iefix) format("embedded-opentype"), url(../fonts/revicons/revicons.woff?5510888) format("woff"), url(../fonts/revicons/revicons.ttf?5510888) format("truetype"), url(../fonts/revicons/revicons.svg?5510888#revicons) format("svg");
	font-weight: 400;
	font-style: normal; }

[class*=" revicon-"]:before, [class^=revicon-]:before {
	font-family: revicons;
	font-style: normal;
	font-weight: 400;
	speak: none;
	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: .2em;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	line-height: 1em;
	margin-left: .2em; }

.revicon-search-1:before {
	content: '\e802'; }

.revicon-pencil-1:before {
	content: '\e831'; }

.revicon-picture-1:before {
	content: '\e803'; }

.revicon-cancel:before {
	content: '\e80a'; }

.revicon-info-circled:before {
	content: '\e80f'; }

.revicon-trash:before {
	content: '\e801'; }

.revicon-left-dir:before {
	content: '\e817'; }

.revicon-right-dir:before {
	content: '\e818'; }

.revicon-down-open:before {
	content: '\e83b'; }

.revicon-left-open:before {
	content: '\e819'; }

.revicon-right-open:before {
	content: '\e81a'; }

.revicon-angle-left:before {
	content: '\e820'; }

.revicon-angle-right:before {
	content: '\e81d'; }

.revicon-left-big:before {
	content: '\e81f'; }

.revicon-right-big:before {
	content: '\e81e'; }

.revicon-magic:before {
	content: '\e807'; }

.revicon-picture:before {
	content: '\e800'; }

.revicon-export:before {
	content: '\e80b'; }

.revicon-cog:before {
	content: '\e832'; }

.revicon-login:before {
	content: '\e833'; }

.revicon-logout:before {
	content: '\e834'; }

.revicon-video:before {
	content: '\e805'; }

.revicon-arrow-combo:before {
	content: '\e827'; }

.revicon-left-open-1:before {
	content: '\e82a'; }

.revicon-right-open-1:before {
	content: '\e82b'; }

.revicon-left-open-mini:before {
	content: '\e822'; }

.revicon-right-open-mini:before {
	content: '\e823'; }

.revicon-left-open-big:before {
	content: '\e824'; }

.revicon-right-open-big:before {
	content: '\e825'; }

.revicon-left:before {
	content: '\e836'; }

.revicon-right:before {
	content: '\e826'; }

.revicon-ccw:before {
	content: '\e808'; }

.revicon-arrows-ccw:before {
	content: '\e806'; }

.revicon-palette:before {
	content: '\e829'; }

.revicon-list-add:before {
	content: '\e80c'; }

.revicon-doc:before {
	content: '\e809'; }

.revicon-left-open-outline:before {
	content: '\e82e'; }

.revicon-left-open-2:before {
	content: '\e82c'; }

.revicon-right-open-outline:before {
	content: '\e82f'; }

.revicon-right-open-2:before {
	content: '\e82d'; }

.revicon-equalizer:before {
	content: '\e83a'; }

.revicon-layers-alt:before {
	content: '\e804'; }

.revicon-popup:before {
	content: '\e828'; }

.rev_slider_wrapper {
	position: relative;
	z-index: 0;
	width: 100%; }

.rev_slider {
	position: relative;
	overflow: visible; }

.entry-content .rev_slider a, .rev_slider a {
	box-shadow: none; }

.tp-overflow-hidden {
	overflow: hidden !important; }

.group_ov_hidden {
	overflow: hidden; }

.rev_slider img, .tp-simpleresponsive img {
	max-width: none !important;
	-webkit-transition: none;
	transition: none;
	margin: 0;
	padding: 0;
	border: none; }

.rev_slider .no-slides-text {
	font-weight: 700;
	text-align: center;
	padding-top: 80px; }

.rev_slider > ul, .rev_slider > ul > li, .rev_slider > ul > li:before, .rev_slider_wrapper > ul, .tp-revslider-mainul > li, .tp-revslider-mainul > li:before, .tp-simpleresponsive > ul, .tp-simpleresponsive > ul > li, .tp-simpleresponsive > ul > li:before {
	list-style: none !important;
	position: absolute;
	margin: 0 !important;
	padding: 0 !important;
	overflow-x: visible;
	overflow-y: visible;
	background-image: none;
	background-position: 0 0;
	text-indent: 0;
	top: 0;
	left: 0; }

.rev_slider > ul > li, .rev_slider > ul > li:before, .tp-revslider-mainul > li, .tp-revslider-mainul > li:before, .tp-simpleresponsive > ul > li, .tp-simpleresponsive > ul > li:before {
	visibility: hidden; }

.tp-revslider-mainul, .tp-revslider-slidesli {
	padding: 0 !important;
	margin: 0 !important;
	list-style: none !important; }

.fullscreen-container, .fullwidthbanner-container {
	padding: 0;
	position: relative; }

.rev_slider li.tp-revslider-slidesli {
	position: absolute !important; }

.tp-caption .rs-untoggled-content {
	display: block; }

.tp-caption .rs-toggled-content {
	display: none; }

.rs-toggle-content-active.tp-caption .rs-toggled-content {
	display: block; }

.rs-toggle-content-active.tp-caption .rs-untoggled-content {
	display: none; }

.rev_slider .caption, .rev_slider .tp-caption {
	position: relative;
	visibility: hidden;
	white-space: nowrap;
	display: block;
	-webkit-font-smoothing: antialiased !important;
	z-index: 1; }

.rev_slider .caption, .rev_slider .tp-caption, .tp-simpleresponsive img {
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-o-user-select: none; }

.rev_slider .tp-mask-wrap .tp-caption, .rev_slider .tp-mask-wrap :last-child, .wpb_text_column .rev_slider .tp-mask-wrap .tp-caption, .wpb_text_column .rev_slider .tp-mask-wrap :last-child {
	margin-bottom: 0; }

.tp-svg-layer svg {
	width: 100%;
	height: 100%;
	position: relative; }

.tp-carousel-wrapper {
	cursor: url(openhand.cur), move; }

.tp-carousel-wrapper.dragged {
	cursor: url(closedhand.cur), move; }

.tp_inner_padding {
	box-sizing: border-box;
	max-height: none !important; }

.tp-caption.tp-layer-selectable {
	-moz-user-select: all;
	-khtml-user-select: all;
	-webkit-user-select: all;
	-o-user-select: all; }

.tp-caption.tp-hidden-caption, .tp-forcenotvisible, .tp-hide-revslider, .tp-parallax-wrap.tp-hidden-caption {
	visibility: hidden !important;
	display: none !important; }

.rev_slider audio, .rev_slider embed, .rev_slider iframe, .rev_slider object, .rev_slider video {
	max-width: none !important; }

.tp-element-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0; }

.tp-blockmask, .tp-blockmask_in, .tp-blockmask_out {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #fff;
	z-index: 1000;
	-webkit-transform: scaleX(0) scaleY(0);
	        transform: scaleX(0) scaleY(0); }

.tp-parallax-wrap {
	-webkit-transform-style: preserve-3d;
	        transform-style: preserve-3d; }

.rev_row_zone {
	position: absolute;
	width: 100%;
	left: 0;
	box-sizing: border-box;
	min-height: 50px;
	font-size: 0; }

.rev_column_inner, .rev_slider .tp-caption.rev_row {
	position: relative;
	width: 100% !important;
	box-sizing: border-box; }

.rev_row_zone_top {
	top: 0; }

.rev_row_zone_middle {
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%); }

.rev_row_zone_bottom {
	bottom: 0; }

.rev_slider .tp-caption.rev_row {
	display: table;
	table-layout: fixed;
	vertical-align: top;
	height: auto !important;
	font-size: 0; }

.rev_column {
	display: table-cell;
	position: relative;
	height: auto;
	box-sizing: border-box;
	font-size: 0; }

.rev_column_inner {
	display: block;
	height: auto !important;
	white-space: normal !important; }

.rev_column_bg {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
	box-sizing: border-box;
	background-clip: content-box;
	border: 0 solid transparent; }

.tp-caption .backcorner, .tp-caption .backcornertop, .tp-caption .frontcorner, .tp-caption .frontcornertop {
	height: 0;
	top: 0;
	width: 0;
	position: absolute; }

.rev_column_inner .tp-loop-wrap, .rev_column_inner .tp-mask-wrap, .rev_column_inner .tp-parallax-wrap {
	text-align: inherit; }

.rev_column_inner .tp-mask-wrap {
	display: inline-block; }

.rev_column_inner .tp-parallax-wrap, .rev_column_inner .tp-parallax-wrap .tp-loop-wrap, .rev_column_inner .tp-parallax-wrap .tp-mask-wrap {
	position: relative !important;
	left: auto !important;
	top: auto !important;
	line-height: 0; }

.tp-video-play-button, .tp-video-play-button i {
	line-height: 50px !important;
	vertical-align: top;
	text-align: center; }

.rev_column_inner .rev_layer_in_column, .rev_column_inner .tp-parallax-wrap, .rev_column_inner .tp-parallax-wrap .tp-loop-wrap, .rev_column_inner .tp-parallax-wrap .tp-mask-wrap {
	vertical-align: top; }

.rev_break_columns {
	display: block !important; }

.rev_break_columns .tp-parallax-wrap.rev_column {
	display: block !important;
	width: 100% !important; }

.fullwidthbanner-container {
	overflow: hidden; }

.fullwidthbanner-container .fullwidthabanner {
	width: 100%;
	position: relative; }

.tp-static-layers {
	position: absolute;
	z-index: 101;
	top: 0;
	left: 0; }

.tp-caption .frontcorner {
	border-left: 40px solid transparent;
	border-right: 0 solid transparent;
	border-top: 40px solid #00A8FF;
	left: -40px; }

.tp-caption .backcorner {
	border-left: 0 solid transparent;
	border-right: 40px solid transparent;
	border-bottom: 40px solid #00A8FF;
	right: 0; }

.tp-caption .frontcornertop {
	border-left: 40px solid transparent;
	border-right: 0 solid transparent;
	border-bottom: 40px solid #00A8FF;
	left: -40px; }

.tp-caption .backcornertop {
	border-left: 0 solid transparent;
	border-right: 40px solid transparent;
	border-top: 40px solid #00A8FF;
	right: 0; }

.tp-layer-inner-rotation {
	position: relative !important; }

img.tp-slider-alternative-image {
	width: 100%;
	height: auto; }

.caption.fullscreenvideo, .rs-background-video-layer, .tp-caption.coverscreenvideo, .tp-caption.fullscreenvideo {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute; }

.noFilterClass {
	-webkit-filter: none !important;
	        filter: none !important; }

.rs-background-video-layer {
	visibility: hidden;
	z-index: 0; }

.caption.fullscreenvideo audio, .caption.fullscreenvideo iframe, .caption.fullscreenvideo video, .tp-caption.fullscreenvideo iframe, .tp-caption.fullscreenvideo iframe audio, .tp-caption.fullscreenvideo iframe video {
	width: 100% !important;
	height: 100% !important;
	display: none; }

.fullcoveredvideo audio, .fullscreenvideo audio .fullcoveredvideo video, .fullscreenvideo video {
	background: #000; }

.fullcoveredvideo .tp-poster {
	background-position: center center;
	background-size: cover;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0; }

.videoisplaying .html5vid .tp-poster {
	display: none; }

.tp-video-play-button {
	background: #000;
	background: rgba(0, 0, 0, 0.3);
	border-radius: 5px;
	position: absolute;
	top: 50%;
	left: 50%;
	color: #FFF;
	margin-top: -25px;
	margin-left: -25px;
	cursor: pointer;
	width: 50px;
	height: 50px;
	box-sizing: border-box;
	display: inline-block;
	z-index: 4;
	opacity: 0;
	-webkit-transition: opacity 0.3s ease-out !important;
	transition: opacity 0.3s ease-out !important; }

.tp-audio-html5 .tp-video-play-button, .tp-hiddenaudio {
	display: none !important; }

.tp-caption .html5vid {
	width: 100% !important;
	height: 100% !important; }

.tp-video-play-button i {
	width: 50px;
	height: 50px;
	display: inline-block;
	font-size: 40px !important; }

.rs-fullvideo-cover, .tp-dottedoverlay, .tp-shadowcover {
	height: 100%;
	top: 0;
	left: 0;
	position: absolute; }

.tp-caption:hover .tp-video-play-button {
	opacity: 1;
	display: block; }

.tp-caption .tp-revstop {
	display: none;
	border-left: 5px solid #fff !important;
	border-right: 5px solid #fff !important;
	margin-top: 15px !important;
	line-height: 20px !important;
	vertical-align: top;
	font-size: 25px !important; }

.tp-seek-bar, .tp-video-button, .tp-volume-bar {
	outline: 0;
	line-height: 12px;
	margin: 0;
	cursor: pointer; }

.videoisplaying .revicon-right-dir {
	display: none; }

.videoisplaying .tp-revstop {
	display: inline-block; }

.videoisplaying .tp-video-play-button {
	display: none; }

.fullcoveredvideo .tp-video-play-button {
	display: none !important; }

.fullscreenvideo .fullscreenvideo audio, .fullscreenvideo .fullscreenvideo video {
	-o-object-fit: contain !important;
	   object-fit: contain !important; }

.fullscreenvideo .fullcoveredvideo audio, .fullscreenvideo .fullcoveredvideo video {
	-o-object-fit: cover !important;
	   object-fit: cover !important; }

.tp-video-controls {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 5px;
	opacity: 0;
	-webkit-transition: opacity .3s;
	transition: opacity .3s;
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(13%, #000), to(#323232));
	background-image: linear-gradient(to bottom, #000 13%, #323232 100%);
	display: table;
	max-width: 100%;
	overflow: hidden;
	box-sizing: border-box; }

.rev-btn.rev-hiddenicon i, .rev-btn.rev-withicon i {
	-webkit-transition: all 0.2s ease-out !important;
	transition: all 0.2s ease-out !important;
	font-size: 15px; }

.tp-caption:hover .tp-video-controls {
	opacity: .9; }

.tp-video-button {
	background: rgba(0, 0, 0, 0.5);
	border: 0;
	border-radius: 3px;
	font-size: 12px;
	color: #fff;
	padding: 0; }

.tp-video-button:hover {
	cursor: pointer; }

.tp-video-button-wrap, .tp-video-seek-bar-wrap, .tp-video-vol-bar-wrap {
	padding: 0 5px;
	display: table-cell;
	vertical-align: middle; }

.tp-video-seek-bar-wrap {
	width: 80%; }

.tp-video-vol-bar-wrap {
	width: 20%; }

.tp-seek-bar, .tp-volume-bar {
	width: 100%;
	padding: 0; }

.rs-fullvideo-cover {
	width: 100%;
	background: 0 0;
	z-index: 5; }

.disabled_lc .tp-video-play-button, .rs-background-video-layer audio::-webkit-media-controls, .rs-background-video-layer video::-webkit-media-controls, .rs-background-video-layer video::-webkit-media-controls-start-playback-button {
	display: none !important; }

.tp-audio-html5 .tp-video-controls {
	opacity: 1 !important;
	visibility: visible !important; }

.tp-dottedoverlay {
	background-repeat: repeat;
	width: 100%;
	z-index: 3; }

.tp-dottedoverlay.twoxtwo {
	background: url(../assets/gridtile.png); }

.tp-dottedoverlay.twoxtwowhite {
	background: url(../assets/gridtile_white.png); }

.tp-dottedoverlay.threexthree {
	background: url(../assets/gridtile_3x3.png); }

.tp-dottedoverlay.threexthreewhite {
	background: url(../assets/gridtile_3x3_white.png); }

.tp-shadowcover {
	width: 100%;
	background: #fff;
	z-index: -1; }

.tp-shadow1 {
	box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.8); }

.tp-shadow2:after, .tp-shadow2:before, .tp-shadow3:before, .tp-shadow4:after {
	z-index: -2;
	position: absolute;
	content: "";
	bottom: 10px;
	left: 10px;
	width: 50%;
	top: 85%;
	max-width: 300px;
	background: 0 0;
	box-shadow: 0 15px 10px rgba(0, 0, 0, 0.8);
	-webkit-transform: rotate(-3deg);
	        transform: rotate(-3deg); }

.tp-shadow2:after, .tp-shadow4:after {
	-webkit-transform: rotate(3deg);
	        transform: rotate(3deg);
	right: 10px;
	left: auto; }

.tp-shadow5 {
	position: relative;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset; }

.tp-shadow5:after, .tp-shadow5:before {
	content: "";
	position: absolute;
	z-index: -2;
	box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.6);
	top: 30%;
	bottom: 0;
	left: 20px;
	right: 20px;
	border-radius: 100px/20px; }

.tp-button {
	padding: 6px 13px 5px;
	border-radius: 3px;
	height: 30px;
	cursor: pointer;
	color: #fff !important;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.6) !important;
	font-size: 15px;
	line-height: 45px !important;
	font-family: arial,sans-serif;
	font-weight: 700;
	letter-spacing: -1px;
	text-decoration: none; }

.tp-button.big {
	color: #fff;
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.6);
	font-weight: 700;
	padding: 9px 20px;
	font-size: 19px;
	line-height: 57px !important; }

.purchase:hover, .tp-button.big:hover, .tp-button:hover {
	background-position: bottom,15px 11px; }

.purchase.green, .purchase:hover.green, .tp-button.green, .tp-button:hover.green {
	background-color: #21a117;
	box-shadow: 0 3px 0 0 #104d0b; }

.purchase.blue, .purchase:hover.blue, .tp-button.blue, .tp-button:hover.blue {
	background-color: #1d78cb;
	box-shadow: 0 3px 0 0 #0f3e68; }

.purchase.red, .purchase:hover.red, .tp-button.red, .tp-button:hover.red {
	background-color: #cb1d1d;
	box-shadow: 0 3px 0 0 #7c1212; }

.purchase.orange, .purchase:hover.orange, .tp-button.orange, .tp-button:hover.orange {
	background-color: #f70;
	box-shadow: 0 3px 0 0 #a34c00; }

.purchase.darkgrey, .purchase:hover.darkgrey, .tp-button.darkgrey, .tp-button.grey, .tp-button:hover.darkgrey, .tp-button:hover.grey {
	background-color: #555;
	box-shadow: 0 3px 0 0 #222; }

.purchase.lightgrey, .purchase:hover.lightgrey, .tp-button.lightgrey, .tp-button:hover.lightgrey {
	background-color: #888;
	box-shadow: 0 3px 0 0 #555; }

.rev-btn, .rev-btn:visited {
	outline: 0 !important;
	box-shadow: none !important;
	text-decoration: none !important;
	line-height: 44px;
	font-size: 17px;
	font-weight: 500;
	padding: 12px 35px;
	box-sizing: border-box;
	font-family: Roboto,sans-serif;
	cursor: pointer; }

.rev-btn.rev-uppercase, .rev-btn.rev-uppercase:visited {
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 15px;
	font-weight: 900; }

.rev-btn.rev-withicon i {
	font-weight: 400;
	position: relative;
	top: 0;
	margin-left: 10px !important; }

.rev-btn.rev-hiddenicon i {
	font-weight: 400;
	position: relative;
	top: 0;
	opacity: 0;
	margin-left: 0 !important;
	width: 0 !important; }

.rev-btn.rev-hiddenicon:hover i {
	opacity: 1 !important;
	margin-left: 10px !important;
	width: auto !important; }

.rev-btn.rev-medium, .rev-btn.rev-medium:visited {
	line-height: 36px;
	font-size: 14px;
	padding: 10px 30px; }

.rev-btn.rev-medium.rev-hiddenicon i, .rev-btn.rev-medium.rev-withicon i {
	font-size: 14px;
	top: 0; }

.rev-btn.rev-small, .rev-btn.rev-small:visited {
	line-height: 28px;
	font-size: 12px;
	padding: 7px 20px; }

.rev-btn.rev-small.rev-hiddenicon i, .rev-btn.rev-small.rev-withicon i {
	font-size: 12px;
	top: 0; }

.rev-maxround {
	border-radius: 30px; }

.rev-minround {
	border-radius: 3px; }

.rev-burger {
	position: relative;
	width: 60px;
	height: 60px;
	box-sizing: border-box;
	padding: 22px 0 0 14px;
	border-radius: 50%;
	border: 1px solid rgba(51, 51, 51, 0.25);
	-webkit-tap-highlight-color: transparent;
	cursor: pointer; }

.rev-burger span {
	display: block;
	width: 30px;
	height: 3px;
	background: #333;
	-webkit-transition: .7s;
	transition: .7s;
	pointer-events: none;
	-webkit-transform-style: flat !important;
	        transform-style: flat !important; }

.rev-burger.revb-white span, .rev-burger.revb-whitenoborder span {
	background: #fff; }

.rev-burger span:nth-child(2) {
	margin: 3px 0; }

#dialog_addbutton .rev-burger:hover :first-child, .open .rev-burger :first-child, .open.rev-burger :first-child {
	-webkit-transform: translateY(6px) rotate(-45deg);
	        transform: translateY(6px) rotate(-45deg); }

#dialog_addbutton .rev-burger:hover :nth-child(2), .open .rev-burger :nth-child(2), .open.rev-burger :nth-child(2) {
	-webkit-transform: rotate(-45deg);
	        transform: rotate(-45deg);
	opacity: 0; }

#dialog_addbutton .rev-burger:hover :last-child, .open .rev-burger :last-child, .open.rev-burger :last-child {
	-webkit-transform: translateY(-6px) rotate(-135deg);
	        transform: translateY(-6px) rotate(-135deg); }

.rev-burger.revb-white {
	border: 2px solid rgba(255, 255, 255, 0.2); }

.rev-burger.revb-darknoborder, .rev-burger.revb-whitenoborder {
	border: 0; }

.rev-burger.revb-darknoborder span {
	background: #333; }

.rev-burger.revb-whitefull {
	background: #fff;
	border: none; }

.rev-burger.revb-whitefull span {
	background: #333; }

.rev-burger.revb-darkfull {
	background: #333;
	border: none; }

.rev-burger.revb-darkfull span, .rev-scroll-btn.revs-fullwhite {
	background: #fff; }

@-webkit-keyframes rev-ani-mouse {
	0% {
		opacity: 1;
		top: 29%; }
	15% {
		opacity: 1;
		top: 50%; }
	50% {
		opacity: 0;
		top: 50%; }
	100% {
		opacity: 0;
		top: 29%; } }

@keyframes rev-ani-mouse {
	0% {
		opacity: 1;
		top: 29%; }
	15% {
		opacity: 1;
		top: 50%; }
	50% {
		opacity: 0;
		top: 50%; }
	100% {
		opacity: 0;
		top: 29%; } }

.rev-scroll-btn {
	display: inline-block;
	position: relative;
	left: 0;
	right: 0;
	text-align: center;
	cursor: pointer;
	width: 35px;
	height: 55px;
	box-sizing: border-box;
	border: 3px solid #fff;
	border-radius: 23px; }

.rev-scroll-btn > * {
	display: inline-block;
	line-height: 18px;
	font-size: 13px;
	font-weight: 400;
	color: #fff;
	font-family: proxima-nova,"Helvetica Neue",Helvetica,Arial,sans-serif;
	letter-spacing: 2px; }

.rev-scroll-btn > .active, .rev-scroll-btn > :focus, .rev-scroll-btn > :hover {
	color: #fff; }

.rev-scroll-btn > .active, .rev-scroll-btn > :active, .rev-scroll-btn > :focus, .rev-scroll-btn > :hover {
	opacity: .8; }

.rev-scroll-btn.revs-fullwhite span {
	background: #333; }

.rev-scroll-btn.revs-fulldark {
	background: #333;
	border: none; }

.rev-scroll-btn.revs-fulldark span, .tp-bullet {
	background: #fff; }

.rev-scroll-btn span {
	position: absolute;
	display: block;
	top: 29%;
	left: 50%;
	width: 8px;
	height: 8px;
	margin: -4px 0 0 -4px;
	background: #fff;
	border-radius: 50%;
	-webkit-animation: rev-ani-mouse 2.5s linear infinite;
	        animation: rev-ani-mouse 2.5s linear infinite; }

.rev-scroll-btn.revs-dark {
	border-color: #333; }

.rev-scroll-btn.revs-dark span {
	background: #333; }

.rev-control-btn {
	position: relative;
	display: inline-block;
	z-index: 5;
	color: #FFF;
	font-size: 20px;
	line-height: 60px;
	font-weight: 400;
	font-style: normal;
	font-family: Raleway;
	text-decoration: none;
	text-align: center;
	background-color: #000;
	border-radius: 50px;
	text-shadow: none;
	background-color: rgba(0, 0, 0, 0.5);
	width: 60px;
	height: 60px;
	box-sizing: border-box;
	cursor: pointer; }

.rev-cbutton-dark-sr, .rev-cbutton-light-sr {
	border-radius: 3px; }

.rev-cbutton-light, .rev-cbutton-light-sr {
	color: #333;
	background-color: rgba(255, 255, 255, 0.75); }

.rev-sbutton {
	line-height: 37px;
	width: 37px;
	height: 37px; }

.rev-sbutton-blue {
	background-color: #3B5998; }

.rev-sbutton-lightblue {
	background-color: #00A0D1; }

.rev-sbutton-red {
	background-color: #DD4B39; }

.tp-bannertimer {
	visibility: hidden;
	width: 100%;
	height: 5px;
	background: #000;
	background: rgba(0, 0, 0, 0.15);
	position: absolute;
	z-index: 200;
	top: 0; }

.tp-bannertimer.tp-bottom {
	top: auto;
	bottom: 0 !important;
	height: 5px; }

.tp-caption img {
	background: 0 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF)";
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF, endColorstr=#00FFFFFF);
	zoom: 1; }

.caption.slidelink a div, .tp-caption.slidelink a div {
	width: 3000px;
	height: 1500px;
	background: url(../assets/coloredbg.png); }

.tp-caption.slidelink a span {
	background: url(../assets/coloredbg.png); }

.tp-loader.spinner0, .tp-loader.spinner5 {
	background-image: url(../assets/loader.gif);
	background-repeat: no-repeat; }

.tp-shape {
	width: 100%;
	height: 100%; }

.tp-caption .rs-starring {
	display: inline-block; }

.tp-caption .rs-starring .star-rating {
	float: none;
	display: inline-block;
	vertical-align: top;
	color: #FFC321 !important; }

.tp-caption .rs-starring .star-rating, .tp-caption .rs-starring-page .star-rating {
	position: relative;
	height: 1em;
	width: 5.4em;
	font-family: star;
	font-size: 1em !important; }

.tp-loader.spinner0, .tp-loader.spinner1 {
	width: 40px;
	height: 40px;
	margin-top: -20px;
	margin-left: -20px;
	-webkit-animation: tp-rotateplane 1.2s infinite ease-in-out;
	        animation: tp-rotateplane 1.2s infinite ease-in-out;
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15); }

.tp-caption .rs-starring .star-rating:before, .tp-caption .rs-starring-page .star-rating:before {
	content: "\73\73\73\73\73";
	color: #E0DADF;
	float: left;
	top: 0;
	left: 0;
	position: absolute; }

.tp-caption .rs-starring .star-rating span {
	overflow: hidden;
	float: left;
	top: 0;
	left: 0;
	position: absolute;
	padding-top: 1.5em;
	font-size: 1em !important; }

.tp-caption .rs-starring .star-rating span:before {
	content: "\53\53\53\53\53";
	top: 0;
	position: absolute;
	left: 0; }

.tp-loader {
	top: 50%;
	left: 50%;
	z-index: 10000;
	position: absolute; }

.tp-loader.spinner0 {
	background-position: center center; }

.tp-loader.spinner5 {
	background-position: 10px 10px;
	background-color: #fff;
	margin: -22px;
	width: 44px;
	height: 44px;
	border-radius: 3px; }

@-webkit-keyframes tp-rotateplane {
	0% {
		-webkit-transform: perspective(120px) rotateX(0) rotateY(0);
		        transform: perspective(120px) rotateX(0) rotateY(0); }
	50% {
		-webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
		        transform: perspective(120px) rotateX(-180.1deg) rotateY(0); }
	100% {
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
		        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); } }

@keyframes tp-rotateplane {
	0% {
		-webkit-transform: perspective(120px) rotateX(0) rotateY(0);
		        transform: perspective(120px) rotateX(0) rotateY(0); }
	50% {
		-webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
		        transform: perspective(120px) rotateX(-180.1deg) rotateY(0); }
	100% {
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
		        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); } }

.tp-loader.spinner2 {
	width: 40px;
	height: 40px;
	margin-top: -20px;
	margin-left: -20px;
	background-color: red;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
	border-radius: 100%;
	-webkit-animation: tp-scaleout 1s infinite ease-in-out;
	        animation: tp-scaleout 1s infinite ease-in-out; }

@-webkit-keyframes tp-scaleout {
	0% {
		-webkit-transform: scale(0);
		        transform: scale(0); }
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		opacity: 0; } }

@keyframes tp-scaleout {
	0% {
		-webkit-transform: scale(0);
		        transform: scale(0); }
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		opacity: 0; } }

.tp-loader.spinner3 {
	margin: -9px 0 0 -35px;
	width: 70px;
	text-align: center; }

.tp-loader.spinner3 .bounce1, .tp-loader.spinner3 .bounce2, .tp-loader.spinner3 .bounce3 {
	width: 18px;
	height: 18px;
	background-color: #fff;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: tp-bouncedelay 1.4s infinite ease-in-out;
	        animation: tp-bouncedelay 1.4s infinite ease-in-out;
	-webkit-animation-fill-mode: both;
	        animation-fill-mode: both; }

.tp-loader.spinner3 .bounce1 {
	-webkit-animation-delay: -.32s;
	        animation-delay: -.32s; }

.tp-loader.spinner3 .bounce2 {
	-webkit-animation-delay: -.16s;
	        animation-delay: -.16s; }

@-webkit-keyframes tp-bouncedelay {
	0%, 100%, 80% {
		-webkit-transform: scale(0);
		        transform: scale(0); }
	40% {
		-webkit-transform: scale(1);
		        transform: scale(1); } }

@keyframes tp-bouncedelay {
	0%, 100%, 80% {
		-webkit-transform: scale(0);
		        transform: scale(0); }
	40% {
		-webkit-transform: scale(1);
		        transform: scale(1); } }

.tp-loader.spinner4 {
	margin: -20px 0 0 -20px;
	width: 40px;
	height: 40px;
	text-align: center;
	-webkit-animation: tp-rotate 2s infinite linear;
	        animation: tp-rotate 2s infinite linear; }

.tp-loader.spinner4 .dot1, .tp-loader.spinner4 .dot2 {
	width: 60%;
	height: 60%;
	display: inline-block;
	position: absolute;
	top: 0;
	background-color: #fff;
	border-radius: 100%;
	-webkit-animation: tp-bounce 2s infinite ease-in-out;
	        animation: tp-bounce 2s infinite ease-in-out;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15); }

.tp-loader.spinner4 .dot2 {
	top: auto;
	bottom: 0;
	-webkit-animation-delay: -1s;
	        animation-delay: -1s; }

@-webkit-keyframes tp-rotate {
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg); } }

@keyframes tp-rotate {
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg); } }

@-webkit-keyframes tp-bounce {
	0%, 100% {
		-webkit-transform: scale(0);
		        transform: scale(0); }
	50% {
		-webkit-transform: scale(1);
		        transform: scale(1); } }

@keyframes tp-bounce {
	0%, 100% {
		-webkit-transform: scale(0);
		        transform: scale(0); }
	50% {
		-webkit-transform: scale(1);
		        transform: scale(1); } }

.tp-bullets.navbar, .tp-tabs.navbar, .tp-thumbs.navbar {
	border: none;
	min-height: 0;
	margin: 0;
	border-radius: 0; }

.tp-bullets, .tp-tabs, .tp-thumbs {
	position: absolute;
	display: block;
	z-index: 1000;
	top: 0;
	left: 0; }

.tp-tab, .tp-thumb {
	cursor: pointer;
	position: absolute;
	opacity: .5;
	box-sizing: border-box; }

.tp-arr-imgholder, .tp-tab-image, .tp-thumb-image, .tp-videoposter {
	background-position: center center;
	background-size: cover;
	width: 100%;
	height: 100%;
	display: block;
	position: absolute;
	top: 0;
	left: 0; }

.tp-tab.selected, .tp-tab:hover, .tp-thumb.selected, .tp-thumb:hover {
	opacity: 1; }

.tp-tab-mask, .tp-thumb-mask {
	box-sizing: border-box !important; }

.tp-tabs, .tp-thumbs {
	box-sizing: content-box !important; }

.tp-bullet {
	width: 15px;
	height: 15px;
	position: absolute;
	background: rgba(255, 255, 255, 0.3);
	cursor: pointer; }

.tp-bullet.selected, .tp-bullet:hover {
	background: #fff; }

.tparrows {
	cursor: pointer;
	background: #000;
	background: rgba(0, 0, 0, 0.5);
	width: 40px;
	height: 40px;
	position: absolute;
	display: block;
	z-index: 1000; }

.tparrows:hover {
	background: #000; }

.tparrows:before {
	font-family: revicons;
	font-size: 15px;
	color: #fff;
	display: block;
	line-height: 40px;
	text-align: center; }

.hginfo, .hglayerinfo {
	font-size: 12px;
	font-weight: 600; }

.tparrows.tp-leftarrow:before {
	content: '\e824'; }

.tparrows.tp-rightarrow:before {
	content: '\e825'; }

body.rtl .tp-kbimg {
	left: 0 !important; }

.dddwrappershadow {
	box-shadow: 0 45px 100px rgba(0, 0, 0, 0.4); }

.hglayerinfo {
	position: fixed;
	bottom: 0;
	left: 0;
	color: #FFF;
	line-height: 20px;
	background: rgba(0, 0, 0, 0.75);
	padding: 5px 10px;
	z-index: 2000;
	white-space: normal; }

.helpgrid, .hginfo {
	position: absolute; }

.hginfo {
	top: -2px;
	left: -2px;
	color: #e74c3c;
	background: #000;
	padding: 2px 5px; }

.indebugmode .tp-caption:hover {
	border: 1px dashed #c0392b !important; }

.helpgrid {
	border: 2px dashed #c0392b;
	top: 0;
	left: 0;
	z-index: 0; }

#revsliderlogloglog {
	padding: 15px;
	color: #fff;
	position: fixed;
	top: 0;
	left: 0;
	width: 200px;
	height: 150px;
	background: rgba(0, 0, 0, 0.7);
	z-index: 100000;
	font-size: 10px;
	overflow: scroll; }

.aden {
	-webkit-filter: hue-rotate(-20deg) contrast(0.9) saturate(0.85) brightness(1.2);
	        filter: hue-rotate(-20deg) contrast(0.9) saturate(0.85) brightness(1.2); }

.aden::after {
	background: -webkit-gradient(linear, left top, right top, from(rgba(66, 10, 14, 0.2)), to(transparent));
	background: linear-gradient(to right, rgba(66, 10, 14, 0.2), transparent);
	mix-blend-mode: darken; }

.perpetua::after, .reyes::after {
	mix-blend-mode: soft-light;
	opacity: .5; }

.inkwell {
	-webkit-filter: sepia(0.3) contrast(1.1) brightness(1.1) grayscale(1);
	        filter: sepia(0.3) contrast(1.1) brightness(1.1) grayscale(1); }

.perpetua::after {
	background: -webkit-gradient(linear, left top, left bottom, from(#005b9a), to(#e6c13d));
	background: linear-gradient(to bottom, #005b9a, #e6c13d); }

.reyes {
	-webkit-filter: sepia(0.22) brightness(1.1) contrast(0.85) saturate(0.75);
	        filter: sepia(0.22) brightness(1.1) contrast(0.85) saturate(0.75); }

.reyes::after {
	background: #efcdad; }

.gingham {
	-webkit-filter: brightness(1.05) hue-rotate(-10deg);
	        filter: brightness(1.05) hue-rotate(-10deg); }

.gingham::after {
	background: -webkit-gradient(linear, left top, right top, from(rgba(66, 10, 14, 0.2)), to(transparent));
	background: linear-gradient(to right, rgba(66, 10, 14, 0.2), transparent);
	mix-blend-mode: darken; }

.toaster {
	-webkit-filter: contrast(1.5) brightness(0.9);
	        filter: contrast(1.5) brightness(0.9); }

.toaster::after {
	background: radial-gradient(circle, #804e0f, #3b003b);
	mix-blend-mode: screen; }

.walden {
	-webkit-filter: brightness(1.1) hue-rotate(-10deg) sepia(0.3) saturate(1.6);
	        filter: brightness(1.1) hue-rotate(-10deg) sepia(0.3) saturate(1.6); }

.walden::after {
	background: #04c;
	mix-blend-mode: screen;
	opacity: .3; }

.hudson {
	-webkit-filter: brightness(1.2) contrast(0.9) saturate(1.1);
	        filter: brightness(1.2) contrast(0.9) saturate(1.1); }

.hudson::after {
	background: radial-gradient(circle, #a6b1ff 50%, #342134);
	mix-blend-mode: multiply;
	opacity: .5; }

.earlybird {
	-webkit-filter: contrast(0.9) sepia(0.2);
	        filter: contrast(0.9) sepia(0.2); }

.earlybird::after {
	background: radial-gradient(circle, #d0ba8e 20%, #360309 85%, #1d0210 100%);
	mix-blend-mode: overlay; }

.mayfair {
	-webkit-filter: contrast(1.1) saturate(1.1);
	        filter: contrast(1.1) saturate(1.1); }

.mayfair::after {
	background: radial-gradient(circle at 40% 40%, rgba(255, 255, 255, 0.8), rgba(255, 200, 200, 0.6), #111 60%);
	mix-blend-mode: overlay;
	opacity: .4; }

.lofi {
	-webkit-filter: saturate(1.1) contrast(1.5);
	        filter: saturate(1.1) contrast(1.5); }

.lofi::after {
	background: radial-gradient(circle, transparent 70%, #222 150%);
	mix-blend-mode: multiply; }

._1977 {
	-webkit-filter: contrast(1.1) brightness(1.1) saturate(1.3);
	        filter: contrast(1.1) brightness(1.1) saturate(1.3); }

._1977:after {
	background: rgba(243, 106, 188, 0.3);
	mix-blend-mode: screen; }

.brooklyn {
	-webkit-filter: contrast(0.9) brightness(1.1);
	        filter: contrast(0.9) brightness(1.1); }

.brooklyn::after {
	background: radial-gradient(circle, rgba(168, 223, 193, 0.4) 70%, #c4b7c8);
	mix-blend-mode: overlay; }

.xpro2 {
	-webkit-filter: sepia(0.3);
	        filter: sepia(0.3); }

.xpro2::after {
	background: radial-gradient(circle, #e6e7e0 40%, rgba(43, 42, 161, 0.6) 110%);
	mix-blend-mode: color-burn; }

.nashville {
	-webkit-filter: sepia(0.2) contrast(1.2) brightness(1.05) saturate(1.2);
	        filter: sepia(0.2) contrast(1.2) brightness(1.05) saturate(1.2); }

.nashville::after {
	background: rgba(0, 70, 150, 0.4);
	mix-blend-mode: lighten; }

.nashville::before {
	background: rgba(247, 176, 153, 0.56);
	mix-blend-mode: darken; }

.lark {
	-webkit-filter: contrast(0.9);
	        filter: contrast(0.9); }

.lark::after {
	background: rgba(242, 242, 242, 0.8);
	mix-blend-mode: darken; }

.lark::before {
	background: #22253f;
	mix-blend-mode: color-dodge; }

.moon {
	-webkit-filter: grayscale(1) contrast(1.1) brightness(1.1);
	        filter: grayscale(1) contrast(1.1) brightness(1.1); }

.moon::before {
	background: #a0a0a0;
	mix-blend-mode: soft-light; }

.moon::after {
	background: #383838;
	mix-blend-mode: lighten; }

.clarendon {
	-webkit-filter: contrast(1.2) saturate(1.35);
	        filter: contrast(1.2) saturate(1.35); }

.clarendon:before {
	background: rgba(127, 187, 227, 0.2);
	mix-blend-mode: overlay; }

.willow {
	-webkit-filter: grayscale(0.5) contrast(0.95) brightness(0.9);
	        filter: grayscale(0.5) contrast(0.95) brightness(0.9); }

.willow::before {
	background-color: radial-gradient(40%, circle, #d4a9af 55%, #000 150%);
	mix-blend-mode: overlay; }

.willow::after {
	background-color: #d8cdcb;
	mix-blend-mode: color; }

.rise {
	-webkit-filter: brightness(1.05) sepia(0.2) contrast(0.9) saturate(0.9);
	        filter: brightness(1.05) sepia(0.2) contrast(0.9) saturate(0.9); }

.rise::after {
	background: radial-gradient(circle, rgba(232, 197, 152, 0.8), transparent 90%);
	mix-blend-mode: overlay;
	opacity: .6; }

.rise::before {
	background: radial-gradient(circle, rgba(236, 205, 169, 0.15) 55%, rgba(50, 30, 7, 0.4));
	mix-blend-mode: multiply; }

._1977:after, ._1977:before, .aden:after, .aden:before, .brooklyn:after, .brooklyn:before, .clarendon:after, .clarendon:before, .earlybird:after, .earlybird:before, .gingham:after, .gingham:before, .hudson:after, .hudson:before, .inkwell:after, .inkwell:before, .lark:after, .lark:before, .lofi:after, .lofi:before, .mayfair:after, .mayfair:before, .moon:after, .moon:before, .nashville:after, .nashville:before, .perpetua:after, .perpetua:before, .reyes:after, .reyes:before, .rise:after, .rise:before, .slumber:after, .slumber:before, .toaster:after, .toaster:before, .walden:after, .walden:before, .willow:after, .willow:before, .xpro2:after, .xpro2:before {
	content: '';
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
	pointer-events: none; }

._1977, .aden, .brooklyn, .clarendon, .earlybird, .gingham, .hudson, .inkwell, .lark, .lofi, .mayfair, .moon, .nashville, .perpetua, .reyes, .rise, .slumber, .toaster, .walden, .willow, .xpro2 {
	position: relative; }

._1977 img, .aden img, .brooklyn img, .clarendon img, .earlybird img, .gingham img, .hudson img, .inkwell img, .lark img, .lofi img, .mayfair img, .moon img, .nashville img, .perpetua img, .reyes img, .rise img, .slumber img, .toaster img, .walden img, .willow img, .xpro2 img {
	width: 100%;
	z-index: 1; }

._1977:before, .aden:before, .brooklyn:before, .clarendon:before, .earlybird:before, .gingham:before, .hudson:before, .inkwell:before, .lark:before, .lofi:before, .mayfair:before, .moon:before, .nashville:before, .perpetua:before, .reyes:before, .rise:before, .slumber:before, .toaster:before, .walden:before, .willow:before, .xpro2:before {
	z-index: 2; }

._1977:after, .aden:after, .brooklyn:after, .clarendon:after, .earlybird:after, .gingham:after, .hudson:after, .inkwell:after, .lark:after, .lofi:after, .mayfair:after, .moon:after, .nashville:after, .perpetua:after, .reyes:after, .rise:after, .slumber:after, .toaster:after, .walden:after, .willow:after, .xpro2:after {
	z-index: 3; }

.slumber {
	-webkit-filter: saturate(0.66) brightness(1.05);
	        filter: saturate(0.66) brightness(1.05); }

.slumber::after {
	background: rgba(125, 105, 24, 0.5);
	mix-blend-mode: soft-light; }

.slumber::before {
	background: rgba(69, 41, 12, 0.4);
	mix-blend-mode: lighten; }

.tp-kbimg-wrap:after, .tp-kbimg-wrap:before {
	height: 500%;
	width: 500%; }

iframe {
	border: 0; }

.rs-background-video-layer[data-ytid] iframe {
	visibility: visible !important; }

/*---------------------------------------------------------------
 Arrows
---------------------------------------------------------------*/
.tp-caption.hero-navigation-arrow {
	display: inline-block;
	font-size: 15px;
	min-width: 30px;
	max-width: 30px;
	line-height: 30px;
	color: rgba(255, 255, 255, 0.5);
	cursor: pointer; }

/* Bullets
-------------------------------------------------------*/
.tp-bullet {
	background: rgba(255, 255, 255, 0.7);
	border-radius: 0;
	box-shadow: none;
	width: 36px;
	height: 2px;
	display: inline-block;
	-webkit-transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
	transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1); }

.tp-bullet.selected,
.tp-bullet:hover {
	width: 46px;
	margin-left: -10px;
	box-shadow: none;
	background-color: #FE5900; }

/* Hero Text Layers
-------------------------------------------------------*/
.tp-caption.hero-title {
	color: #fff;
	text-shadow: none;
	font-weight: 700;
	line-height: 60px;
	font-family: "DM Sans", sans-serif;
	letter-spacing: -0.1em;
	margin: 0px;
	border-width: 0px;
	border-style: none;
	white-space: nowrap;
	padding-top: 1px;
	margin-left: -5px; }
	.tp-caption.hero-title h1, .tp-caption.hero-title h2, .tp-caption.hero-title h3, .tp-caption.hero-title h4, .tp-caption.hero-title h5, .tp-caption.hero-title h6 {
		color: inherit; }

@media only screen and (max-width: 767px) {
	.hero-title-h1 {
		font-size: 2.125rem; } }

.tp-caption.hero-subtitle {
	font-family: "DM Sans", sans-serif;
	letter-spacing: -0.05em;
	color: #fff; }

.hero-text-uppercase {
	text-transform: uppercase; }

.hero-text-stroke {
	-webkit-text-stroke: 2px #fff;
	color: transparent !important; }

.tp-caption.hero-numbers {
	z-index: 13;
	white-space: nowrap;
	font-size: 12px;
	line-height: 30px;
	font-weight: 400;
	color: #ffffff;
	letter-spacing: 0px;
	display: inline-block; }
	.tp-caption.hero-numbers span {
		font-weight: 700;
		font-size: 20px; }

/* Hero Video Play Button
-------------------------------------------------------*/
.hero-video-play a {
	color: #fff;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center; }

.hero-video-play a:hover,
.hero-video-play a:focus {
	color: #FE5900; }

.hero-video-play i {
	margin-right: 10px; }

.hero-video-play__text {
	display: inline-block;
	margin-top: 1px;
	font-weight: 700;
	font-size: 1.125rem;
	letter-spacing: -0.05em; }

/* Hero Scroll Down
-------------------------------------------------------*/
.scroll-down {
	color: #fff;
	font-size: 2rem;
	width: 32px;
	height: 32px;
	text-align: center;
	line-height: 32px;
	z-index: 50 !important;
	position: absolute;
	bottom: 150px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	-webkit-transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
	transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1); }
	.scroll-down:hover, .scroll-down:focus {
		color: #fff; }
		@media only screen and (min-width: 992px) {
			.scroll-down:hover, .scroll-down:focus {
				bottom: 146px; } }
		@media only screen and (max-width: 991px) {
			.scroll-down:hover, .scroll-down:focus {
				bottom: 200px; } }
	.scroll-down--dark {
		color: #131313; }
		.scroll-down--dark:hover, .scroll-down--dark:focus {
			color: #131313; }

/* Hero Info
-------------------------------------------------------*/
.hero-info-phone:hover, .hero-info-phone:focus, .hero-info-email:hover, .hero-info-email:focus {
	color: #fff; }

.tp-caption.hero-info {
	-webkit-transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
	transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1); }

.tp-caption.hero-info--hide {
	-webkit-transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1) !important;
	transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1) !important; }

.tp-caption.hero-info--hide {
	opacity: 0 !important;
	visibility: hidden !important; }

/* Hero Go Back
-------------------------------------------------------*/
.hero-go-back i {
	font-size: .75rem;
	line-height: 1; }

/* Buttons
-------------------------------------------------------*/
.tp-caption.hero-button {
	display: inline-block;
	line-height: 30px; }

.tp-caption .btn {
	-webkit-transition: all 0.2s ease-in-out !important;
	transition: all 0.2s ease-in-out !important; }

/* Menu
-------------------------------------------------------*/
.tp-caption.hero-menu-toggle {
	z-index: 33;
	cursor: pointer;
	color: #fff; }

.tp-caption.hero-menu-link {
	min-width: 800px;
	max-width: 800px;
	z-index: 39;
	white-space: nowrap;
	cursor: pointer;
	color: #ffffff; }
	.tp-caption.hero-menu-link:hover {
		color: #FE5900 !important; }

/*-------------------------------------------------------*/
/* Service Boxes
/*-------------------------------------------------------*/
.service {
	margin-bottom: 70px; }
	.service__icon {
		font-size: 4rem;
		line-height: 1;
		color: #FE5900;
		display: inline-block;
		margin-bottom: 32px; }
	.service__title {
		font-size: 1.5rem;
		margin-bottom: 18px; }
	.service__text {
		margin-bottom: 0;
		color: #686868; }
	.service a {
		color: #686868; }
		.service a:hover {
			color: #FE5900; }

/*-------------------------------------------------------*/
/* About
/*-------------------------------------------------------*/
.about__holder {
	padding: 60px 40px;
	background-color: #fff;
	position: relative; }

.about__img-holder {
	position: relative;
	margin-bottom: 0; }

.about__info {
	margin-top: 40px; }

@media only screen and (min-width: 576px) {
	.about__holder {
		display: -webkit-box;
		display: flex;
		flex-wrap: nowrap; }
	.about__info {
		margin-left: 40px;
		margin-top: 0; } }

@media only screen and (min-width: 992px) {
	.about__holder {
		padding: 80px 40px; }
	.about__info {
		max-width: 600px; } }

@media (min-width: 1280px) {
	.about__holder {
		-webkit-box-align: center;
		        align-items: center;
		max-width: 65%;
		margin-left: auto;
		padding: 160px 20px 160px 280px; }
	.about__img-holder {
		position: absolute;
		left: -400px; } }

@media (min-width: 1600px) {
	.about__holder {
		padding: 160px 20px 160px 360px; }
	.about__img-holder {
		left: -400px; } }

/*-------------------------------------------------------*/
/* Projects
/*-------------------------------------------------------*/
.project {
	position: relative;
	overflow: hidden; }
	.project-slide {
		margin: 0 19px; }
	.project__img-holder {
		position: relative;
		width: 100%;
		height: 100%;
		margin: 0;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden; }
	.project__img {
		width: 100%;
		-webkit-transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
		transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
		overflow: hidden;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden; }
	.project__title, .project__category {
		opacity: 0;
		-webkit-transition: opacity 0.2s cubic-bezier(0.07, 0.98, 0, 0.96);
		transition: opacity 0.2s cubic-bezier(0.07, 0.98, 0, 0.96); }
	.project__description-wrap {
		position: absolute;
		opacity: 0;
		bottom: 40px;
		left: 40px;
		display: inline-block;
		width: 78%;
		max-width: 260px;
		-webkit-clip-path: inset(0 0 0 100%);
		clip-path: inset(0 0 0 100%);
		-webkit-transition: opacity 0.2s 0.3s, clip-path 0s 0.3s;
		-webkit-transition: opacity 0.2s 0.3s, -webkit-clip-path 0s 0.3s;
		transition: opacity 0.2s 0.3s, -webkit-clip-path 0s 0.3s;
		transition: opacity 0.2s 0.3s, clip-path 0s 0.3s;
		transition: opacity 0.2s 0.3s, clip-path 0s 0.3s, -webkit-clip-path 0s 0.3s; }
	.project__description {
		position: relative;
		padding: 16px 20px; }
		.project__description:after {
			content: '';
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: #fff;
			-webkit-transform-origin: 0 50%;
			transform-origin: 0 50%;
			-webkit-transform: scaleX(0);
			transform: scaleX(0);
			-webkit-transition: transform 0.35s cubic-bezier(0.07, 0.98, 0, 0.96);
			-webkit-transition: -webkit-transform 0.35s cubic-bezier(0.07, 0.98, 0, 0.96);
			transition: -webkit-transform 0.35s cubic-bezier(0.07, 0.98, 0, 0.96);
			transition: transform 0.35s cubic-bezier(0.07, 0.98, 0, 0.96);
			transition: transform 0.35s cubic-bezier(0.07, 0.98, 0, 0.96), -webkit-transform 0.35s cubic-bezier(0.07, 0.98, 0, 0.96); }
	.project:hover .project__description-wrap {
		opacity: 1;
		-webkit-clip-path: inset(0 0 0 0);
		clip-path: inset(0 0 0 0);
		-webkit-transition: -webkit-clip-path 0.8s cubic-bezier(0.07, 0.98, 0, 0.96);
		transition: -webkit-clip-path 0.8s cubic-bezier(0.07, 0.98, 0, 0.96);
		transition: clip-path 0.8s cubic-bezier(0.07, 0.98, 0, 0.96);
		transition: clip-path 0.8s cubic-bezier(0.07, 0.98, 0, 0.96), -webkit-clip-path 0.8s cubic-bezier(0.07, 0.98, 0, 0.96); }
	.project:hover .project__description:after {
		-webkit-transform-origin: 100% 50%;
		transform-origin: 100% 50%;
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
		-webkit-transition: transform 0.7s cubic-bezier(0.07, 0.98, 0, 0.96);
		-webkit-transition: -webkit-transform 0.7s cubic-bezier(0.07, 0.98, 0, 0.96);
		transition: -webkit-transform 0.7s cubic-bezier(0.07, 0.98, 0, 0.96);
		transition: transform 0.7s cubic-bezier(0.07, 0.98, 0, 0.96);
		transition: transform 0.7s cubic-bezier(0.07, 0.98, 0, 0.96), -webkit-transform 0.7s cubic-bezier(0.07, 0.98, 0, 0.96); }
	.project:hover .project__title,
	.project:hover .project__category {
		opacity: 1; }
	.project__url {
		display: block;
		position: relative;
		width: 100%;
		height: 100%; }
	.project__title {
		font-size: 1.25rem;
		position: relative;
		z-index: 2; }
	.project__category {
		position: relative;
		z-index: 2;
		display: inline-block;
		margin-top: 16px;
		font-size: 0.875rem;
		font-weight: 700; }

/* Filter
-------------------------------------------------------*/
.project-filter {
	list-style: none;
	margin-bottom: 96px;
	cursor: default;
	font-size: 2rem;
	font-weight: 700; }
	.project-filter a {
		display: inline-block;
		position: relative;
		margin-right: 32px;
		color: #131313;
		text-decoration: none;
		letter-spacing: -0.05em; }
		.project-filter a:last-child {
			margin: 0; }
		.project-filter a:after {
			content: '';
			display: block;
			position: absolute;
			height: 2px;
			bottom: 2px;
			left: 0;
			width: 0;
			background-color: #FE5900;
			-webkit-transition: 0.3s ease-in-out;
			transition: 0.3s ease-in-out; }
		.project-filter a:hover {
			color: #FE5900; }
	.project-filter a.active:after,
	.project-filter a:hover:after,
	.project-filter a:focus:after {
		width: 100%; }

/* Grid
-------------------------------------------------------*/
.project-grid--5-col .project {
	width: 20%; }

.project-grid--4-col .project {
	width: 25%; }

.project-grid--3-col .project {
	width: 33.33333333%; }

.project-grid--2-col .project {
	width: 50%; }

/*-------------------------------------------------------*/
/* Results
/*-------------------------------------------------------*/
.section-results {
	padding: 0; }

.results {
	padding: 40px;
	position: relative;
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	        flex-direction: column;
	-webkit-box-pack: end;
	        justify-content: flex-end; }
	@media only screen and (min-width: 992px) {
		.results {
			min-height: 534px; } }
	@media only screen and (min-width: 1200px) {
		.results {
			padding: 46px 72px; } }
	.results-col:not(:last-child) {
		border-right: 1px solid rgba(255, 255, 255, 0.18); }
	.results__counter, .results__text {
		color: #fff; }
	.results__counter {
		font-size: 3rem;
		font-weight: 700;
		margin-bottom: 20px; }
	.results__number, .results__suffix {
		float: left; }
	.results__text {
		margin-bottom: 0; }

/*-------------------------------------------------------*/
/* Testimonials
/*-------------------------------------------------------*/
.section-testimonials--large-padding {
	padding: 140px 0 240px; }
	@media only screen and (min-width: 992px) {
		.section-testimonials--large-padding {
			padding: 320px 0 400px; } }

.testimonial {
	position: relative;
	display: -webkit-box;
	display: flex;
	flex-wrap: nowrap;
	-webkit-box-align: start;
	        align-items: flex-start; }
	.testimonial__img {
		position: relative;
		overflow: hidden;
		width: 110px;
		height: 110px;
		border-radius: 50%;
		align-self: flex-start; }
	.testimonial__body {
		padding-left: 40px;
		-webkit-box-flex: 1;
		        flex: 1 0 0; }
	.testimonial__text {
		font-size: 1.125rem;
		margin-bottom: 28px;
		display: inline-block;
		position: relative;
		color: #fff; }
	.testimonial__name {
		font-size: 0.9375rem;
		color: #FE5900;
		display: block;
		margin-bottom: 8px; }
	.testimonial__company {
		color: #909090;
		text-transform: uppercase;
		font-size: 0.9375rem; }

/* Style 2
-------------------------------------------------------*/
@media only screen and (max-width: 767px) {
	.testimonial-1 {
		display: block; } }

.testimonial-1__img {
	border-radius: 0;
	width: auto;
	height: auto; }
	@media only screen and (max-width: 767px) {
		.testimonial-1__img {
			width: 100%; } }

.testimonial-1__img-holder {
	position: relative;
	margin-bottom: 22px; }
	.testimonial-1__img-holder:before {
		content: '';
		position: absolute;
		display: block;
		width: 100%;
		height: 100%;
		left: -22px;
		bottom: -22px;
		background-color: #dddddd; }
	@media only screen and (max-width: 767px) {
		.testimonial-1__img-holder {
			margin-bottom: 60px; } }

.testimonial-1__body {
	padding: 0; }
	@media only screen and (min-width: 768px) {
		.testimonial-1__body {
			padding-left: 90px; } }

.testimonial-1__text {
	color: #131313;
	font-size: 2rem; }

.testimonial-1__name {
	font-size: 1.25rem; }

/*-------------------------------------------------------*/
/* Clients
/*-------------------------------------------------------*/
.client-logo {
	border-bottom: 1px solid #E9E9E9;
	text-align: center; }
	@media only screen and (min-width: 576px) {
		.client-logo {
			border-right: 1px solid #E9E9E9; } }
	.client-logo:last-child {
		border-right: none; }

@media only screen and (min-width: 576px) {
	.clients-second-row .client-logo {
		border-bottom: none; } }

@media only screen and (max-width: 575px) {
	.clients-second-row .client-logo:last-child {
		border-bottom: none; } }

/*-------------------------------------------------------*/
/* Team
/*-------------------------------------------------------*/
.team {
	margin-bottom: 32px; }
	.team__img {
		width: 100%; }
	.team__name {
		font-size: 1.75rem;
		margin-top: 30px; }
	.team__position {
		font-size: 0.9375rem;
		text-transform: uppercase;
		display: inline-block;
		margin-bottom: 14px; }
	@media only screen and (max-width: 991px) {
		.team {
			margin-bottom: 56px; } }

/*-------------------------------------------------------*/
/* Call To Action
/*-------------------------------------------------------*/
.call-to-action {
	position: relative;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat; }
	.call-to-action__title {
		font-size: 2.25rem;
		line-height: 1.4;
		margin-bottom: 16px; }
		@media only screen and (max-width: 575px) {
			.call-to-action__title {
				font-size: 1.5rem; } }
	.call-to-action__text {
		margin-bottom: 32px;
		color: #131313; }

/*-------------------------------------------------------*/
/* Contact Form
/*-------------------------------------------------------*/
.contact-form-modal {
	padding: 0 !important; }
	.contact-form-modal__dialog {
		display: -webkit-box;
		display: flex;
		margin: 0;
		max-width: 100%; }
	.contact-form-modal__content {
		border-radius: 0;
		border: 0;
		-webkit-box-pack: center;
		        justify-content: center;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		        flex-direction: row;
		height: 100vh; }
	.contact-form-modal__body {
		-webkit-box-flex: 1;
		        flex: 1 0 0;
		max-width: 555px;
		margin: auto; }
	.contact-form-modal__close {
		width: 40px;
		height: 40px;
		position: absolute;
		top: 40px;
		right: 40px; }
		.contact-form-modal__close span {
			font-size: 0; }
			.contact-form-modal__close span:before, .contact-form-modal__close span:after {
				content: '';
				display: block;
				position: absolute;
				top: 20px;
				height: 2px;
				width: 40px;
				background-color: #131313; }
			.contact-form-modal__close span:before {
				-webkit-transform: rotate(-45deg);
				transform: rotate(-45deg); }
			.contact-form-modal__close span:after {
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg); }
		.contact-form-modal__close:hover, .contact-form-modal__close:focus {
			background-color: transparent; }

.contact-form__message {
	height: 50px;
	width: 100%;
	font-size: 13px;
	line-height: 50px;
	text-align: center;
	float: none;
	margin-top: 20px;
	display: none;
	color: #fff; }
	.contact-form__message.error {
		background-color: #f8d7da;
		color: #721c24; }
	.contact-form__message.success {
		background-color: #d4edda;
		color: #155724; }

/*-------------------------------------------------------*/
/* Newsletter
/*-------------------------------------------------------*/
.newsletter {
	/* Change Color of Placeholders */ }
	.newsletter .title-row {
		margin-bottom: 20px; }
	.newsletter .section-title {
		font-size: 36px;
		margin-bottom: 30px; }
	.newsletter .subtitle {
		font-size: 21px;
		font-weight: 400; }
	.newsletter__form {
		max-width: 460px;
		margin-left: auto;
		margin-right: auto; }
	.newsletter .mc4wp-form-fields {
		display: -webkit-box;
		display: flex; }
		.newsletter .mc4wp-form-fields > .form-group:first-child {
			-webkit-box-flex: 1;
			        flex: 1;
			margin-right: 8px; }
			@media only screen and (max-width: 575px) {
				.newsletter .mc4wp-form-fields > .form-group:first-child {
					margin-right: 0; } }
		@media only screen and (max-width: 575px) {
			.newsletter .mc4wp-form-fields {
				display: block; } }
	.newsletter input {
		height: 50px; }
	.newsletter input[type=email],
	.newsletter input[type=text] {
		color: #fff;
		background-color: transparent;
		border-color: rgba(213, 217, 237, 0.36); }
		.newsletter input[type=email]:focus,
		.newsletter input[type=text]:focus {
			border-color: #fff; }
	.newsletter input[type=email]::-webkit-input-placeholder,
	.newsletter input[type=text]::-webkit-input-placeholder {
		color: #fff; }
	.newsletter input[type=email]:-moz-placeholder,
	.newsletter input[type=text]:-moz-placeholder {
		color: #fff;
		opacity: 1; }
	.newsletter input[type=email]::-moz-placeholder,
	.newsletter input[type=text]::-moz-placeholder {
		color: #fff;
		opacity: 1; }
	.newsletter input[type=email]:-ms-input-placeholder,
	.newsletter input[type=text]:-ms-input-placeholder {
		color: #fff; }

/*-------------------------------------------------------*/
/* Page Title
/*-------------------------------------------------------*/
.page-title {
	position: relative;
	width: 100%;
	display: block;
	padding: 100px 0;
	text-align: center;
	background-size: cover;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-color: #F6F6F6; }
	.page-title__holder {
		max-width: 580px;
		position: relative; }
	.page-title__title {
		font-size: 3rem;
		line-height: 1.3;
		margin-bottom: 24px; }
		@media only screen and (min-width: 992px) {
			.page-title__title {
				font-size: 4.25rem; } }
	.page-title__subtitle {
		font-size: 1.5rem;
		line-height: 1.4;
		color: #fff; }
	@media only screen and (max-width: 767px) {
		.page-title {
			padding: 40px 0; } }

/*-------------------------------------------------------*/
/* Widgets
/*-------------------------------------------------------*/
.widget {
	margin-bottom: 48px; }
	.widget li {
		list-style: none; }
	.widget-title {
		margin-bottom: 24px;
		font-size: 1rem;
		font-weight: 700; }

/* Widget About Us
-------------------------------------------------------*/
.widget-about-us__text, .widget-about-us__email, .widget-about-us__phone {
	display: block;
	font-size: 1.25rem;
	margin-bottom: 6px; }

.widget-about-us__text, .widget-about-us__phone,
.widget-about-us a:hover,
.widget-about-us a:focus {
	color: #fff; }

/* Widget Address
-------------------------------------------------------*/
.widget-address {
	font-size: 1.25rem;
	color: #fff; }
	.widget-address__address {
		line-height: 1.8;
		margin-bottom: 20px; }
	.widget-address__url {
		color: #fff;
		text-transform: uppercase;
		font-size: 0.9375rem;
		position: relative;
		-webkit-transition: 0.2s ease-in-out;
		transition: 0.2s ease-in-out; }
		.widget-address__url:after {
			content: '';
			display: block;
			position: absolute;
			height: 2px;
			left: 0;
			width: 100%;
			background-color: #FE5900;
			-webkit-transition: 0.2s ease-in-out;
			transition: 0.2s ease-in-out; }
		.widget-address__url:hover:after, .widget-address__url:focus:after {
			background-color: #fff; }

/* Widget Tags / Entry Tags
-------------------------------------------------------*/
.widget_tag_cloud a,
.entry__tags a {
	padding: 12px 18px;
	line-height: 1;
	margin: 0 8px 8px 0;
	font-size: 0.875rem !important;
	background-color: #F6F6F6;
	color: #131313;
	font-family: "DM Sans", sans-serif;
	display: inline-block;
	float: left;
	-webkit-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out; }
	.widget_tag_cloud a:hover, .widget_tag_cloud a:focus,
	.entry__tags a:hover,
	.entry__tags a:focus {
		background-color: #131313;
		color: #fff; }

.tagcloud {
	overflow: hidden; }

/*-------------------------------------------------------*/
/* Gutenberg Front-end Styles
/*-------------------------------------------------------*/
/* Buttons
-------------------------------------------------------*/
.wp-block-button .wp-block-button__link {
	padding: 14px 32px;
	font-size: 0.9375rem;
	border-radius: 5px;
	font-weight: 700;
	line-height: 1.5 !important; }

.wp-block-button__link:not(.has-background),
.wp-block-button__link:not(.has-background):active,
.wp-block-button__link:not(.has-background):focus,
.wp-block-button__link:not(.has-background):hover {
	background-color: #FE5900; }

/* Full-width Images
-------------------------------------------------------*/
@media only screen and (min-width: 992px) {
	.fullwidth .entry__article .alignwide {
		margin-left: -220px;
		margin-right: -220px;
		width: auto;
		position: relative;
		z-index: 1; }
	.fullwidth .entry__article .alignfull {
		margin-left: calc( 50% - 50vw);
		margin-right: calc( 50% - 50vw);
		max-width: 100vw;
		position: relative;
		z-index: 1; }
		.fullwidth .entry__article .alignfull img {
			width: 100vw; } }

/* Images
-------------------------------------------------------*/
.wp-block-image {
	margin-top: 40px;
	margin-bottom: 40px; }
	.wp-block-image .alignleft {
		margin-right: 2em;
		max-width: 40%; }
		@media only screen and (max-width: 575px) {
			.wp-block-image .alignleft {
				float: none;
				margin-right: 0; } }
	.wp-block-image .alignright {
		margin-left: 2em;
		max-width: 40%; }
		@media only screen and (max-width: 575px) {
			.wp-block-image .alignright {
				float: none;
				margin-left: 0; } }

/* Tables
-------------------------------------------------------*/
.wp-block-table {
	width: 100%;
	margin-bottom: 2rem; }

/* Latest Comments
-------------------------------------------------------*/
ol.wp-block-latest-comments {
	padding-left: 0; }

/* Galleries
-------------------------------------------------------*/
ul.wp-block-gallery {
	list-style: none;
	padding-left: 0 !important; }

.wp-block-image figcaption {
	color: #909090; }

.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
	position: absolute;
	bottom: 0;
	width: 100%;
	max-height: 100%;
	overflow: auto;
	padding: 40px 10px 9px;
	color: #fff;
	text-align: center;
	font-size: 13px;
	background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.7)), color-stop(70%, rgba(0, 0, 0, 0.3)), to(transparent));
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3) 70%, transparent); }

/* Separators
-------------------------------------------------------*/
hr {
	margin-top: 20px;
	margin-bottom: 20px;
	border: 0;
	border-top: 1px solid #E9E9E9; }

.wp-block-separator.is-style-dots {
	background: 0 0;
	border: none;
	text-align: center;
	max-width: none;
	line-height: 1;
	height: auto; }
	.wp-block-separator.is-style-dots::before {
		content: "\00b7 \00b7 \00b7";
		color: #909090;
		font-size: 1.25rem;
		font-weight: 700;
		letter-spacing: 2em;
		padding-left: 2em;
		font-family: serif; }

.wp-block-separator.is-style-default {
	max-width: 100px; }

.wp-block-separator.is-style-dots:before {
	font-weight: 700; }

/* Blockquote / Pullquote
-------------------------------------------------------*/
blockquote {
	margin: 0;
	line-height: 1.5; }
	blockquote p {
		margin-bottom: 0; }
	blockquote cite {
		color: #909090;
		font-size: 0.875rem;
		font-weight: 700;
		margin-top: 1em;
		display: inline-block;
		font-style: normal; }

.wp-block-quote {
	padding: 24px;
	margin-bottom: 28px;
	max-width: 600px;
	margin-left: auto;
	margin-right: auto; }
	.wp-block-quote:before {
		content: '\e900';
		font-family: 'ui-icons';
		color: #131313;
		font-size: 1.4rem; }
	.wp-block-quote p {
		font-size: 1.75rem;
		line-height: 1.4;
		font-style: italic;
		color: #131313;
		font-family: "DM Sans", sans-serif; }
		@media only screen and (max-width: 991px) {
			.wp-block-quote p {
				font-size: 1.4rem; } }
	.wp-block-quote.is-large, .wp-block-quote.is-style-large {
		padding: 40px;
		margin-top: 40px;
		margin-bottom: 40px; }
		.wp-block-quote.is-large p, .wp-block-quote.is-style-large p {
			font-style: normal;
			position: relative; }
		.wp-block-quote.is-large cite,
		.wp-block-quote.is-large footer, .wp-block-quote.is-style-large cite,
		.wp-block-quote.is-style-large footer {
			font-size: 0.9375rem; }

.wp-block-pullquote {
	padding: 0;
	text-align: left;
	line-height: 1.5;
	color: #131313;
	position: relative; }
	.wp-block-pullquote blockquote {
		padding-left: 40px; }
		.wp-block-pullquote blockquote:before {
			content: '\e900';
			font-family: 'ui-icons';
			font-size: 1.4rem;
			position: absolute;
			left: 0; }
	.wp-block-pullquote p {
		font-family: "DM Sans", sans-serif; }
	@media only screen and (min-width: 576px) {
		.wp-block-pullquote.alignleft p, .wp-block-pullquote.alignright p {
			font-size: 1.25rem; }
		.wp-block-pullquote.alignleft {
			margin-right: 2em; }
		.wp-block-pullquote.alignright {
			margin-left: 2em; } }
	@media only screen and (max-width: 575px) {
		.wp-block-pullquote.alignleft, .wp-block-pullquote.alignright {
			max-width: 100%;
			float: none;
			margin-left: 0;
			margin-right: 0; } }

.wp-block-pullquote.is-style-solid-color {
	border: none; }
	.wp-block-pullquote.is-style-solid-color blockquote p {
		margin-bottom: 0; }
	.wp-block-pullquote.is-style-solid-color.alignleft blockquote p,
	.wp-block-pullquote.is-style-solid-color.alignright blockquote p {
		font-size: 1.25rem; }

.wp-block-pullquote.is-style-solid-color blockquote {
	margin-left: auto;
	margin-right: auto;
	text-align: left;
	max-width: 60%; }

.wp-block-gallery {
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap;
	list-style-type: none;
	padding: 0; }

.wp-block-gallery .blocks-gallery-image, .wp-block-gallery .blocks-gallery-item {
	margin: 0 16px 16px 0;
	display: -webkit-box;
	display: flex;
	-webkit-box-flex: 1;
	        flex-grow: 1;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	        flex-direction: column;
	-webkit-box-pack: center;
	        justify-content: center;
	position: relative; }

.wp-block-gallery .blocks-gallery-image:nth-of-type(even), .wp-block-gallery .blocks-gallery-item:nth-of-type(even) {
	margin-right: 0; }

.wp-block-gallery .blocks-gallery-image, .wp-block-gallery .blocks-gallery-item {
	width: calc((100% - 16px)/ 2); }

@media (min-width: 600px) {
	.wp-block-gallery.columns-3 .blocks-gallery-image, .wp-block-gallery.columns-3 .blocks-gallery-item {
		width: calc((100% - 16px * 2)/ 3);
		margin-right: 16px; } }

.wp-block-gallery .blocks-gallery-image:last-child, .wp-block-gallery .blocks-gallery-item:last-child {
	margin-right: 0; }

.wp-block-gallery .blocks-gallery-image figure, .wp-block-gallery .blocks-gallery-item figure {
	margin: 0;
	height: 100%; }

.wp-block-gallery.is-cropped .blocks-gallery-image a, .wp-block-gallery.is-cropped .blocks-gallery-image img, .wp-block-gallery.is-cropped .blocks-gallery-item a, .wp-block-gallery.is-cropped .blocks-gallery-item img {
	width: 100%; }

.wp-block-gallery .blocks-gallery-image figure, .wp-block-gallery .blocks-gallery-item figure {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: end;
	        align-items: flex-end;
	-webkit-box-pack: start;
	        justify-content: flex-start; }

.wp-block-columns {
	display: -webkit-box;
	display: flex;
	flex-wrap: wrap; }

@media (min-width: 782px) {
	.wp-block-columns {
		flex-wrap: nowrap; } }

@media (min-width: 600px) {
	.wp-block-column:not(:last-child) {
		margin-right: 32px; } }

@media (min-width: 600px) {
	.wp-block-column:nth-child(odd) {
		margin-right: 32px; } }

@media (min-width: 600px) {
	.wp-block-column {
		flex-basis: 50%;
		-webkit-box-flex: 0;
		        flex-grow: 0; } }

@media (min-width: 600px) {
	.wp-block-column:not(:first-child) {
		margin-left: 32px; } }

.wp-block-column {
	-webkit-box-flex: 1;
	        flex: 1;
	margin-bottom: 1em;
	flex-basis: 100%;
	min-width: 0;
	word-break: break-word;
	overflow-wrap: break-word; }

.cc-window {
	opacity: 1;
	-webkit-transition: opacity 1s ease;
	transition: opacity 1s ease; }

.cc-window.cc-invisible {
	opacity: 0; }

.cc-animate.cc-revoke {
	-webkit-transition: -webkit-transform 1s ease;
	transition: -webkit-transform 1s ease;
	transition: transform 1s ease;
	transition: transform 1s ease, -webkit-transform 1s ease; }

.cc-animate.cc-revoke.cc-top {
	-webkit-transform: translateY(-2em);
	        transform: translateY(-2em); }

.cc-animate.cc-revoke.cc-bottom {
	-webkit-transform: translateY(2em);
	        transform: translateY(2em); }

.cc-animate.cc-revoke.cc-active.cc-bottom, .cc-animate.cc-revoke.cc-active.cc-top, .cc-revoke:hover {
	-webkit-transform: translateY(0);
	        transform: translateY(0); }

.cc-grower {
	max-height: 0;
	overflow: hidden;
	-webkit-transition: max-height 1s;
	transition: max-height 1s; }

.cc-link, .cc-revoke:hover {
	text-decoration: underline; }

.cc-revoke, .cc-window {
	position: fixed;
	overflow: hidden;
	box-sizing: border-box;
	font-family: Helvetica,Calibri,Arial,sans-serif;
	font-size: 16px;
	line-height: 1.5em;
	display: -webkit-box;
	display: flex;
	flex-wrap: nowrap;
	z-index: 9999; }

.cc-window.cc-static {
	position: static; }

.cc-window.cc-floating {
	padding: 2em;
	max-width: 24em;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	        flex-direction: column; }

.cc-window.cc-banner {
	padding: 1em 1.8em;
	width: 100%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	        flex-direction: row; }

.cc-revoke {
	padding: .5em; }

.cc-header {
	font-size: 18px;
	font-weight: 700; }

.cc-btn, .cc-close, .cc-link, .cc-revoke {
	cursor: pointer; }

.cc-link {
	opacity: .8;
	display: inline-block;
	padding: .2em; }

.cc-link:hover {
	opacity: 1; }

.cc-link:active, .cc-link:visited {
	color: initial; }

.cc-btn {
	display: block;
	padding: .4em .8em;
	font-size: .9em;
	font-weight: 700;
	border-width: 2px;
	border-style: solid;
	text-align: center;
	white-space: nowrap; }

.cc-highlight .cc-btn:first-child {
	background-color: transparent;
	border-color: transparent; }

.cc-highlight .cc-btn:first-child:focus, .cc-highlight .cc-btn:first-child:hover {
	background-color: transparent;
	text-decoration: underline; }

.cc-close {
	display: block;
	position: absolute;
	top: .5em;
	right: .5em;
	font-size: 1.6em;
	opacity: .9;
	line-height: .75; }

.cc-close:focus, .cc-close:hover {
	opacity: 1; }

.cc-revoke.cc-top {
	top: 0;
	left: 3em;
	border-bottom-left-radius: .5em;
	border-bottom-right-radius: .5em; }

.cc-revoke.cc-bottom {
	bottom: 0;
	left: 3em;
	border-top-left-radius: .5em;
	border-top-right-radius: .5em; }

.cc-revoke.cc-left {
	left: 3em;
	right: unset; }

.cc-revoke.cc-right {
	right: 3em;
	left: unset; }

.cc-top {
	top: 1em; }

.cc-left {
	left: 1em; }

.cc-right {
	right: 1em; }

.cc-bottom {
	bottom: 1em; }

.cc-floating > .cc-link {
	margin-bottom: 1em; }

.cc-floating .cc-message {
	display: block;
	margin-bottom: 1em; }

.cc-window.cc-floating .cc-compliance {
	-webkit-box-flex: 1;
	        flex: 1 0 auto; }

.cc-window.cc-banner {
	-webkit-box-align: center;
	        align-items: center; }

.cc-banner.cc-top {
	left: 0;
	right: 0;
	top: 0; }

.cc-banner.cc-bottom {
	left: 0;
	right: 0;
	bottom: 0; }

.cc-banner .cc-message {
	display: block;
	-webkit-box-flex: 1;
	        flex: 1 1 auto;
	max-width: 100%;
	margin-right: 1em; }

.cc-compliance {
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	        align-items: center;
	align-content: space-between; }

.cc-floating .cc-compliance > .cc-btn {
	-webkit-box-flex: 1;
	        flex: 1; }

.cc-btn + .cc-btn {
	margin-left: .5em; }

@media print {
	.cc-revoke, .cc-window {
		display: none; } }

@media screen and (max-width: 900px) {
	.cc-btn {
		white-space: normal; } }

@media screen and (max-width: 414px) and (orientation: portrait), screen and (max-width: 736px) and (orientation: landscape) {
	.cc-window.cc-top {
		top: 0; }
	.cc-window.cc-bottom {
		bottom: 0; }
	.cc-window.cc-banner, .cc-window.cc-floating, .cc-window.cc-left, .cc-window.cc-right {
		left: 0;
		right: 0; }
	.cc-window.cc-banner {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		        flex-direction: column; }
	.cc-window.cc-banner .cc-compliance {
		-webkit-box-flex: 1;
		        flex: 1 1 auto; }
	.cc-window.cc-floating {
		max-width: none; }
	.cc-window .cc-message {
		margin-bottom: 1em; }
	.cc-window.cc-banner {
		-webkit-box-align: unset;
		        align-items: unset; }
	.cc-window.cc-banner .cc-message {
		margin-right: 0; } }

.cc-floating.cc-theme-classic {
	padding: 1.2em;
	border-radius: 5px; }

.cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
	text-align: center;
	display: inline;
	-webkit-box-flex: 0;
	        flex: none; }

.cc-theme-classic .cc-btn {
	border-radius: 5px; }

.cc-theme-classic .cc-btn:last-child {
	min-width: 140px; }

.cc-floating.cc-type-info.cc-theme-classic .cc-btn {
	display: inline-block; }

.cc-theme-edgeless.cc-window {
	padding: 0; }

.cc-floating.cc-theme-edgeless .cc-message {
	margin: 2em 2em 1.5em; }

.cc-banner.cc-theme-edgeless .cc-btn {
	margin: 0;
	padding: .8em 1.8em;
	height: 100%; }

.cc-banner.cc-theme-edgeless .cc-message {
	margin-left: 1em; }

.cc-floating.cc-theme-edgeless .cc-btn + .cc-btn {
	margin-left: 0; }

/* Cookies banner
-------------------------------------------------------*/
.cc-container {
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: center;
	        justify-content: center;
	padding: 0; }

.cc-window {
	background-color: #131313 !important;
	padding: 5px 20px; }

.cc-message {
	margin-right: 20px;
	-webkit-box-flex: 0 !important;
	        flex: 0 1 auto !important;
	color: #fff;
	font-weight: 500;
	font-size: 15px; }

.cc-link {
	opacity: 1;
	font-weight: 700; }
	.cc-link:hover, .cc-link:focus {
		color: #fff; }

a.cc-btn.cc-dismiss {
	background-color: #FE5900;
	border-radius: 0;
	height: 32px;
	line-height: 32px;
	padding: 0 14px;
	border: 0;
	color: #fff;
	min-width: auto !important; }
	a.cc-btn.cc-dismiss:hover, a.cc-btn.cc-dismiss:focus {
		background-color: #ef5400; }

/*-------------------------------------------------------*/
/* Blog
/*-------------------------------------------------------*/
.entry {
	overflow: hidden;
	position: relative;
	margin-bottom: 48px; }
	.entry__header {
		margin-bottom: 36px; }
	.entry__img {
		width: 100%; }
		.entry__img-url {
			display: block; }
	.entry__title {
		font-size: 1.75rem;
		margin-top: 16px;
		margin-bottom: 16px; }
	.entry__excerpt {
		margin-top: 20px; }
		.entry__excerpt p {
			margin-bottom: 0; }

/*-------------------------------------------------------*/
/* Meta
/*-------------------------------------------------------*/
.entry__meta {
	display: -webkit-box;
	display: flex; }
	.entry__meta-item {
		display: inline-block;
		font-size: 0.75rem;
		color: #909090;
		text-transform: uppercase;
		margin-right: 12px; }
	.entry__meta a:hover, .entry__meta a:focus {
		color: #131313; }

.entry__meta-author {
	text-transform: none;
	font-size: 0.875rem; }
	.entry__meta-author-url {
		color: #909090; }

/*-------------------------------------------------------*/
/* Pagination
/*-------------------------------------------------------*/
.pagination {
	text-align: center;
	margin-top: 24px; }
	.pagination ul {
		list-style: none;
		clear: both;
		display: -webkit-box;
		display: flex;
		-webkit-box-pack: center;
		        justify-content: center; }
	.pagination__page {
		font-size: 0.875rem;
		display: inline-block;
		width: 40px;
		height: 40px;
		line-height: 36px;
		margin: 0 2px;
		text-align: center;
		color: #686868;
		vertical-align: middle;
		white-space: nowrap;
		border: 2px solid #E9E9E9;
		list-style: none;
		-webkit-transition: 0.2s ease-in-out;
		transition: 0.2s ease-in-out; }
	.pagination a:hover,
	.pagination a:focus, .pagination__page--active {
		background-color: #FE5900;
		color: #fff;
		border-color: transparent; }
	.pagination i {
		font-size: 0.75rem; }

/*-------------------------------------------------------*/
/* Single Post
/*-------------------------------------------------------*/
/* Page Title
-------------------------------------------------------*/
.blog-page-title {
	position: relative;
	text-align: center;
	padding: 200px 0 260px;
	margin-bottom: 0; }
	@media only screen and (max-width: 991px) {
		.blog-page-title {
			padding: 120px 0 140px; } }
	@media only screen and (max-width: 575px) {
		.blog-page-title {
			padding: 80px 0 140px; } }
	.blog-page-title__holder {
		padding: 40px 0; }
	.blog-page-title__title {
		font-size: 3rem;
		line-height: 1.2;
		color: #fff;
		margin-top: 8px;
		margin-bottom: 20px; }
		@media only screen and (min-width: 992px) {
			.blog-page-title__title {
				font-size: 4.25rem; } }
	.blog-page-title .entry__meta {
		-webkit-box-pack: center;
		        justify-content: center; }
		.blog-page-title .entry__meta-item {
			font-size: 0.9375rem;
			text-transform: none;
			margin-right: 0;
			font-weight: 700; }
			.blog-page-title .entry__meta-item::after {
				content: "•";
				display: inline-block;
				padding: 0 8px 0 5px; }
			.blog-page-title .entry__meta-item:last-child:after {
				display: none; }
		.blog-page-title .entry__meta a, .blog-page-title .entry__meta span {
			color: #fff; }
		.blog-page-title .entry__meta a:hover {
			text-decoration: underline; }

/* Featured Image
-------------------------------------------------------*/
.blog-featured-img {
	width: 100%; }
	.blog-featured-img-container {
		position: relative;
		z-index: 1;
		margin-bottom: 48px; }
	.blog-featured-img-container--offset {
		margin-top: -210px; }
		@media only screen and (max-width: 991px) {
			.blog-featured-img-container--offset {
				margin-top: -80px; } }

/* Article
-------------------------------------------------------*/
.single-post__entry {
	overflow: visible; }

.entry__article {
	font-size: 1.125rem;
	line-height: 1.8; }
	@media only screen and (max-width: 991px) {
		.entry__article {
			margin-top: 16px; } }
	.entry__article > p:first-of-type {
		font-size: 1.5rem; }
	.entry__article > h1,
	.entry__article > h2,
	.entry__article > h3,
	.entry__article > h4,
	.entry__article > h5,
	.entry__article > h6 {
		margin-top: 40px;
		margin-bottom: 20px; }
	.entry__article > p, .entry__article ul, .entry__article ol {
		margin-bottom: 34px; }
	.entry__article a:not(.wp-block-button__link) {
		color: #4C86E7; }
		.entry__article a:not(.wp-block-button__link):hover {
			color: #FE5900; }
	.entry__article ol, .entry__article ul {
		padding-left: 40px; }

figcaption {
	font-size: 0.9375rem;
	font-style: italic;
	margin-top: 16px;
	color: #909090;
	text-align: center; }

.dropcap {
	float: left;
	color: #131313;
	font-weight: 700;
	font-size: 6.875rem;
	line-height: 1;
	padding-right: 20px; }

/* Entry Share
/*-------------------------------------------------------*/
.entry__article-wrap {
	position: relative; }

@media only screen and (min-width: 992px) {
	.entry__share {
		position: absolute !important;
		height: 100%;
		margin-left: -160px;
		margin-bottom: 0; }
		.entry__share-socials {
			width: 40px; } }

@media (min-width: 1400px) {
	.entry__share {
		margin-left: -220px; } }

/* Entry Tags
/*-------------------------------------------------------*/
.entry__tags {
	margin-top: 24px;
	margin-bottom: 56px;
	overflow: hidden; }
	.entry__tags-label {
		color: #131313;
		font-weight: 700;
		font-size: 18px;
		display: inline-block;
		margin-right: 4px; }

/* Author
/*-------------------------------------------------------*/
.entry-author {
	padding: 48px 40px 38px;
	background-color: #F6F6F6; }
	.entry-author__img-holder {
		display: block; }
	.entry-author__img {
		height: 68px;
		width: 68px;
		display: block;
		margin-right: 24px;
		border-radius: 50%; }
	.entry-author__name {
		margin-bottom: 5px;
		font-size: 1.125rem; }
	.entry-author__description {
		margin-bottom: 18px; }
	@media only screen and (min-width: 576px) {
		.entry-author__img-holder {
			float: left; }
		.entry-author__info {
			overflow: hidden; } }

/* Comments
/*-------------------------------------------------------*/
.entry__comments {
	margin-top: 64px; }

.entry__comments-title,
.comment-respond__title {
	font-size: 2rem;
	margin-bottom: 38px; }

.comment {
	list-style: none; }
	@media only screen and (min-width: 576px) {
		.comment .children {
			padding-left: 8%; } }
	.comment-list > li:first-child > .comment-body {
		border-top: 0;
		padding-top: 0; }
	.comment-body {
		margin-top: 32px;
		padding-bottom: 28px;
		border-bottom: 2px dotted #E9E9E9; }
	.comment-text {
		overflow: hidden; }
		.comment-text p {
			margin-bottom: 10px; }
	.comment-avatar {
		float: left;
		margin-right: 20px;
		display: block;
		border-radius: 50%;
		overflow: hidden; }
	.comment-author {
		font-family: "DM Sans", sans-serif;
		font-size: 0.9375rem;
		display: inline-block;
		margin-bottom: 5px;
		margin-right: 8px; }
	.comment-metadata {
		display: inline-block; }
	.comment-date, .comment-edit-link {
		display: inline-block;
		margin-bottom: 10px;
		font-size: 0.875rem;
		color: #909090; }
	.comment-edit-link {
		margin-left: 5px;
		margin-bottom: 0;
		color: #FE5900; }
	.comment-reply {
		color: #131313;
		font-weight: 600; }
		.comment-reply:hover {
			color: #FE5900; }

/* Comment Form
/*-------------------------------------------------------*/
.comment-form p {
	margin: 0; }

.comment-respond {
	margin-top: 64px; }

/* Entry Navigation
/*-------------------------------------------------------*/
.entry-navigation__row {
	display: -webkit-box;
	display: flex; }

.entry-navigation__col {
	width: 50%;
	display: block;
	overflow: hidden;
	position: relative; }
	.entry-navigation__col:after {
		content: "";
		top: 0;
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.45); }
	.entry-navigation__col:hover .entry-navigation__img {
		-webkit-transform: scale(1.2);
		transform: scale(1.2); }

.entry-navigation__img {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	-webkit-transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
	transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1); }

.entry-navigation__url {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 2; }

.entry-navigation--left {
	float: left;
	text-align: right; }

.entry-navigation--right {
	float: right; }

.entry-navigation__body {
	padding: 96px 8%;
	position: relative;
	z-index: 1; }

.entry-navigation__label {
	display: inline-block;
	margin-bottom: 5px;
	color: #fff;
	font-size: 0.875rem; }

.entry-navigation__title {
	color: #fff; }

@media only screen and (max-width: 575px) {
	.entry-navigation__row {
		display: block; }
	.entry-navigation__col {
		width: 100%;
		float: none;
		text-align: center; } }

/* Alignment
-------------------------------------------------------*/
.alignleft,
.alignright {
	max-width: 100% !important;
	float: none; }

@media only screen and (min-width: 576px) {
	.alignleft {
		float: left;
		margin: .5em 2em 2em 0; }
	.alignright {
		float: right;
		margin: .5em 0 2em 2em; }
	.alignleft,
	.alignright {
		max-width: 40% !important; } }

@media only screen and (max-width: 575px) {
	margin-left: 0;
	margin-right: 0;
	float: none; }

.alignnone {
	margin: .5em 2em; }

.aligncenter {
	display: block;
	margin: .5em auto; }

a img.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto; }

/*-------------------------------------------------------*/
/* Single Project
/*-------------------------------------------------------*/
/* Hero
-------------------------------------------------------*/
.project__hero {
	height: 420px;
	margin-top: 60px; }
	@media only screen and (min-width: 992px) {
		.project__hero {
			height: 100vh;
			margin-top: 0; } }

.project__hero-img-holder {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	height: 100%;
	margin: 0; }

.project__hero-info {
	max-width: 840px;
	position: absolute;
	bottom: 90px;
	padding-right: 20px;
	padding-top: 40px; }
	@media only screen and (max-width: 1199px) {
		.project__hero-info {
			bottom: 40px; } }

.project__hero-title {
	font-size: 3rem;
	line-height: 1.2; }
	@media only screen and (min-width: 992px) {
		.project__hero-title {
			font-size: 4.25rem; } }

.project__hero-description {
	font-size: 1.2rem;
	margin-bottom: 0;
	color: #fff;
	opacity: 0.7; }
	@media only screen and (min-width: 992px) {
		.project__hero-description {
			font-size: 1.625rem; } }

/* Project Description
-------------------------------------------------------*/
.project__info-title {
	font-size: 2.125rem;
	margin-bottom: 20px; }

/* Sidebar Info
-------------------------------------------------------*/
.project__info-list {
	list-style: none; }
	.project__info-list-item {
		margin-bottom: 24px; }
	.project__info-list-label {
		margin-bottom: 8px;
		text-transform: uppercase;
		font-size: 0.8125rem;
		display: block; }
	.project__info-list-title {
		color: #131313;
		font-weight: 700;
		letter-spacing: -0.05em; }
	.project__info-list a:hover, .project__info-list a:focus {
		color: #FE5900; }

/* Image Full
-------------------------------------------------------*/
.project__image-full {
	max-width: 1548px;
	width: 100%;
	margin: auto; }

/* Process
-------------------------------------------------------*/
.project__process {
	margin-bottom: 48px; }
	.project__process-title {
		margin-bottom: 24px; }
	.project__process-description {
		margin-bottom: 0; }

/* Gallery
-------------------------------------------------------*/
.project__gallery-img-holder {
	margin-bottom: 40px; }

.project__gallery-item {
	width: 100%; }

/* Related Projects
-------------------------------------------------------*/
.related-project {
	margin-bottom: 56px; }
	.related-project__header {
		margin-bottom: 34px; }
	.related-project__img {
		width: 100%; }
	.related-project__body {
		text-align: center; }
	.related-project__title {
		font-size: 1.75rem; }
	.related-project__category {
		color: #909090;
		font-size: 0.9375rem;
		letter-spacing: -0.05em;
		font-weight: 700; }

/*-------------------------------------------------------*/
/* Spacings
/*-------------------------------------------------------*/
.m-0 {
	margin: 0 !important; }

.p-0 {
	padding: 0 !important; }

.mt-0 {
	margin-top: 0 !important; }

.mt-8 {
	margin-top: 8px; }

.mt-16 {
	margin-top: 16px; }

.mt-24 {
	margin-top: 24px; }

.mt-32 {
	margin-top: 32px; }

.mt-40 {
	margin-top: 40px; }

.mt-48 {
	margin-top: 48px; }

.mt-56 {
	margin-top: 56px; }

.mt-64 {
	margin-top: 64px; }

.mt-72 {
	margin-top: 72px; }

.mt-80 {
	margin-top: 80px; }

.mb-0 {
	margin-bottom: 0 !important; }

.mb-8 {
	margin-bottom: 8px; }

.mb-16 {
	margin-bottom: 16px; }

.mb-24 {
	margin-bottom: 24px; }

.mb-32 {
	margin-bottom: 32px; }

.mb-40 {
	margin-bottom: 40px; }

.mb-48 {
	margin-bottom: 48px; }

.mb-56 {
	margin-bottom: 56px; }

.mb-64 {
	margin-bottom: 64px; }

.mb-72 {
	margin-bottom: 72px; }

.mb-80 {
	margin-bottom: 80px; }

.pt-0 {
	padding-top: 0; }

.pt-8 {
	padding-top: 8px; }

.pt-16 {
	padding-top: 16px; }

.pt-24 {
	padding-top: 24px; }

.pt-32 {
	padding-top: 32px; }

.pt-40 {
	padding-top: 40px; }

.pt-48 {
	padding-top: 48px; }

.pt-56 {
	padding-top: 56px; }

.pt-64 {
	padding-top: 64px; }

.pt-72 {
	padding-top: 72px; }

.pt-80 {
	padding-top: 80px; }

.pt-96 {
	padding-top: 96px; }

.pb-0 {
	padding-bottom: 0; }

.pb-8 {
	padding-bottom: 8px; }

.pb-16 {
	padding-bottom: 16px; }

.pb-24 {
	padding-bottom: 24px; }

.pb-32 {
	padding-bottom: 32px; }

.pb-40 {
	padding-bottom: 40px; }

.pb-48 {
	padding-bottom: 48px; }

.pb-56 {
	padding-bottom: 56px; }

.pb-64 {
	padding-bottom: 64px; }

.pb-72 {
	padding-bottom: 72px; }

.pb-80 {
	padding-bottom: 80px; }

.pb-88 {
	padding-bottom: 88px; }

.pb-96 {
	padding-bottom: 96px; }

@media only screen and (max-width: 1199px) {
	.mt-lg-0 {
		margin-top: 0 !important; }
	.mt-lg-8 {
		margin-top: 8px; }
	.mt-lg-16 {
		margin-top: 16px; }
	.mt-lg-24 {
		margin-top: 24px; }
	.mt-lg-32 {
		margin-top: 32px; }
	.mt-lg-40 {
		margin-top: 40px; }
	.mt-lg-48 {
		margin-top: 48px; }
	.mt-lg-56 {
		margin-top: 56px; }
	.mt-lg-64 {
		margin-top: 64px; }
	.mt-lg-72 {
		margin-top: 72px; }
	.mt-lg-80 {
		margin-top: 80px; }
	.mb-lg-0 {
		margin-bottom: 0 !important; }
	.mb-lg-8 {
		margin-bottom: 8px; }
	.mb-lg-16 {
		margin-bottom: 16px; }
	.mb-lg-24 {
		margin-bottom: 24px; }
	.mb-lg-32 {
		margin-bottom: 32px; }
	.mb-lg-40 {
		margin-bottom: 40px; }
	.mb-lg-48 {
		margin-bottom: 48px; }
	.mb-lg-56 {
		margin-bottom: 56px; }
	.mb-lg-64 {
		margin-bottom: 64px; }
	.mb-lg-72 {
		margin-bottom: 72px; }
	.mb-lg-80 {
		margin-bottom: 80px; }
	.pt-lg-0 {
		padding-top: 0; }
	.pt-lg-8 {
		padding-top: 8px; }
	.pt-lg-16 {
		padding-top: 16px; }
	.pt-lg-24 {
		padding-top: 24px; }
	.pt-lg-32 {
		padding-top: 32px; }
	.pt-lg-40 {
		padding-top: 40px; }
	.pt-lg-48 {
		padding-top: 48px; }
	.pt-lg-56 {
		padding-top: 56px; }
	.pt-lg-64 {
		padding-top: 64px; }
	.pt-lg-72 {
		padding-top: 72px; }
	.pt-lg-80 {
		padding-top: 80px; }
	.pt-lg-88 {
		padding-top: 88px; }
	.pt-lg-96 {
		padding-top: 96px; }
	.pb-lg-0 {
		padding-bottom: 0; }
	.pb-lg-8 {
		padding-bottom: 8px; }
	.pb-lg-16 {
		padding-bottom: 16px; }
	.pb-lg-24 {
		padding-bottom: 24px; }
	.pb-lg-32 {
		padding-bottom: 32px; }
	.pb-lg-40 {
		padding-bottom: 40px; }
	.pb-lg-48 {
		padding-bottom: 48px; }
	.pb-lg-56 {
		padding-bottom: 56px; }
	.pb-lg-64 {
		padding-bottom: 64px; }
	.pb-lg-72 {
		padding-bottom: 72px; }
	.pb-lg-80 {
		padding-bottom: 80px; }
	.pb-lg-88 {
		padding-bottom: 88px; }
	.pb-lg-96 {
		padding-bottom: 96px; } }

@media only screen and (max-width: 991px) {
	.mt-md-0 {
		margin-top: 0 !important; }
	.mt-md-8 {
		margin-top: 8px; }
	.mt-md-16 {
		margin-top: 16px; }
	.mt-md-24 {
		margin-top: 24px; }
	.mt-md-32 {
		margin-top: 32px; }
	.mt-md-40 {
		margin-top: 40px; }
	.mt-md-48 {
		margin-top: 48px; }
	.mt-md-56 {
		margin-top: 56px; }
	.mt-md-64 {
		margin-top: 64px; }
	.mt-md-72 {
		margin-top: 72px; }
	.mt-md-80 {
		margin-top: 80px; }
	.mb-md-0 {
		margin-bottom: 0 !important; }
	.mb-md-8 {
		margin-bottom: 8px; }
	.mb-md-16 {
		margin-bottom: 16px; }
	.mb-md-24 {
		margin-bottom: 24px; }
	.mb-md-32 {
		margin-bottom: 32px; }
	.mb-md-40 {
		margin-bottom: 40px; }
	.mb-md-48 {
		margin-bottom: 48px; }
	.mb-md-56 {
		margin-bottom: 56px; }
	.mb-md-64 {
		margin-bottom: 64px; }
	.mb-md-72 {
		margin-bottom: 72px; }
	.mb-md-80 {
		margin-bottom: 80px; }
	.pt-md-0 {
		padding-top: 0; }
	.pt-md-8 {
		padding-top: 8px; }
	.pt-md-16 {
		padding-top: 16px; }
	.pt-md-24 {
		padding-top: 24px; }
	.pt-md-32 {
		padding-top: 32px; }
	.pt-md-40 {
		padding-top: 40px; }
	.pt-md-48 {
		padding-top: 48px; }
	.pt-md-56 {
		padding-top: 56px; }
	.pt-md-64 {
		padding-top: 64px; }
	.pt-md-72 {
		padding-top: 72px; }
	.pt-md-80 {
		padding-top: 80px; }
	.pt-md-88 {
		padding-top: 88px; }
	.pt-md-96 {
		padding-top: 96px; }
	.pb-md-0 {
		padding-bottom: 0; }
	.pb-md-8 {
		padding-bottom: 8px; }
	.pb-md-16 {
		padding-bottom: 16px; }
	.pb-md-24 {
		padding-bottom: 24px; }
	.pb-md-32 {
		padding-bottom: 32px; }
	.pb-md-40 {
		padding-bottom: 40px; }
	.pb-md-48 {
		padding-bottom: 48px; }
	.pb-md-56 {
		padding-bottom: 56px; }
	.pb-md-64 {
		padding-bottom: 64px; }
	.pb-md-72 {
		padding-bottom: 72px; }
	.pb-md-80 {
		padding-bottom: 80px; }
	.pb-md-88 {
		padding-bottom: 88px; }
	.pb-md-96 {
		padding-bottom: 96px; } }

@media only screen and (max-width: 767px) {
	.mt-sm-0 {
		margin-top: 0 !important; }
	.mt-sm-8 {
		margin-top: 8px; }
	.mt-sm-16 {
		margin-top: 16px; }
	.mt-sm-24 {
		margin-top: 24px; }
	.mt-sm-32 {
		margin-top: 32px; }
	.mt-sm-40 {
		margin-top: 40px; }
	.mt-sm-48 {
		margin-top: 48px; }
	.mt-sm-56 {
		margin-top: 56px; }
	.mt-sm-64 {
		margin-top: 64px; }
	.mt-sm-72 {
		margin-top: 72px; }
	.mt-sm-80 {
		margin-top: 80px; }
	.mb-sm-0 {
		margin-bottom: 0 !important; }
	.mb-sm-8 {
		margin-bottom: 8px; }
	.mb-sm-16 {
		margin-bottom: 16px; }
	.mb-sm-24 {
		margin-bottom: 24px; }
	.mb-sm-32 {
		margin-bottom: 32px; }
	.mb-sm-40 {
		margin-bottom: 40px; }
	.mb-sm-48 {
		margin-bottom: 48px; }
	.mb-sm-56 {
		margin-bottom: 56px; }
	.mb-sm-64 {
		margin-bottom: 64px; }
	.mb-sm-72 {
		margin-bottom: 72px; }
	.mb-sm-80 {
		margin-bottom: 80px; }
	.pt-sm-0 {
		padding-top: 0; }
	.pt-sm-8 {
		padding-top: 8px; }
	.pt-sm-16 {
		padding-top: 16px; }
	.pt-sm-24 {
		padding-top: 24px; }
	.pt-sm-32 {
		padding-top: 32px; }
	.pt-sm-40 {
		padding-top: 40px; }
	.pt-sm-48 {
		padding-top: 48px; }
	.pt-sm-56 {
		padding-top: 56px; }
	.pt-sm-64 {
		padding-top: 64px; }
	.pt-sm-72 {
		padding-top: 72px; }
	.pt-sm-80 {
		padding-top: 80px; }
	.pt-sm-88 {
		padding-top: 88px; }
	.pt-sm-96 {
		padding-top: 96px; }
	.pb-sm-0 {
		padding-bottom: 0; }
	.pb-sm-8 {
		padding-bottom: 8px; }
	.pb-sm-16 {
		padding-bottom: 16px; }
	.pb-sm-24 {
		padding-bottom: 24px; }
	.pb-sm-32 {
		padding-bottom: 32px; }
	.pb-sm-40 {
		padding-bottom: 40px; }
	.pb-sm-48 {
		padding-bottom: 48px; }
	.pb-sm-56 {
		padding-bottom: 56px; }
	.pb-sm-64 {
		padding-bottom: 64px; }
	.pb-sm-72 {
		padding-bottom: 72px; }
	.pb-sm-80 {
		padding-bottom: 80px; }
	.pb-sm-88 {
		padding-bottom: 88px; }
	.pb-sm-96 {
		padding-bottom: 96px; } }

@media only screen and (max-width: 575px) {
	.mt-xs-0 {
		margin-top: 0 !important; }
	.mt-xs-8 {
		margin-top: 8px; }
	.mt-xs-16 {
		margin-top: 16px; }
	.mt-xs-24 {
		margin-top: 24px; }
	.mt-xs-32 {
		margin-top: 32px; }
	.mt-xs-40 {
		margin-top: 40px; }
	.mt-xs-48 {
		margin-top: 48px; }
	.mt-xs-56 {
		margin-top: 56px; }
	.mt-xs-64 {
		margin-top: 64px; }
	.mt-xs-72 {
		margin-top: 72px; }
	.mt-xs-80 {
		margin-top: 80px; }
	.mb-xs-0 {
		margin-bottom: 0 !important; }
	.mb-xs-8 {
		margin-bottom: 8px; }
	.mb-xs-16 {
		margin-bottom: 16px; }
	.mb-xs-24 {
		margin-bottom: 24px; }
	.mb-xs-32 {
		margin-bottom: 32px; }
	.mb-xs-40 {
		margin-bottom: 40px; }
	.mb-xs-48 {
		margin-bottom: 48px; }
	.mb-xs-56 {
		margin-bottom: 56px; }
	.mb-xs-64 {
		margin-bottom: 64px; }
	.mb-xs-72 {
		margin-bottom: 72px; }
	.mb-xs-80 {
		margin-bottom: 80px; }
	.pt-xs-0 {
		padding-top: 0; }
	.pt-xs-8 {
		padding-top: 8px; }
	.pt-xs-16 {
		padding-top: 16px; }
	.pt-xs-24 {
		padding-top: 24px; }
	.pt-xs-32 {
		padding-top: 32px; }
	.pt-xs-40 {
		padding-top: 40px; }
	.pt-xs-48 {
		padding-top: 48px; }
	.pt-xs-56 {
		padding-top: 56px; }
	.pt-xs-64 {
		padding-top: 64px; }
	.pt-xs-72 {
		padding-top: 72px; }
	.pt-xs-80 {
		padding-top: 80px; }
	.pt-xs-88 {
		padding-top: 88px; }
	.pt-xs-96 {
		padding-top: 96px; }
	.pb-xs-0 {
		padding-bottom: 0; }
	.pb-xs-8 {
		padding-bottom: 8px; }
	.pb-xs-16 {
		padding-bottom: 16px; }
	.pb-xs-24 {
		padding-bottom: 24px; }
	.pb-xs-32 {
		padding-bottom: 32px; }
	.pb-xs-40 {
		padding-bottom: 40px; }
	.pb-xs-48 {
		padding-bottom: 48px; }
	.pb-xs-56 {
		padding-bottom: 56px; }
	.pb-xs-64 {
		padding-bottom: 64px; }
	.pb-xs-72 {
		padding-bottom: 72px; }
	.pb-xs-80 {
		padding-bottom: 80px; }
	.pb-xs-88 {
		padding-bottom: 88px; }
	.pb-xs-96 {
		padding-bottom: 96px; } }

/*-------------------------------------------------------*/
/* Helper classes
/*-------------------------------------------------------*/
.clear {
	clear: both; }

.oh {
	overflow: hidden; }

.ov {
	overflow: visible; }

.relative {
	position: relative; }

.mobile section {
	background-attachment: scroll !important; }

.bg-dark {
	background-color: #131313; }

.bg-black {
	background-color: #000; }

.bg-light {
	background-color: #F6F6F6; }

.bg-white {
	background-color: #fff; }

.bg-color {
	background-color: #4C86E7; }

.white {
	color: #fff; }

.block {
	display: block; }

.left {
	float: left; }

.right {
	float: right; }

.valign {
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%); }

.img-full-width {
	width: 100%; }

.uppercase {
	text-transform: uppercase;
	letter-spacing: 0.02em; }

.last {
	margin-bottom: 0 !important; }

/* Slant angles
-------------------------------------------------------*/
.angle:before {
	position: absolute;
	-webkit-transform: skewY(-5deg);
	transform: skewY(-5deg);
	width: 100%;
	left: 0;
	content: "";
	background: none repeat scroll 0 0 #fff;
	min-height: 170px; }

.angle--top:before {
	margin-top: -232px; }

.angle--bottom:before {
	margin-top: 183px; }

.angle--dark:before {
	background-color: #131313; }

.angle--black:before {
	background-color: #000; }

.angle--light:before {
	background-color: #F6F6F6; }

@media only screen and (max-width: 991px) {
	.angle:before {
		min-height: 130px; }
	.angle--top:before {
		margin-top: -164px; }
	.angle--bottom:before {
		margin-top: 140px; } }

/* Pattern
-------------------------------------------------------*/
.bg-pattern,
.bg-pattern.angle:before {
	background: url("../img/dark_wall.png"); }

/* Section Dark / Background Image
-------------------------------------------------------*/
.bg-overlay h1, .bg-overlay h2, .bg-overlay h3:not(.section-subtitle):not(.project__title), .bg-overlay h4, .bg-overlay h5, .bg-overlay h6,
.bg-overlay .section-title,
.bg-overlay .subtitle,
.bg-overlay .call-to-action__title,
.bg-overlay .statistic__number,
.bg-overlay .statistic__title,
.bg-overlay .page-title__title,
.bg-overlay a:hover,
.bg-overlay a:focus,
.bg-overlay .entry__meta a:hover,
.bg-overlay .entry__meta a:focus,
.bg-dark h1,
.bg-dark h2,
.bg-dark h3:not(.section-subtitle):not(.project__title),
.bg-dark h4,
.bg-dark h5,
.bg-dark h6,
.bg-dark .section-title,
.bg-dark .subtitle,
.bg-dark .call-to-action__title,
.bg-dark .statistic__number,
.bg-dark .statistic__title,
.bg-dark .page-title__title,
.bg-dark a:hover,
.bg-dark a:focus,
.bg-dark .entry__meta a:hover,
.bg-dark .entry__meta a:focus,
.bg-black h1,
.bg-black h2,
.bg-black h3:not(.section-subtitle):not(.project__title),
.bg-black h4,
.bg-black h5,
.bg-black h6,
.bg-black .section-title,
.bg-black .subtitle,
.bg-black .call-to-action__title,
.bg-black .statistic__number,
.bg-black .statistic__title,
.bg-black .page-title__title,
.bg-black a:hover,
.bg-black a:focus,
.bg-black .entry__meta a:hover,
.bg-black .entry__meta a:focus,
.bg-img h1,
.bg-img h2,
.bg-img h3:not(.section-subtitle):not(.project__title),
.bg-img h4,
.bg-img h5,
.bg-img h6,
.bg-img .section-title,
.bg-img .subtitle,
.bg-img .call-to-action__title,
.bg-img .statistic__number,
.bg-img .statistic__title,
.bg-img .page-title__title,
.bg-img a:hover,
.bg-img a:focus,
.bg-img .entry__meta a:hover,
.bg-img .entry__meta a:focus,
.bg-color h1,
.bg-color h2,
.bg-color h3:not(.section-subtitle):not(.project__title),
.bg-color h4,
.bg-color h5,
.bg-color h6,
.bg-color .section-title,
.bg-color .subtitle,
.bg-color .call-to-action__title,
.bg-color .statistic__number,
.bg-color .statistic__title,
.bg-color .page-title__title,
.bg-color a:hover,
.bg-color a:focus,
.bg-color .entry__meta a:hover,
.bg-color .entry__meta a:focus,
.bg-color-overlay h1,
.bg-color-overlay h2,
.bg-color-overlay h3:not(.section-subtitle):not(.project__title),
.bg-color-overlay h4,
.bg-color-overlay h5,
.bg-color-overlay h6,
.bg-color-overlay .section-title,
.bg-color-overlay .subtitle,
.bg-color-overlay .call-to-action__title,
.bg-color-overlay .statistic__number,
.bg-color-overlay .statistic__title,
.bg-color-overlay .page-title__title,
.bg-color-overlay a:hover,
.bg-color-overlay a:focus,
.bg-color-overlay .entry__meta a:hover,
.bg-color-overlay .entry__meta a:focus,
.bg-gradient h1,
.bg-gradient h2,
.bg-gradient h3:not(.section-subtitle):not(.project__title),
.bg-gradient h4,
.bg-gradient h5,
.bg-gradient h6,
.bg-gradient .section-title,
.bg-gradient .subtitle,
.bg-gradient .call-to-action__title,
.bg-gradient .statistic__number,
.bg-gradient .statistic__title,
.bg-gradient .page-title__title,
.bg-gradient a:hover,
.bg-gradient a:focus,
.bg-gradient .entry__meta a:hover,
.bg-gradient .entry__meta a:focus {
	color: #fff; }

.bg-overlay p:not(.intro__text):not(.testimonial__text):not(.project__hero-description),
.bg-dark p:not(.intro__text):not(.testimonial__text):not(.project__hero-description),
.bg-black p:not(.intro__text):not(.testimonial__text):not(.project__hero-description),
.bg-img p:not(.intro__text):not(.testimonial__text):not(.project__hero-description),
.bg-color p:not(.intro__text):not(.testimonial__text):not(.project__hero-description),
.bg-color-overlay p:not(.intro__text):not(.testimonial__text):not(.project__hero-description),
.bg-gradient p:not(.intro__text):not(.testimonial__text):not(.project__hero-description) {
	color: #969696; }

.bg-overlay .results-col,
.bg-overlay .accordion__panel,
.bg-overlay .accordion:first-child .accordion__panel,
.bg-overlay .client-logo,
.bg-dark .results-col,
.bg-dark .accordion__panel,
.bg-dark .accordion:first-child .accordion__panel,
.bg-dark .client-logo,
.bg-black .results-col,
.bg-black .accordion__panel,
.bg-black .accordion:first-child .accordion__panel,
.bg-black .client-logo,
.bg-img .results-col,
.bg-img .accordion__panel,
.bg-img .accordion:first-child .accordion__panel,
.bg-img .client-logo,
.bg-color .results-col,
.bg-color .accordion__panel,
.bg-color .accordion:first-child .accordion__panel,
.bg-color .client-logo,
.bg-color-overlay .results-col,
.bg-color-overlay .accordion__panel,
.bg-color-overlay .accordion:first-child .accordion__panel,
.bg-color-overlay .client-logo,
.bg-gradient .results-col,
.bg-gradient .accordion__panel,
.bg-gradient .accordion:first-child .accordion__panel,
.bg-gradient .client-logo {
	border-color: #131313; }

.bg-overlay .accordion__toggle,
.bg-dark .accordion__toggle,
.bg-black .accordion__toggle,
.bg-img .accordion__toggle,
.bg-color .accordion__toggle,
.bg-color-overlay .accordion__toggle,
.bg-gradient .accordion__toggle {
	background-color: #fff; }

.bg-overlay .title-underline a,
.bg-dark .title-underline a,
.bg-black .title-underline a,
.bg-img .title-underline a,
.bg-color .title-underline a,
.bg-color-overlay .title-underline a,
.bg-gradient .title-underline a {
	background-image: -webkit-gradient(linear, left top, right top, from(#fff), to(#fff));
	background-image: linear-gradient(to right, #fff 0%, #fff 100%); }

/* Hovers
-------------------------------------------------------*/
.hover-shrink--shrink {
	overflow: hidden; }

.hover-shrink--shrink, .hover-shrink--zoom {
	-webkit-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out; }

.hover-shrink:hover .hover-shrink--shrink {
	-webkit-transform: scale(0.95);
	transform: scale(0.95); }

.hover-shrink:hover .hover-shrink--zoom {
	-webkit-transform: scale(1.2);
	transform: scale(1.2); }

.hover-down {
	-webkit-transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
	transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
	top: 0;
	position: relative; }
	.hover-down:hover {
		box-shadow: 0px 5px 10px rgba(64, 90, 128, 0.05);
		top: 2px; }

.hover-line {
	position: relative; }
	.hover-line:after {
		content: '';
		display: block;
		height: 2px;
		width: 100%;
		position: absolute;
		bottom: -5px;
		right: 0;
		background-color: #FE5900;
		-webkit-transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1);
		transition: all 0.6s cubic-bezier(0.86, 0, 0.07, 1); }
	.hover-line:hover:after {
		width: 0; }

/* Title Underline
-------------------------------------------------------*/
.title-underline a {
	background-image: -webkit-gradient(linear, left top, right top, from(#131313), to(#131313));
	background-image: linear-gradient(to right, #131313 0%, #131313 100%);
	background-size: 0px 2px;
	background-position: 0px 95%;
	background-repeat: no-repeat;
	-webkit-transition: all 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
	transition: all 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
	padding: 0.5% 0px; }

.title-underline:hover a {
	background-size: 100% 2px; }

/* Gradients / Overlays
-------------------------------------------------------*/
.bg-gradient-bottom:before {
	content: "";
	top: 0;
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	background: #131313;
	/* Old browsers */
	/* FF3.6+ */
	/* Chrome,Safari4+ */
	/* Chrome10+,Safari5.1+ */
	/* Opera 11.10+ */
	/* IE10+ */
	background: -webkit-gradient(linear, left bottom, left top, from(#131313), to(transparent));
	background: linear-gradient(to top, #131313 0%, transparent 100%);
	/* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
	/* IE6-9 */ }

.bg-overlay {
	position: relative; }
	.bg-overlay:before {
		content: "";
		top: 0;
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(19, 19, 19, 0.81); }
	.bg-overlay--light-68::before {
		background-color: rgba(19, 19, 19, 0.68); }
	.bg-overlay--light::before {
		background-color: rgba(19, 19, 19, 0.37); }

/* Section Dividers
-------------------------------------------------------*/
.top-divider {
	border-top: 1px solid #E9E9E9; }

.bottom-divider {
	border-bottom: 1px solid #E9E9E9; }

/* Animations
-------------------------------------------------------*/
.animate {
	position: relative;
	overflow: hidden; }
	.animate-container {
		opacity: 0;
		visibility: hidden;
		-webkit-transition: 0.2s ease-in-out 0.8s;
		transition: 0.2s ease-in-out 0.8s; }
	.animate:before {
		content: "";
		position: absolute;
		display: block;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0; }
	.animate:before {
		background-color: #F6F6F6;
		z-index: 1;
		-webkit-transform: translateX(-101%);
		transform: translateX(-101%); }
	.animate--animated:before {
		-webkit-animation: deothemes-appearing-animation 1s cubic-bezier(0.86, 0, 0.07, 1) forwards;
		        animation: deothemes-appearing-animation 1s cubic-bezier(0.86, 0, 0.07, 1) forwards; }
	.animate--animated .animate-container {
		opacity: 1;
		visibility: visible; }

@-webkit-keyframes deothemes-appearing-animation {
	0% {
		-webkit-transform: translateX(-101%);
		transform: translateX(-101%); }
	50% {
		-webkit-transform: translateX(0);
		transform: translateX(0); }
	100% {
		-webkit-transform: translateX(101%);
		transform: translateX(101%); } }

@keyframes deothemes-appearing-animation {
	0% {
		-webkit-transform: translateX(-101%);
		transform: translateX(-101%); }
	50% {
		-webkit-transform: translateX(0);
		transform: translateX(0); }
	100% {
		-webkit-transform: translateX(101%);
		transform: translateX(101%); } }
