/*-------------------------------------------------------*/
/* Spacings
/*-------------------------------------------------------*/
.m-0 {  margin: 0!important; }
.p-0 { padding: 0!important; }
.mt-0 { margin-top: 0!important; }
.mt-8 { margin-top: 8px; }
.mt-16 { margin-top: 16px; }
.mt-24 { margin-top: 24px; }
.mt-32 { margin-top: 32px; }
.mt-40 { margin-top: 40px; }
.mt-48 { margin-top: 48px; }
.mt-56 { margin-top: 56px; }
.mt-64 { margin-top: 64px; }
.mt-72 { margin-top: 72px; }
.mt-80 { margin-top: 80px; }
.mb-0 { margin-bottom: 0!important; }
.mb-8 { margin-bottom: 8px; }
.mb-16 { margin-bottom: 16px; }
.mb-24 { margin-bottom: 24px; }
.mb-32 { margin-bottom: 32px; }
.mb-40 { margin-bottom: 40px; }
.mb-48 { margin-bottom: 48px; }
.mb-56 { margin-bottom: 56px; }
.mb-64 { margin-bottom: 64px; }
.mb-72 { margin-bottom: 72px; }
.mb-80 { margin-bottom: 80px; }
.pt-0 { padding-top: 0; }
.pt-8 { padding-top: 8px; }
.pt-16 { padding-top: 16px; }
.pt-24 { padding-top: 24px; }
.pt-32 { padding-top: 32px; }
.pt-40 { padding-top: 40px; }
.pt-48 { padding-top: 48px; }
.pt-56 { padding-top: 56px; }
.pt-64 { padding-top: 64px; }
.pt-72 { padding-top: 72px; }
.pt-80 { padding-top: 80px; }
.pt-96 { padding-top: 96px; }
.pb-0 { padding-bottom: 0; }
.pb-8 { padding-bottom: 8px; }
.pb-16 { padding-bottom: 16px; }
.pb-24 { padding-bottom: 24px; }
.pb-32 { padding-bottom: 32px; }
.pb-40 { padding-bottom: 40px; }
.pb-48 { padding-bottom: 48px; }
.pb-56 { padding-bottom: 56px; }
.pb-64 { padding-bottom: 64px; }
.pb-72 { padding-bottom: 72px; }
.pb-80 { padding-bottom: 80px; }
.pb-88 { padding-bottom: 88px; }
.pb-96 { padding-bottom: 96px; }

@include bp-xl-down {
.mt-lg-0 {  margin-top: 0!important; }
.mt-lg-8 { margin-top: 8px; }
.mt-lg-16 { margin-top: 16px; }
.mt-lg-24 { margin-top: 24px; }
.mt-lg-32 { margin-top: 32px; }
.mt-lg-40 { margin-top: 40px; }
.mt-lg-48 { margin-top: 48px; }
.mt-lg-56 { margin-top: 56px; }
.mt-lg-64 { margin-top: 64px; }
.mt-lg-72 { margin-top: 72px; }
.mt-lg-80 {  margin-top: 80px; }
.mb-lg-0 {  margin-bottom: 0!important; }
.mb-lg-8 { margin-bottom: 8px; }
.mb-lg-16 { margin-bottom: 16px; }
.mb-lg-24 { margin-bottom: 24px; }
.mb-lg-32 { margin-bottom: 32px; }
.mb-lg-40 { margin-bottom: 40px; }
.mb-lg-48 { margin-bottom: 48px; }
.mb-lg-56 { margin-bottom: 56px; }
.mb-lg-64 { margin-bottom: 64px; }
.mb-lg-72 { margin-bottom: 72px; }
.mb-lg-80 {  margin-bottom: 80px; }
.pt-lg-0 {  padding-top: 0; }
.pt-lg-8 { padding-top: 8px; }
.pt-lg-16 { padding-top: 16px; }
.pt-lg-24 { padding-top: 24px; }
.pt-lg-32 { padding-top: 32px; }
.pt-lg-40 { padding-top: 40px; }
.pt-lg-48 { padding-top: 48px; }
.pt-lg-56 { padding-top: 56px; }
.pt-lg-64 { padding-top: 64px; }
.pt-lg-72 { padding-top: 72px; }
.pt-lg-80 {  padding-top: 80px; }
.pt-lg-88 {  padding-top: 88px; }
.pt-lg-96 {  padding-top: 96px; }
.pb-lg-0 {  padding-bottom: 0; }
.pb-lg-8 { padding-bottom: 8px; }
.pb-lg-16 { padding-bottom: 16px; }
.pb-lg-24 { padding-bottom: 24px; }
.pb-lg-32 { padding-bottom: 32px; }
.pb-lg-40 { padding-bottom: 40px; }
.pb-lg-48 { padding-bottom: 48px; }
.pb-lg-56 { padding-bottom: 56px; }
.pb-lg-64 { padding-bottom: 64px; }
.pb-lg-72 { padding-bottom: 72px; }
.pb-lg-80 {  padding-bottom: 80px; }
.pb-lg-88 {  padding-bottom: 88px; }
.pb-lg-96 {  padding-bottom: 96px; }
}

@include bp-lg-down {
.mt-md-0 { margin-top: 0!important; }
.mt-md-8 { margin-top: 8px; }
.mt-md-16 { margin-top: 16px; }
.mt-md-24 { margin-top: 24px; }
.mt-md-32 { margin-top: 32px; }
.mt-md-40 { margin-top: 40px; }
.mt-md-48 { margin-top: 48px; }
.mt-md-56 { margin-top: 56px; }
.mt-md-64 { margin-top: 64px; }
.mt-md-72 { margin-top: 72px; }
.mt-md-80 { margin-top: 80px; }
.mb-md-0 { margin-bottom: 0!important; }
.mb-md-8 { margin-bottom: 8px; }
.mb-md-16 { margin-bottom: 16px; }
.mb-md-24 { margin-bottom: 24px; }
.mb-md-32 { margin-bottom: 32px; }
.mb-md-40 { margin-bottom: 40px; }
.mb-md-48 { margin-bottom: 48px; }
.mb-md-56 { margin-bottom: 56px; }
.mb-md-64 { margin-bottom: 64px; }
.mb-md-72 { margin-bottom: 72px; }
.mb-md-80 { margin-bottom: 80px; }
.pt-md-0 { padding-top: 0; }
.pt-md-8 { padding-top: 8px; }
.pt-md-16 { padding-top: 16px; }
.pt-md-24 { padding-top: 24px; }
.pt-md-32 { padding-top: 32px; }
.pt-md-40 { padding-top: 40px; }
.pt-md-48 { padding-top: 48px; }
.pt-md-56 { padding-top: 56px; }
.pt-md-64 { padding-top: 64px; }
.pt-md-72 { padding-top: 72px; }
.pt-md-80 { padding-top: 80px; }
.pt-md-88 { padding-top: 88px; }
.pt-md-96 { padding-top: 96px; }
.pb-md-0 { padding-bottom: 0; }
.pb-md-8 { padding-bottom: 8px; }
.pb-md-16 { padding-bottom: 16px; }
.pb-md-24 { padding-bottom: 24px; }
.pb-md-32 { padding-bottom: 32px; }
.pb-md-40 { padding-bottom: 40px; }
.pb-md-48 { padding-bottom: 48px; }
.pb-md-56 { padding-bottom: 56px; }
.pb-md-64 { padding-bottom: 64px; }
.pb-md-72 { padding-bottom: 72px; }
.pb-md-80 { padding-bottom: 80px; }
.pb-md-88 { padding-bottom: 88px; }
.pb-md-96 { padding-bottom: 96px; }
}

@include bp-md-down {
.mt-sm-0 { margin-top: 0!important; }
.mt-sm-8 { margin-top: 8px; }
.mt-sm-16 { margin-top: 16px; }
.mt-sm-24 { margin-top: 24px; }
.mt-sm-32 { margin-top: 32px; }
.mt-sm-40 { margin-top: 40px; }
.mt-sm-48 { margin-top: 48px; }
.mt-sm-56 { margin-top: 56px; }
.mt-sm-64 { margin-top: 64px; }
.mt-sm-72 { margin-top: 72px; }
.mt-sm-80 { margin-top: 80px; }
.mb-sm-0 { margin-bottom: 0!important; }
.mb-sm-8 { margin-bottom: 8px; }
.mb-sm-16 { margin-bottom: 16px; }
.mb-sm-24 { margin-bottom: 24px; }
.mb-sm-32 { margin-bottom: 32px; }
.mb-sm-40 { margin-bottom: 40px; }
.mb-sm-48 { margin-bottom: 48px; }
.mb-sm-56 { margin-bottom: 56px; }
.mb-sm-64 { margin-bottom: 64px; }
.mb-sm-72 { margin-bottom: 72px; }
.mb-sm-80 { margin-bottom: 80px; }
.pt-sm-0 { padding-top: 0; }
.pt-sm-8 { padding-top: 8px; }
.pt-sm-16 { padding-top: 16px; }
.pt-sm-24 { padding-top: 24px; }
.pt-sm-32 { padding-top: 32px; }
.pt-sm-40 { padding-top: 40px; }
.pt-sm-48 { padding-top: 48px; }
.pt-sm-56 { padding-top: 56px; }
.pt-sm-64 { padding-top: 64px; }
.pt-sm-72 { padding-top: 72px; }
.pt-sm-80 { padding-top: 80px; }
.pt-sm-88 { padding-top: 88px; }
.pt-sm-96 { padding-top: 96px; }
.pb-sm-0 { padding-bottom: 0; }
.pb-sm-8 { padding-bottom: 8px; }
.pb-sm-16 { padding-bottom: 16px; }
.pb-sm-24 { padding-bottom: 24px; }
.pb-sm-32 { padding-bottom: 32px; }
.pb-sm-40 { padding-bottom: 40px; }
.pb-sm-48 { padding-bottom: 48px; }
.pb-sm-56 { padding-bottom: 56px; }
.pb-sm-64 { padding-bottom: 64px; }
.pb-sm-72 { padding-bottom: 72px; }
.pb-sm-80 { padding-bottom: 80px; }
.pb-sm-88 { padding-bottom: 88px; }
.pb-sm-96 { padding-bottom: 96px; }
}

@include bp-sm-down {
.mt-xs-0 { margin-top: 0!important; }
.mt-xs-8 { margin-top: 8px; }
.mt-xs-16 { margin-top: 16px; }
.mt-xs-24 { margin-top: 24px; }
.mt-xs-32 { margin-top: 32px; }
.mt-xs-40 { margin-top: 40px; }
.mt-xs-48 { margin-top: 48px; }
.mt-xs-56 { margin-top: 56px; }
.mt-xs-64 { margin-top: 64px; }
.mt-xs-72 { margin-top: 72px; }
.mt-xs-80 { margin-top: 80px; }
.mb-xs-0 { margin-bottom: 0!important; }
.mb-xs-8 { margin-bottom: 8px; }
.mb-xs-16 { margin-bottom: 16px; }
.mb-xs-24 { margin-bottom: 24px; }
.mb-xs-32 { margin-bottom: 32px; }
.mb-xs-40 { margin-bottom: 40px; }
.mb-xs-48 { margin-bottom: 48px; }
.mb-xs-56 { margin-bottom: 56px; }
.mb-xs-64 { margin-bottom: 64px; }
.mb-xs-72 { margin-bottom: 72px; }
.mb-xs-80 { margin-bottom: 80px; }
.pt-xs-0 { padding-top: 0; }
.pt-xs-8 { padding-top: 8px; }
.pt-xs-16 { padding-top: 16px; }
.pt-xs-24 { padding-top: 24px; }
.pt-xs-32 { padding-top: 32px; }
.pt-xs-40 { padding-top: 40px; }
.pt-xs-48 { padding-top: 48px; }
.pt-xs-56 { padding-top: 56px; }
.pt-xs-64 { padding-top: 64px; }
.pt-xs-72 { padding-top: 72px; }
.pt-xs-80 { padding-top: 80px; }
.pt-xs-88 { padding-top: 88px; }
.pt-xs-96 { padding-top: 96px; }
.pb-xs-0 { padding-bottom: 0; }
.pb-xs-8 { padding-bottom: 8px; }
.pb-xs-16 { padding-bottom: 16px; }
.pb-xs-24 { padding-bottom: 24px; }
.pb-xs-32 { padding-bottom: 32px; }
.pb-xs-40 { padding-bottom: 40px; }
.pb-xs-48 { padding-bottom: 48px; }
.pb-xs-56 { padding-bottom: 56px; }
.pb-xs-64 { padding-bottom: 64px; }
.pb-xs-72 { padding-bottom: 72px; }
.pb-xs-80 { padding-bottom: 80px; }
.pb-xs-88 { padding-bottom: 88px; }
.pb-xs-96 { padding-bottom: 96px; }
}