// Colors
$text-color: #686868;
$heading-color: #131313;
$nav-color: #ffffff;
$bg-light: #F6F6F6;
$bg-dark: #131313;
$border-color: #E9E9E9;
$border-color-dark: #323232;
$main-color: #FE5900;
$secondary-color: #4C86E7;
$meta-color: #909090;


// Fonts
$body-font: 'DM Sans', sans-serif;
$heading-font: 'DM Sans', sans-serif;


// Navbar
$nav-short-height: 60px;
$nav-full-height: 112px;